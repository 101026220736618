import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { VacationRentalShopCallState } from "../index";
import {
  ITrackingProperties,
  ObfuscatedAddress,
  ViewedVacationRentalDetailsProperties,
  ListingCollectionEnum,
  PolicyEnum,
} from "redmond";
import queryStringParser from "query-string";
import { getSelectedAccount } from "../../../rewards/reducer";
import { getAdultsCount, getChildrenCount, getFromDate, getPetsCount, getUntilDate, getLodgingSelection } from "../../../search/reducer";
import dayjs from "dayjs";

export const getVacationRentalShopSelectedListing = (state: IStoreState) =>
  state.vacationRentalShop.selectedHome;

export const getVacationRentalShopSelectedListingCalendar = (
  state: IStoreState
) => state.vacationRentalShop.selectedHomeCalendar;

export const getVacationRentalShopUnavailableDates = (state: IStoreState) =>
  getVacationRentalShopSelectedListingCalendar(state)
    ?.calendarDays?.filter(
      (calendarDay) =>
        !calendarDay.isAvailable && !calendarDay.isAvailableForCheckout
    )
    .map((calendarDay) => dayjs(calendarDay.date).toDate());

export const getNotifyIfShopAndBookPriceDiffer = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalNotifyIfShopAndBookPriceDiffer;

export const getVacationRentalShopSelectedListingId = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalListingId;

export const getVacationRentalShopCallState = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallState;

export const getVacationRentalShopCallError = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallError;

export const getVacationRentalDetailsEntrySource = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalDetailsEntrySource;

export const getVacationRentalSortOrder = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalSortOrder;

export const hasVacationRentalShopFailed = createSelector(
  getVacationRentalShopCallState,
  (vrShopCallState) => {
    return vrShopCallState === VacationRentalShopCallState.Failed;
  }
);

export const getViewedVacationRentalDetailsProperties = createSelector(
  getSelectedAccount,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  getVacationRentalShopSelectedListing,
  getLodgingSelection,
  getVacationRentalDetailsEntrySource,
  getVacationRentalSortOrder,
  (
    account,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
    listing,
    lodgingSelection,
    entrySource,
    sortOrder,
  ): ITrackingProperties<ViewedVacationRentalDetailsProperties> => {
    const { selectedLodgingIndex, roomsCount }: { selectedLodgingIndex?: string, roomsCount?: string } = queryStringParser.parse(window.location.search);

    let parsedSelectedLodgingIndex: number | undefined;
    let parsedRoomsCount: number | undefined;

    try {
      parsedSelectedLodgingIndex = typeof selectedLodgingIndex === "string" ? parseInt(selectedLodgingIndex): undefined
      parsedRoomsCount = typeof roomsCount === "string" ? parseInt(roomsCount): undefined
    } catch (error) {
      console.error(error);
    }

    return {
      properties: {
        adults_count: adultsCount,
        account_type_selected: account?.productDisplayName || "",
        account_use_type: account?.accountUseType,
        advance: dayjs(fromDate).diff(dayjs(), "day"),
        check_in_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        check_out_date: untilDate ? dayjs(untilDate).format("YYYY-MM-DD") : "",
        children_count: childrenCount,
        credit_balance: 0,
        customer_account_role: account?.customerAccountRole,
        delegated_to: "",
        first_launch: false,
        has_credits: false,
        home_city: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.city,
        home_host_name: listing?.listing.hostInfo?.hostName,
        home_id: listing?.listingId.id,
        home_state: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.state,
        los: dayjs(untilDate).diff(fromDate, "day"),
        market_id: listing?.listing?.market?.id,
        market_name: listing?.listing?.market?.name,
        number_of_photos: listing?.listing.content.media.length,
        pets_count: petsCount,
        pets_searched: petsCount,
        vx_statement_credit_balance: 0,
        ...listing?.listing.trackingProperties?.properties,
        product_type: [ListingCollectionEnum.Premier, ListingCollectionEnum.Lifestyle].includes(listing?.listing.listingCollection || ListingCollectionEnum.NoCollection) ? listing?.listing.listingCollection as string : "",
        free_cancellation_shown: listing?.availability?.rate?.cancellationPolicy?.policy?.Policy == PolicyEnum.FullyRefundable,
        google_place_id: lodgingSelection?.placeId,
        destination_name: lodgingSelection?.searchTerm,
        lodging_row_index: parsedSelectedLodgingIndex,
        rooms_searched: parsedRoomsCount,
        details_entry_source: entrySource?.toLowerCase() || 'none',
        sort_order: sortOrder,
      },
      encryptedProperties: [
        listing?.listing.trackingProperties?.encryptedProperties ?? "",
      ]
    };
  }
);
