import {
  GuestsSelection,
  LodgingSelection,
} from "../../hotels-module/interfaces";
import { Funnel } from "./wallet";
import { DateRange } from "../../common";

export interface ActiveCrossSellOffer {
  funnel: Funnel;
  description: string;
  destinationDetail: DestinationDetail;
}

export interface ActiveCrossSellOffersResponse {
  activeOffers: Array<ActiveCrossSellOffer>;
}

export interface DestinationDetail {
  cityName: string;
  lodgingSelection?: LodgingSelection;
  stayDates: DateRange;
  guests: GuestsSelection;
}

export interface PotentialCrossSellOffersRequest {
  flightDetail?: FlightDetail;
}

export interface FlightDetail {
  tripCategory?: TripCategoryEnum;
  // only populate for round trips
  lengthOfStay?: number;
  firstSliceDepartureDate: string;
}

export enum TripCategoryEnum {
  OneWay = "OneWay",
  RoundTrip = "RoundTrip",
  MultiCity = "MultiCity",
}

export interface PotentialCrossSellOffersResponse {
  potentialOffers: PotentialCrossSellOffer[];
}

export interface PotentialCrossSellOffer {
  triggeredBy: Funnel[];
  mainDescription: string;
  subDescription: string;
  confirmationPageDescription: string;
}
