import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { TruncatedText } from "../../shop/components/TruncatedText/component";
import "./styles.scss";

export interface VacationRentalImportantInformationProps {
  importantInformation?: string;
  registrationId?: string;
}

export const VacationRentalImportantInformation = ({
  importantInformation,
  registrationId,
}: VacationRentalImportantInformationProps) => {
  return (
    <Box
      className="vacation-rentals-important-information section"
      display="flex"
      flexDirection="column"
    >
      {importantInformation && (
        <Box display="flex" flexDirection="column" style={{ gap: "12px" }}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Important Information
          </Typography>
          <TruncatedText
            text={importantInformation}
            limit={500}
            setAsHTML={true}
          />
        </Box>
      )}

      {importantInformation && registrationId && (
        <Box paddingY={"20px"}>
          <Divider />
        </Box>
      )}

      {registrationId && (
        <Box display="flex" flexDirection="column" style={{ gap: "12px" }}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Registration Number
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#676868",
            }}
          >
            {registrationId}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
