import { PortalType } from "../capone-corporate";
import { Amount, DateTime, Prices, RewardsAmount } from "../common";
import { Reservation } from "../hotels-module";
import {
  PortalItineraryStatusEnum,
  PaymentBreakdown,
  IPaymentAmountInfo,
} from "./itinerary";

export interface CountryCode {
  code: string;
}

export interface DriverLicense {
  country: CountryCode;
  cityCode: string;
  street: string;
  postCode: string;
}

export interface FrequentFlyerNumber {
  value: string;
}

export interface HotelItineraryRedeemedEarnOffer {
  description: string;
}

export interface HotelItinerary {
  reservation: Reservation;
  status: PortalItineraryStatusEnum;
  paymentAmountInfo: IPaymentAmountInfo;
  paymentBreakdown: PaymentBreakdown;
  ancillaries: HotelAncillaryContracts;
  portalType?: PortalType;
  redeemedEarnOffers?: HotelItineraryRedeemedEarnOffer[];
  tenant?: string;
}

export interface HotelAncillaryContracts {
  cfar?: HotelCfarContract;
}

export enum ContractStateEnum {
  Unspecified = "Unspecified",
  Pending = "Pending",
  Purchase = "Purchase",
  Cancel = "Cancel",
  Exercise = "Exercise",
  Amend = "Amend",
}

export interface HotelCfarContractState {
  ContractState: ContractStateEnum;
}

export interface HotelCfarContract {
  id: string;
  purchased: DateTime;
  expired: DateTime;
  premium: CfarContractPremium;
  coverage: CfarContractCoverage;
  state?: HotelCfarContractState;
  purchasedTz?: string;
  expiredTz?: string;
  shouldRefundCfarPremium?: boolean;
}

export interface CfarContractPremium {
  amount: Prices;
}

export interface CfarContractCoverage {
  amount: Prices;
  percentage: number;
}

export interface HotelCfarCanceled {
  HotelCfarCancelScenario: HotelCfarCancelScenarioEnum.Canceled;
}

export interface HotelCfarCancellationFailure {
  HotelCfarCancelScenario: HotelCfarCancelScenarioEnum.CancellationFailure;
}

export interface HotelCfarContactCustomerService {
  HotelCfarCancelScenario: HotelCfarCancelScenarioEnum.ContactCustomerService;
}

export interface HotelCfarPending {
  HotelCfarCancelScenario: HotelCfarCancelScenarioEnum.Pending;
}

export interface CfarContractId {
  value: string;
}

export interface RewardsAmountWithRate {
  rewardsAmount: RewardsAmount;
  redemptionRate: number;
}

export interface HotelCfarRefundable {
  contractId: CfarContractId;
  coverageAmount: Amount;
  coveragePercentage: number;
  refundAmount: Prices;
  preparedPayment: string;
  cashRefundAmount?: Amount;
  rewardsRefundAmount?: RewardsAmountWithRate;
  travelCreditRefundAmount?: Amount;
  shouldRefundCfarPremium?: boolean;
  HotelCfarCancelScenario: HotelCfarCancelScenarioEnum.CfarRefundable;
}

export type HotelCfarCancelScenario =
  | HotelCfarCanceled
  | HotelCfarCancellationFailure
  | HotelCfarRefundable
  | HotelCfarContactCustomerService
  | HotelCfarPending;

export enum HotelCfarCancelScenarioEnum {
  ContactCustomerService = "ContactCustomerService",
  CancellationFailure = "CancellationFailure",
  Canceled = "Canceled",
  CfarRefundable = "CfarRefundable",
  Pending = "Pending",
}

export interface HotelCfarCancellationPolicyRequest {
  reservationId: string;
}

export interface HotelCfarCancellationRequest {
  reservationId: string;
  cancelScenario: HotelCfarCancelScenario;
}

export interface ErrorCode {
  code: string;
  details?: string;
}

export type HotelCfarCancellationResponse =
  | ErrorCode
  | HotelCfarCancellationSuccess;

export interface HotelCfarCancellationResult {
  success: boolean;
}

export interface HotelCfarCancellationSuccess {
  value: HotelCfarCancellationResult;
}

export interface HotelItineraryRequest {
  states: HotelItineraryState[];
  referenceDateTime: string;
}

export interface HotelItineraryResponse {
  itineraries: { [key: string]: HotelItinerary[] | undefined };
}

export enum HotelItineraryState {
  Canceled = "Canceled",
  Cancelled = "Cancelled",
  Past = "Past",
  Future = "Future",
  Invalid = "Invalid",
  CancellationFailure = "CancellationFailure",
  Present = "Present",
}
