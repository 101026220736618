import dayjs from "dayjs";
import { History } from "history";
import queryStringParser from "query-string";

import {
  IResult,
  Listing,
  LodgingSelection,
  LodgingSelectionEnum,
  StayTypesEnum,
} from "redmond";

import {
  PATH_STAYS,
  PATH_STAYS_AVAILABILITY,
  PATH_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_SHOP,
} from "../../../utils/paths";
import { BaseAddress } from "redmond/apis/tysons/vacation-rentals";

export interface IVacationRentalsShopParsedQuery {
  lodgingId: string;
  fromDate: string;
  untilDate: string;
  adultsCount: number;
  childrenCount: number;
  selectedLodgingIndex: number;
  lodgingSelection?: LodgingSelection | string;
  fromHotelAvailability?: boolean;
}

export interface VacationRentalsShopQuery {
  lodgingId: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  selectedAccountIndex: number;
  selectedLodgingIndex: number | null;
  lodgingSelection?: LodgingSelection | string; // this needs to be updated once we get data returned like hotels with lodgingSelection
}

interface CommonSearchParams {
  location: IResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
}

interface VacationRentalsSearchParams extends CommonSearchParams {
  type: StayTypesEnum.VacationRentals;
  petsCount: number;
}

export type SearchParams = VacationRentalsSearchParams;

type OmitNull<T> = T extends null ? never : T;
type NotNull<T> = {
  [k in keyof T]: OmitNull<T[k]>;
};
export type RequiredSearchParams = NotNull<SearchParams>;

export const transformToStringifiedQuery = ({
  lodgingId,
  fromDate,
  untilDate,
  adultsCount = 2,
  childrenCount = 0,
  selectedAccountIndex = 0,
  selectedLodgingIndex,
  lodgingSelection,
}: VacationRentalsShopQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?lodgingId=${lodgingId}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  const lodgingSelectionParam = encodeURIComponent(lodgingSelection as string);
  let string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}&childrenCount=${childrenCount}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;

  if (selectedLodgingIndex != null) {
    string += `&selectedLodgingIndex=${selectedLodgingIndex}`;
  }
  return string;
};

export const transformToStringifiedAvailabilityQuery = ({
  location,
  fromDate: initialFromDate,
  untilDate: initialUntilDate,
  adultsCount,
  childrenCount,
  petsCount,
}: {
  location: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number | null;
  childrenCount: number | null;
  petsCount?: number;
}): string => {
  if (initialFromDate === null || initialUntilDate === null) {
    return `?locationName=${location}`;
  }

  // Since we're mutating the dates below, we need to create a copy first,
  // or the dates will keep getting offset.
  const fromDate = new Date(initialFromDate);
  const untilDate = new Date(initialUntilDate);

  // fixes "off by 1 day" bug caused by converting to local timezone
  fromDate.setMinutes(fromDate.getMinutes() + fromDate.getTimezoneOffset());
  untilDate.setMinutes(untilDate.getMinutes() + untilDate.getTimezoneOffset());

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  let string = `?locationName=${location}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}&childrenCount=${childrenCount}`;
  if (petsCount) {
    string += `&petsCount=${petsCount}`;
  }
  return string;
};

export const goToAvailability = ({
  history,
  listing,
  fromDate,
  untilDate,
  adultsCount,
  childrenCount,
  petsCount,
}: {
  history: History;
  listing: Listing | undefined | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  petsCount?: number;
}) => {
  // note: this needs to be updated once availability params are fixed. refreshing on /availability kicks back to /search and this also kicks back to /search
  if (!listing) {
    return history.push(PATH_STAYS);
  }
  
  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  const lodgingSelection = JSON.parse(
    decodeURIComponent(parsedQueryStringPrimitive.lodgingSelection as string)
  );

  const listingAddress = listing.content.location.address as BaseAddress // Obfuscated Address and Full Address both extend BaseAddress
  
  const locationToSearch = 
    lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
      ? lodgingSelection.searchTerm
      : `${listingAddress.city}, ${listingAddress.state}`

  const search = transformToStringifiedAvailabilityQuery({
    location: encodeURIComponent(encodeURIComponent(locationToSearch),),
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
  });

  history.push(`${PATH_STAYS_AVAILABILITY}${search}`);
};

export const goToShop = ({ history }: { history: History }) => {
  history.push(`${PATH_VACATION_RENTALS_SHOP}${history.location.search}`, {
    fromPage: location.pathname,
  });
};

export const goToCheckout = ({ history }: { history: History }) => {
  history.push(`${PATH_VACATION_RENTALS_BOOK}${history.location.search}`);
};
