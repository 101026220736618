import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Provider, useDispatch } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import {
  PremierCollectionModuleProps as VacationRentalsModuleProps,
  PremierCollectionClientAssetProps as VacationRentalsClientAssetProps,
  installColorConfig,
  SessionInfo,
  CallState,
  ExperimentVariant,
} from "redmond";
import { useMedalliaFeedback } from "b2b-base/src/components/MedalliaFeedback";
import { ExperimentsHookProvider } from "@capone/experiments";
import { IntlProvider } from "react-intl";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { store } from "./store";
import AxiosInterceptors from "./components/AxiosInterceptors";
import {
  setIsFirstLaunch,
  setAgentEmail,
  fetchRewardsAccounts,
} from "./modules/rewards/actions/actions";
import UserSourceProvider from "./context/userSource";
import ExperimentsProvider, {
  getExperimentVariant,
  STAYS_BOOKING_EXPERIMENT,
  useExperiments,
} from "./context/experiments";
import fetchUserInfo from "./api/v0/user/fetchUserInfo";
import { AgentBanner } from "halifax";
import {
  PATH_STAYS,
  PATH_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_SHOP,
} from "./utils/paths";
import { VacationRentalShop } from "./modules/shop";
import { RewardsBanner } from "./modules/rewards/components";
import * as H from "history";
import { VacationRentalBook } from "./modules/book";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptVacationRentalsModule",
  seed: "ptVacationRentalsModule",
});

interface VacationRentalsClientContextType
  extends VacationRentalsClientAssetProps {
  isAgentPortal: boolean;
}

export const ClientContext = React.createContext<
  Partial<VacationRentalsClientContextType>
>({});

const App = (props: VacationRentalsModuleProps) => {
  const {
    theme,
    language,
    colors,
    baseHistory,
    clientAssets,
    isAgentPortal,
    experiments,
  } = props;

  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo();
        setSessionInfo(userInfoResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );
  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  return (
    <Provider store={store}>
      <ExperimentsProvider
        initState={{
          experiments: experiments?.experiments || [],
          trackingProperties: experiments?.trackingProperties,
          callState: CallState.NotCalled,
        }}
      >
        <ExperimentsHookProvider isLoggedIn={Boolean(sessionInfo?.csrfToken)}>
          <UserSourceProvider>
            <Router history={baseHistory}>
              <AxiosInterceptors isAgentPortal={isAgentPortal} />
              <ClientContext.Provider
                value={{
                  ...clientAssets,
                  sessionInfo,
                  isAgentPortal,
                }}
              >
                <div className="App">
                  <StylesProvider
                    generateClassName={generateClassName}
                    injectFirst
                  >
                    <ThemeProvider theme={activeTheme}>
                      {messages != null ? (
                        <IntlProvider
                          locale={locale}
                          defaultLocale="en"
                          messages={messages}
                        >
                          <Switch>
                            {isAgentPortal && (
                              <Route path="*">
                                <AgentBanner
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </Route>
                            )}
                            <Route path="*">
                              <RewardsBanner />
                            </Route>
                          </Switch>
                          <Body
                            sessionInfo={sessionInfo}
                            history={baseHistory}
                          />
                        </IntlProvider>
                      ) : (
                        <>{/* Loading */}</>
                      )}
                    </ThemeProvider>
                  </StylesProvider>
                </div>
              </ClientContext.Provider>
            </Router>
          </UserSourceProvider>
        </ExperimentsHookProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export const Body = (props: {
  sessionInfo?: SessionInfo;
  history: H.History;
}) => {
  const { sessionInfo, history } = props;
  const dispatch = useDispatch();

  const expState = useExperiments();

  const StaysBookingExperiment = getExperimentVariant(
    expState.experiments,
    STAYS_BOOKING_EXPERIMENT
  );
  const isStaysBookingEnabled = React.useMemo(
    () => StaysBookingExperiment === ExperimentVariant.AVAILABLE,
    [StaysBookingExperiment]
  );

  React.useEffect(() => {
    // Redirect to home page if vacation rentals booking is disabled
    if (expState.experiments.length > 0 && !isStaysBookingEnabled) {
      history.push(PATH_STAYS);
    }
  }, [expState, history]);

  useEffect(() => {
    if (sessionInfo) {
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      dispatch(fetchRewardsAccounts(true, sessionInfo));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [sessionInfo, dispatch]);

  useMedalliaFeedback();

  if (isStaysBookingEnabled) {
    return (
      <Box className="main-section">
        <Switch>
          <Route path={PATH_VACATION_RENTALS_SHOP}>
            <VacationRentalShop />
          </Route>
          <Route path={PATH_VACATION_RENTALS_BOOK}>
            <VacationRentalBook />
          </Route>
          <Route render={() => <Redirect to={PATH_STAYS} />} />
        </Switch>
      </Box>
    );
  }

  return null;
};

export default App;
