import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  AmenityCategoryEnum,
  AmenityCategoryIconMap,
  AmenityCategoryOrder,
  amenityKindToCategoryMap,
} from "../Shop/constants";
import { Amenity } from "redmond/apis/tysons/vacation-rentals";
import "./styles.scss";

const MIN_NUM_CATEGORIES_TO_SHOW = 4;

interface ICategorizedAmenities {
  amenities: Amenity[];
}

export const CategorizedAmenities = ({ amenities }: ICategorizedAmenities) => {
  const [viewAllAmenities, setViewAllAmenities] = useState(false);

  const categorizeAmenities = (amenities: Amenity[]) => {
    const categorizedAmenities = {} as any;

    amenities.map((amenity) => {
      const category =
        amenityKindToCategoryMap[amenity.kind] || AmenityCategoryEnum.General;
      categorizedAmenities[category]
        ? categorizedAmenities[category].push(amenity.displayName)
        : (categorizedAmenities[category] = [amenity.displayName]);
    });
    return categorizedAmenities;
  };

  const categorizedAmenities = categorizeAmenities(amenities);
  let categoriesSorted = Object.keys(categorizedAmenities).sort(
    (a, b) =>
      AmenityCategoryOrder.indexOf(a as AmenityCategoryEnum) -
      AmenityCategoryOrder.indexOf(b as AmenityCategoryEnum)
  );

  return (
    <Box className="vr-categorized-amenities">
      <Box style={{ columnCount: 2 }}>
        {categoriesSorted
          .slice(0, viewAllAmenities ? undefined : MIN_NUM_CATEGORIES_TO_SHOW)
          .map((category) => (
            <Box
              display={"inline-block"}
              width={"100%"}
              pb="20px"
              key={category}
            >
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: "10px" }}
              >
                <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
                  {AmenityCategoryIconMap[category]}
                  <Typography variant="h6">{category}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  {categorizedAmenities[category]
                    .sort((a: string, b: string) => a.localeCompare(b))
                    .slice(
                      0,
                      viewAllAmenities ? undefined : MIN_NUM_CATEGORIES_TO_SHOW
                    )
                    .map((amenity: string) => (
                      <Box
                        display="flex"
                        style={{ gap: "10px" }}
                        paddingBottom="10px"
                        alignItems={"center"}
                        key={amenity}
                      >
                        <FontAwesomeIcon
                          width={"20px"}
                          height={"20px"}
                          style={{ opacity: 0.5, minWidth: "20px" }}
                          color="#AAAAAA"
                          size="sm"
                          icon={faCheck}
                        />
                        <Typography>{amenity}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      {categoriesSorted.length > MIN_NUM_CATEGORIES_TO_SHOW && (
        <Typography
          variant="h6"
          style={{ color: "#013D58", cursor: "pointer" }}
          className="hover-underline"
          onClick={() => setViewAllAmenities((prev) => !prev)}
        >
          {viewAllAmenities ? "View less" : `View all (${amenities.length})`}
        </Typography>
      )}
    </Box>
  );
};
