import { Airline, AgentLocator } from ".";
import { Amount, Uuid } from "..";

export enum NonCfarEnum {
  /**
   * Fully refundable
   */
  AirlineRefund = "AirlineRefund",
  ContactAirline = "ContactAirline",
  ContactCustomerService = "ContactCustomerService",
  FTC = "Ftc",
  FTCWithPenalty = "FtcWithPenalty",
  MultiTicket = "MultiTicket",
  NonRefundable = "NonRefundable",
  NonCancellable = "NonCancellable",
  PartialRefund = "PartialRefund",
  RefundableComplex = "RefundableComplex",
  TicketedVoid = "TicketedVoid",
  // Rare cases where the flights service doesn't give us a void window but says it's voidable
  TicketedVoidUnknownWindow = "TicketedVoidUnknownWindow",
  // Fare Optimization bookings that haven't been ticketed yet
  DelayedTicketingCancel = "DelayedTicketingCancel",
  TicketlessVoid = "TicketlessVoid",
  MultiProvider = "MultiProvider",
  CancellationPending = "CancellationPending",
}
export enum CancelModalFlowStepEnum {
  loading = "loading",
  policy = "policy",
  confirm = "confirm",
  success = "success",
  nonRefundable = "nonrefundable",
  support = "support",
  pending = "pending",
  cancelled = "cancelled",
  tryAgain = "tryAgain",
}
export enum CancellationResponseStatus {
  Success = "Success",
  Failure = "Failure",
}
export enum CancelScenarioEnum {
  PartialRefund = "PartialRefund",
  FullyRefundableComplex = "FullyRefundableComplex",
  NonRefundable = "NonRefundable",
  Pending = "Pending",
  CancellationFailure = "CancellationFailure",
  PartiallyRefundableComplex = "PartiallyRefundableComplex",
  FullRefund = "FullRefund",
  ContactCustomerService = "ContactCustomerService",
  AirlineControl = "AirlineControl",
  BookingPending = "BookingPending",
  CancellationPending = "CancellationPending",
  Canceled = "Canceled",
  Departed = "Departed",
  NonCfar = "NonCfar",
  TRVKOutage = "TRVKOutage",
}
export interface BaseCopy {
  body: string[];
  importantInfo?: string[];
  title: string;
}

export interface CopyWithDisclaimer extends BaseCopy {
  disclaimer?: string;
}

export interface CopyWithInfo extends BaseCopy {
  informativeSection: BaseCopy;
}

/**
 * @deprecated use `CopyWithDisclaimer` instead
 */
export interface CopyWithExtra extends BaseCopy {
  disclaimer?: string;
  importantInfo: string[];
}

/**
 * @deprecated use `CopyWithInfo` instead
 */
export interface CancelCopy extends CopyWithExtra {
  informativeSection: BaseCopy;
}

export interface CancelScenario {
  GroundCancelScenario?: CancelScenarioEnum;
  HotelCancelScenario?: CancelScenarioEnum;
  FlightCancelScenario?: CancelScenarioEnum;
  customerServiceCopy?: CancelCopy;
  cancelCopy?: CancelCopy;
  cancelConfirmationCopy?: CancelCopy;
  preparedPayment?: string;
  refundAmount?: Amount;
}

export interface CancellationResult {
  success: boolean;
}
export interface CancelErrorResponse {
  code: string;
  details: string;
}
export interface CancellationInfoRequest {
  reservationId?: string;
  itineraryId?: Uuid;
  customerReservationId?: string;
}
export interface CancellationRequest extends CancellationInfoRequest {
  cancelScenario: CancelScenario;
}
export interface FlightCancellationRequest
  extends Omit<CancellationRequest, "cancelScenario"> {
  cancelScenario: FlightCancelInfoResponse;
}
export interface CancellationInfoResponse {
  Response: CancellationResponseStatus;
  value?: CancelScenario;
}

export interface Policies {
  policy: NonCfarEnum;
  agentLocator: {
    provider: string;
    unscopedValue: string;
    userString: string;
  };
  preparedPayment: string;
  cancelConfirmationCopy: CopyWithDisclaimer;
  cancelCopy: CopyWithInfo;
  customerServiceCopy?: CopyWithDisclaimer;
  sessionId?: Uuid;
}
export interface FlightCancelInfoResponse {
  CancelScenario: CancelScenarioEnum;
  NonCfar: NonCfarEnum;
  agentLocator?: AgentLocator;
  airlines?: Airline[];
  cancelConfirmationCopy?: CopyWithExtra;
  cancelCopy?: CancelCopy;
  copy?: BaseCopy;
  customerServiceCopy?: CopyWithExtra;
  firstPolicy?: NonCfarEnum;
  penaltyAmount?: Amount;
  preparedPayment?: string;
  secondPolicy?: NonCfarEnum;
  voidWindowEnd?: number;
  policies?: Policies[];
  sessionId?: Uuid;
}
export interface CancellationResponse extends CancellationInfoResponse {
  errors?: CancelErrorResponse[];
  copy?: BaseCopy;
}

export enum CarProvider {
  avis = "Avis",
  avisRentACar = "Avis rent a car",
  avs = "AVS",
  bgt = "BGT",
  budget = "Budget",
  budgetRentACar = "Budget rent a car",
  foxRentACar = "Fox rent-a-car",
  fx = "FX",
  dollar = "Dollar",
  dollarRentACar = "Dollar rent a car",
  economyRentACar = "Economy rent-a-car",
  europcar = "Europcar",
  fox = "Fox",
  greenMotion = "Green motion",
  hertz = "Hertz",
  mexRentACar = "Mex rent a car",
  nu = "NU",
  nuCar = "Nu car",
  nuCarRentals = "NU car rentals",
  paylessCarRental = "Payless car rental",
  thrifty = "Thrifty",
  thriftyCarRental = "Thrifty car rental",
  ze = "ZE",
  zr = "ZR",
}

export enum FlightProvider {
  sabre = "Sabre",
  travelFusion = "TF",
}

export enum HotelProvider {
  agoda = "Agoda",
  budapest = "Budapest",
  dhisco = "Dhisco",
  expedia = "Expedia",
  getARoom = "Getaroom",
  hotelBeds = "Hotelbeds",
  priceline = "Priceline",
  scrape = "Scrape",
  w2m = "W2m",
}
