import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import React, { useState } from "react";
import { Bedroom, Bathroom } from "redmond/apis/tysons/vacation-rentals";
import "./styles.scss";
import { useDeviceTypes } from "../../../../hooks/useDeviceTypes";

export interface IRoomsAndBeds {
  bedrooms: Bedroom[];
  bathrooms: Bathroom[];
}

export const RoomsAndBeds = ({ bedrooms, bathrooms }: IRoomsAndBeds) => {
  const {
    matchesMobile,
    matchesMediumScreen,
    matchesLargeScreen,
    matchesXLargeScreen,
    matchesXXLargeScreen,
  } = useDeviceTypes();

  const [viewAllBedsAndBaths, setViewAllBedsAndBaths] = useState(false);

  const NUM_BEDS_OR_BATHS_TO_SHOW = matchesXXLargeScreen
    ? 8
    : matchesXLargeScreen
    ? 6
    : matchesLargeScreen
    ? 4
    : matchesMediumScreen
    ? 8
    : matchesMobile
    ? 4
    : 6;

  const bedroomsArr = bedrooms.map((bedroom: Bedroom, index) => {
    return (
      <Box
        key={`bedroom-${index}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        border="0.5px solid #CECFCD"
        width="100px"
        height="90px"
        padding="20px"
        borderRadius="8px"
        style={{ gap: "2px" }}
      >
        <Icon name={IconName.BedOutlineIcon} className="icon-bedroom-large" />
        <Typography variant="body1">Bedroom {index + 1}</Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#505050",
          }}
        >
          {bedroom.description}
        </Typography>
      </Box>
    );
  });

  const bathroomsArr = bathrooms.map((bathroom, index) => {
    return (
      <Box
        key={index}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        border="0.5px solid #CECFCD"
        width="100px"
        height="90px"
        padding="20px"
        borderRadius="8px"
        style={{ gap: "2px" }}
      >
        <Icon
          name={IconName.BathTubOutlineIcon}
          className="icon-bathroom-large"
        />
        <Typography variant="body1">Bathroom {index + 1}</Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#505050",
          }}
        >
          {bathroom.description}
        </Typography>
      </Box>
    );
  });

  const combinedBedroomsAndBathrooms = [...bedroomsArr, ...bathroomsArr];

  return (
    <Box
      className="vacation-rental-rooms-and-beds"
      display="flex"
      flexDirection="column"
      style={{ gap: "25px" }}
    >
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          width: "100%",
          maxWidth: "700px",
          minWidth: "300px",
        }}
      >
        {viewAllBedsAndBaths
          ? combinedBedroomsAndBathrooms
          : combinedBedroomsAndBathrooms.slice(0, NUM_BEDS_OR_BATHS_TO_SHOW)}
      </Box>
      {combinedBedroomsAndBathrooms.length > NUM_BEDS_OR_BATHS_TO_SHOW && (
        <Typography
          variant="h6"
          style={{ color: "#013D58", cursor: "pointer" }}
          className="hover-underline"
          onClick={() => setViewAllBedsAndBaths((prev) => !prev)}
        >
          {viewAllBedsAndBaths
            ? "View less"
            : `View all (${combinedBedroomsAndBathrooms.length})`}
        </Typography>
      )}
    </Box>
  );
};
