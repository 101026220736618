import { ITripRoute } from "../../flights/interfaces";
import { ITripFilter } from "./fetch-calendar";

export interface IShopQuery {
  passengers: any;
  route: ITripRoute;
  inclusion: DataInclusion;
  tripFilter: ITripFilter;
  ShopQuery: ShopQueryType;
  shopUpsellFares: boolean;
  departureDate: string;
  returnDate?: string; // Allow one-way queries
  minimumAdvance?: number;
  originType?: string;
  destination_type?: string;
  departureTimeSpan?: string; // ex 0900/1330
  returnTimeSpan?: string;
  plusMinDays?: number;
  alternateOrigins?: string[];
  alternateDestinations?: string[];
  logRetention?: Retention;
  userInformation?: UserInformation;
  conversationId?: ConversationFlow;
  disableMultiTicketTypes?: MultiTicketType[];
}

export enum ShopQueryType {
  Filtered = "Filtered",
}

export interface DataInclusion {
  baggage: boolean;
}

export enum Retention {
  Forever,
  Discard,
  TTL,
}
export interface UserInformation {
  ipAddress: string;
  userCountry: string;
}
export interface ConversationFlow {
  id: string;
}
export enum MultiTicketType {
  Single,
  HackerFare,
  VirtualInterline,
}

export interface CityNameRequest {
  cityOrAirportCodes: string[];
}

export interface CityNameResponse {
  [key: string]: string;
}
