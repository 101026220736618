import { AirportRegion, TagColors } from "../../common";
import { ShopFilter, PriceDropProtection } from "./shop-summary";
import { TripSummaryContext } from "./trip-summary";

export type AlertKey = FlightKey;

export enum AlertKeyEnum {
  FlightKey = "FlightKey",
}

export enum WatchState {
  NotWatching,
  Watching,
  JustWatched,
  InProcess,
}

export type CreateWatchAlertResponse =
  | CreateWatchAlertFailure
  | CreateWatchAlertSuccess;

export enum CreateWatchAlertResponseEnum {
  Success = "Success",
  Failure = "Failure",
}

export interface CreateWatchAlertSuccess {
  Response: CreateWatchAlertResponseEnum.Success;
  value: any;
}

export interface CreateWatchAlertFailure {
  Response: CreateWatchAlertResponseEnum.Failure;
  errors: ErrorCode[];
}

export interface DeleteWatchAlertRequest {
  key: AlertKey;
}

export type DeleteWatchAlertResponse = ErrorCode | DeleteWatchAlertSuccess;

export interface DeleteWatchAlertSuccess {
  value: any;
}

export interface ErrorCode {
  code: string;
  details?: string;
}

export interface FlightAlertKey {
  filter: ShopFilter;
  origin: AirportRegion;
  destination: AirportRegion;
  departureDate: string;
  returnDate?: string;
}

export type MessageMethod = Email | MultiMethod | Push | Sms;

export interface Email {
  email: string;
  MessageMethod: MessageMethodEnum.Email;
}

export interface MultiMethod {
  push?: Push;
  email?: Email;
  sms?: Sms;
  MessageMethod: MessageMethodEnum.MultiMethod;
}

export interface Push {
  MessageMethod: MessageMethodEnum.Push;
}

export interface Sms {
  MessageMethod: MessageMethodEnum.Sms;
}

export interface FlightKey {
  value: FlightAlertKey;
  AlertKey: AlertKeyEnum;
}

export enum MessageMethodEnum {
  Email = "Email",
  MultiMethod = "MultiMethod",
  Push = "Push",
  Sms = "Sms",
}

export interface UpdateWatchAlertRequest {
  key: AlertKey;
  method: MessageMethod;
}

export type UpdateWatchAlertResponse = ErrorCode | UpdateWatchAlertSuccess;

export interface UpdateWatchAlertSuccess {
  value: WatchAlertViews;
}

export interface WatchAlertView {
  id: string;
  key: AlertKey;
  method: MessageMethod;
  recommendation: string;
  attribution?: any;
  priceDropProtection: PriceDropProtection;
  passengers: { [key: string]: number };
}

export interface WatchAlertViews {
  watches: WatchAlertView[];
  tripContext: TripSummaryContext;
}

export const getWatchDetails = (
  watch: WatchAlertView,
  context: TripSummaryContext | null,
  formatDate: (date: string) => string,
  getFilterLabel: (filter: ShopFilter) => string
  // buyString: string,
  // waitString: string
) => {
  const showFilterLabel = [
    ShopFilter.NonStop,
    ShopFilter.NonStopNoLCC,
    ShopFilter.NoLCC,
  ].includes(watch.key.value.filter);
  return {
    // titleTag: {
    //   label: watch.recommendation === "Buy" ? buyString : waitString,
    //   type: watch.recommendation === "Buy" ? TagColors.GREEN : TagColors.GREY,
    // },
    subtitleTag: showFilterLabel
      ? {
          label: getFilterLabel(watch.key.value.filter),
          type: TagColors.GREY,
        }
      : undefined,
    title: `${
      context?.airports[watch.key.value.origin.code]?.cityName ||
      watch.key.value.origin.code
    } to ${
      context?.airports[watch.key.value.destination.code]?.cityName ||
      watch.key.value.destination.code
    }`,
    startDate: formatDate(watch.key.value.departureDate),
    endDate: watch.key.value.returnDate
      ? formatDate(watch.key.value.returnDate)
      : undefined,
    subtitle: watch.key.value.returnDate
      ? "Round-trip Flight"
      : "One-way Flight",
  };
};
