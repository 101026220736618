import { MediaAssetTagEnum } from "redmond/common";

export const CATEGORY_SORT_ORDER = [
  MediaAssetTagEnum.LivingArea,
  MediaAssetTagEnum.Kitchen,
  MediaAssetTagEnum.DiningArea,
  MediaAssetTagEnum.Bedroom,
  MediaAssetTagEnum.Bathroom,
  MediaAssetTagEnum.Exterior,
  MediaAssetTagEnum.Unknown,
];

export const CATEGORY_DISPLAY_NAME_MAP = {
  [MediaAssetTagEnum.LivingArea]: "Living Area",
  [MediaAssetTagEnum.Kitchen]: "Kitchen",
  [MediaAssetTagEnum.DiningArea]: "Dining Area",
  [MediaAssetTagEnum.Bedroom]: "Bedroom",
  [MediaAssetTagEnum.Bathroom]: "Bathroom",
  [MediaAssetTagEnum.Exterior]: "Exterior",
  [MediaAssetTagEnum.Unknown]: "Additional Photos",
};
