import { CorpFlightFare } from "../../capone-corporate";
import {
  AirportRegion,
  Prices,
  Amount,
  TrackingProperties,
} from "../../common";
import {
  AirlineMap,
  AirportMap,
  MultiTicketTypeEnum,
} from "../../trips-module";
import { Offer as PriceFreezeOffer } from "./price-freeze";
import { TripSegment } from "./trip-summary";
import { TravelWalletOffer } from "./wallet";

export enum Dealness {
  Fair = "fair",
  Good = "good",
  Great = "great",
  Wait = "wait",
}

export interface FareScore {
  comfort: number;
  value: number;
  isBest: boolean;
  isCheapest: boolean;
  isBestQuality: boolean;
  isFastest: boolean;
}

export interface FareShelf {
  outgoing: SegmentShelf;
  returning?: SegmentShelf;
}

export interface Interval {
  dates: string;
  copy: string;
}

export interface Intervals {
  normal?: Interval;
  warning?: Interval;
  danger?: Interval;
}

export interface Prediction extends BasePrediction {
  dealness: Dealness;
  pricePrediction: PricePrediction;
  predictionCopy?: PredictionCopy;
  lowestPrice: Prices;
  priceDropProtection: PriceDropProtection;
  PredictionResult: PredictionResultEnum.Prediction;
  recommendationV2: RecommendationV2Enum;
}

export type PriceDropProtection = IsEligible | NotEligible;

export enum RecommendationV2Enum {
  Buy = "Buy",
  Wait = "Wait",
  BuyNeutral = "BuyNeutral",
  WaitNeutral = "WaitNeutral",
}

export enum PriceDropProtectionEnum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
}

export interface IsEligible {
  PriceDropProtection: PriceDropProtectionEnum.IsEligible;
  candidateId: string;
  minimumRefund: DisplayAmount;
  maximumRefund: DisplayAmount;
  monitoringDuration: MonitoringDuration;
  trackingPropertiesV2?: TrackingProperties;
}

export interface MonitoringDuration {
  inSeconds: number;
}

export interface NotEligible {
  PriceDropProtection: PriceDropProtectionEnum.NotEligible;
  reason?: string;
  trackingPropertiesV2?: TrackingProperties;
}

export interface PricePrediction {
  minPrice?: DisplayAmount;
  maxPrice?: DisplayAmount;
}
export interface DisplayAmount {
  amount: Amount;
  display: string;
}

export interface PredictionCopy {
  origin: string;
  destination: string;
  travelDates: string;
  lowestPriceLabel: string;
  lowestPrice: string;
  carrier: string;
  dealness: string;
  recommendationTitle: string[];
  recommendationBody: string[];
  recommendationTitleWatching: string[];
  recommendationBodyWatching: string[];
  recommendationTitleJustWatched: string[];
  recommendationBodyJustWatched: string[];
  intervals: Intervals;
}

export type PredictionResultResponse = Prediction | UnavailablePrediction;

export enum PredictionResultEnum {
  Prediction = "Prediction",
  UnavailablePrediction = "UnavailablePrediciton",
}

export interface Route {
  origin: AirportRegion;
  destination: AirportRegion;
}

export interface SegmentShelf {
  rating: number;
  brandName: string;
  shortBrandName: string;
  opaqueMapperStep?: string;
}

export enum ShopFilter {
  ShortLayover = "ShortLayover",
  NonStopNoLCC = "NonStopNoLCC",
  NoLCC = "NoLCC",
  NonStop = "NonStop",
  ShortLayoverNoLCC = "ShortLayoverNoLCC",
  NoFilter = "NoFilter",
}

export enum PushNotificationFilter {
  EmailOnly = "EmailOnly",
  AppNotifications = "AppNotifications",
}

export enum Platform {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export interface ShopSummaryRequest {
  route: Route;
  departureDate: string;
  returnDate?: string;
  tripFilter: ShopFilter;
  passengers: { [key: string]: number };
  platform?: Platform;
  originLocationLabel?: string;
  destinationLocationLabel?: string;
  fareClass?: string[];
}

export interface ShopSummaryResponse {
  tripSummaries: TripSummary[];
  prediction: PredictionResultResponse;
  priceFreezeOffer?: OfferWithSuggested;
}

export interface TripFare {
  amount: Prices;
  fareId: string;
  fareScore: FareScore;
  fareShelf?: FareShelf;
  isMultiTicket: boolean;
}

export interface TripSlice {
  // TODO: ask about how it came to having both shop-summary and trip-summary; what are their differences in purpose.
  outgoing: boolean;
  departureTime: string;
  originCode: string;
  originName: string;
  arrivalTime: string;
  destinationCode: string;
  destinationName: string;
  stops: number;
  totalDurationMinutes: number;
  segmentDetails: TripSegment[];
}

export interface TripSummary {
  tripId: string;
  outgoingSlice: TripSlice;
  returningSlice?: TripSlice;
  tripFares: TripFare[];
}

export interface UnavailablePrediction extends BasePrediction {
  reason: string;
  PredictionResult: PredictionResultEnum.UnavailablePrediction;
}

export interface BasePrediction {
  PredictionResult: PredictionResultEnum;
}

export interface OfferWithSuggested {
  offer: PriceFreezeOffer;
  cheapestTrip: {
    fareId: string;
    tripId: string;
    isCheapest: boolean;
  };
}

export interface OffersResponse {
  bestOfferOverall?: TravelWalletOffer;
  offersByTripId: { [key: string]: TravelWalletOffer };
}

export interface ShopSummaryResponseV2 {
  flights: Flights;
  prediction: PredictionResultResponse;
  priceFreezeOffer?: OfferWithSuggested;
  offers?: OffersResponse;
}

export interface Slice {
  id: string;
  departure: string;
  origin: string;
  marketingAirline: string;
  arrival: string;
  destination: string;
  stops: number;
  totalDurationMinutes: number;
  segments: Segment[];
  highestScore: { value: number };
  lowestPrice?: number;
  domestic: boolean;
  hasSelfTransferLayover?: boolean;
}

export interface Flights {
  shopId: string;
  outbound: Outbound[];
  slices: { [key: string]: Slice };
  trips: { [key: string]: Trip };
  fareSlices: { [key: string]: FlightFareSlice };
  fares: { [key: string]: FlightFare | CorpFlightFare };
  airports: AirportMap;
  airlines: AirlineMap;
}

export interface FlightFareSlice {
  id: string;
  slice: string;
  fareShelf: { value: number };
  fareBrandName: string;
  segments: FlightFareSegment[];
  tags: FlightTags;
}

export interface FlightTags {
  isBest: boolean;
  isCheapest: boolean;
  isBestQuality: boolean;
  isFastest: boolean;
}
export interface FlightFareSegment {
  availableSeats: number;
}

export interface FlightFare {
  id: string;
  tripId: string;
  outbound: string;
  return?: string;
  amount?: Prices;
  fareScore: { value: number };
  multiTicketType: MultiTicketTypeEnum;
}

export interface OutboundFares {
  fareSlice: string;
  amount?: Prices;
  next?: string[];
  example: Example;
}

export interface Outbound {
  slice: string;
  fares: OutboundFares[];
  earliestReturnDeparture?: string;
  earliestReturnArrival?: string;
  latestReturnDeparture?: string;
  latestReturnArrival?: string;
}

export interface Example {
  trip: string;
  fare: string;
}

export interface Trip {
  id: string;
  outbound: string;
  return?: string;
  fares: string[];
}

export interface Segment {
  marketingAirline: string;
  operatingAirline: string;
  flightNumber: string;
  departure: string;
  origin: string;
  arrival: string;
  destination: string;
  stopoverDurationMinutes?: number;
  plusDays: number;
}
