import { actions } from "../actions";
import { ListingSearchResult, HotelDetailsEntrySourceEnum } from "redmond";
import {
  SELECT_HOME,
  SET_SELECTED_LISTING_ID,
  FETCH_VACATION_RENTAL_SHOP,
  SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED,
  SET_NOTIFY_IF_SHOP_AND_BOOK_PRICE_DIFFER_FLAG,
  SET_LISTING_CALENDAR,
  SET_VACATION_RENTAL_ENTRY_SOURCE,
  SET_VACATION_RENTAL_SORT_ORDER,
} from "../actions/constants";
import { ListingId, VacationRentalCalendarResponse } from "redmond/apis/tysons/vacation-rentals";
export interface IVacationRentalShopState {
  selectedHome: ListingSearchResult | null;
  selectedHomeCalendar?: VacationRentalCalendarResponse;
  vacationRentalListingId: ListingId | null;
  vacationRentalShopCallState: VacationRentalShopCallState;
  vacationRentalShopCallError?: VacationRentalShopCallError;
  vacationRentalNotifyIfShopAndBookPriceDiffer: boolean;
  vacationRentalDetailsEntrySource?: HotelDetailsEntrySourceEnum;
  vacationRentalSortOrder?: string;
}

export enum VacationRentalShopCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum VacationRentalShopCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}

const initialState: IVacationRentalShopState = {
  selectedHome: null,
  vacationRentalListingId: null,
  vacationRentalShopCallState: VacationRentalShopCallState.NotCalled,
  vacationRentalShopCallError: undefined,
  vacationRentalNotifyIfShopAndBookPriceDiffer: true,
  vacationRentalDetailsEntrySource: undefined,
  vacationRentalSortOrder: undefined,
};

export const reducer = (
  state: IVacationRentalShopState = initialState,
  action: actions.VacationRentalShopActions
): IVacationRentalShopState => {
  switch (action.type) {
    case FETCH_VACATION_RENTAL_SHOP:
      return {
        ...state,
        vacationRentalShopCallState: VacationRentalShopCallState.InProcess,
      };
    case SELECT_HOME:
      return {
        ...state,
        selectedHome: action.home,
        vacationRentalShopCallState: VacationRentalShopCallState.Success,
      };
    case SET_LISTING_CALENDAR:
      return {
        ...state,
        selectedHomeCalendar: action.calendar,
      };
    case SET_SELECTED_LISTING_ID:
      return {
        ...state,
        vacationRentalListingId: action.id,
      };
    case SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        vacationRentalShopCallState: VacationRentalShopCallState.Failed,
        vacationRentalShopCallError: action.vrShopCallError,
      };      
    case SET_NOTIFY_IF_SHOP_AND_BOOK_PRICE_DIFFER_FLAG:
      return {
        ...state,
        vacationRentalNotifyIfShopAndBookPriceDiffer: action.notify,
      }
    case SET_VACATION_RENTAL_ENTRY_SOURCE:
      return {
        ...state,
        vacationRentalDetailsEntrySource: action.entrySource,
      }
    case SET_VACATION_RENTAL_SORT_ORDER:
      return {
        ...state,
        vacationRentalSortOrder: action.sortOrder,
      }
    default:
      return { ...state };
  }
};

export * from "./selectors";
