import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { fetchVacationRentalShop } from "../../actions/actions";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  getViewedVacationRentalDetailsProperties,
  VacationRentalShopCallState,
} from "../../reducer";

import { withRouter } from "react-router";
import { Shop } from "./component";
import { getUserSelectedAccountReferenceId } from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getPetsCount,
  getUntilDate,
} from "../../../search/reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  const callState = getVacationRentalShopCallState(state);
  return {
    selectedListing: getVacationRentalShopSelectedListing(state),
    selectedRewardsAccount: getUserSelectedAccountReferenceId(state),
    fetchedVacationRentalDetails:
      callState === VacationRentalShopCallState.Failed ||
      callState === VacationRentalShopCallState.Success,
    viewedVacationRentalDetailsProperties:
      getViewedVacationRentalDetailsProperties(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    childrenCount: getChildrenCount(state),
    adultsCount: getAdultsCount(state),
    petsCount: getPetsCount(state),
  };
};

const mapDispatchToProps = {
  fetchVacationRentalShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedShop = withRouter(connector(Shop));
