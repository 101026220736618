import { Box, Typography } from "@material-ui/core";
import {
  GoogleMap,
  Circle,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import { Icon, IconName } from "halifax";
import React from "react";
import "./styles.scss";
import { ListingLocation } from "redmond";
import { ListingAddressEnum } from "redmond/apis/tysons/vacation-rentals";
interface Map {
  coordinates: google.maps.LatLng;
  listingName: string;
  location: ListingLocation;
  matchesMobile: boolean;
}

export const Map = (props: Map) => {
  const { coordinates, location, matchesMobile } = props;

  let city, state;

  if (
    location?.address?.ListingAddress === ListingAddressEnum.FullAddress ||
    location?.address?.ListingAddress === ListingAddressEnum.ObfuscatedAddress
  ) {
    city = location?.address?.city;
    state = location?.address?.state;
  }

  return (
    <>
      {city && state && (
        <Typography
          style={{
            fontSize: "16px",
            color: "#676868",
            paddingBottom: "25px",
          }}
        >
          {city}, {state}
        </Typography>
      )}
      <Box
        className="vacation-rental-mobile-map"
        style={{
          height: matchesMobile ? "400px" : "450px",
          width: "auto",
          position: "relative",
        }}
      >
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          options={{
            clickableIcons: false,
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: false,
            fullscreenControl: true,
            // TODO: Add more styles
            // styles: [
            //   {
            //     featureType: "transit",
            //     elementType: "labels.icon",
            //     stylers: [{ visibility: "off" }],
            //   },
            // ],
          }}
          center={coordinates}
          zoom={13}
        >
          <Circle
            center={coordinates}
            options={{
              center: coordinates,
              radius: 500,
              strokeColor: "#fff",
              strokeOpacity: 1,
              strokeWeight: 1,
              fillColor: "rgba(2, 118, 177, 1.0)",
              fillOpacity: 0.35,
            }}
          />
          <OverlayViewF
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={coordinates}
            getPixelPositionOffset={() => ({ x: -10, y: -10 })}
          >
            <Box className="map-marker">
              <Icon name={IconName.LocationDotPin} />
            </Box>
          </OverlayViewF>
        </GoogleMap>
      </Box>
    </>
  );
};
