import { Prices, TrackingProperties } from "../common";
import {
  AgeBandSelection,
  BookingQuestionAnswer,
  ExperienceId,
  ExperienceInclusions,
  ExperienceItinerary,
  ExperienceLanguageGuide,
  ExperienceLogistics,
  ExperienceOptionId,
  ExperiencesCancellationPolicy,
  ExperienceSupplierInfo,
  PriceBreakdownLineItem,
  ReviewSummary,
} from "../experiences-module";
import { PaymentBreakdown, TripContext } from "./itinerary";

export interface ExperienceReservationRequest {
  states: ExperienceReservationState[];
  referenceDateTime: string;
}

export enum ExperienceReservationState {
  Future = "Future",
  Present = "Present",
  Past = "Past",
  Canceled = "Canceled",
}

export interface ExperienceReservationResponse {
  itineraries: {
    [key: string]: ExperienceReservation[];
  };
  context: TripContext;
}

export interface ExperienceReservation {
  reservation: ExperienceReservationDetails;
  paymentBreakdown: PaymentBreakdown;
}

export interface ExperienceReservationDetails {
  customerReservationId: string;
  cancellationPolicy?: ExperiencesCancellationPolicy;
  bookingDetails?: ExperienceBookingDetails;
  trackingProperties?: TrackingProperties;
  experienceSelection: ExperienceSelection;
  experienceDetails?: ExperienceDetails;
  status: ExperienceBookingStatusEnum;
  conversationId: string;
  ticketInfo?: VoucherInfo;
  providerReservationId: string;
  bookingSession?: BookingSession;
}

export interface ExperienceBookingDetails {
  bookerInfo: BookerInfo;
  bookingQuestionAnswers: Array<BookingQuestionAnswer>;
  bookingCommunicationInfo: BookingCommunicationInfo;
  additionalBookingDetails?: AdditionalBookingDetails;
}

export interface BookerInfo {
  firstName: string;
  lastName: string;
}

export interface BookingCommunicationInfo {
  /**
   * Telephone number to be used for correspondence from the supplier regarding the booking. This can be a mobile or land-line number and should be the phone number at which the booker is most easily contactable. It is essential that this string include: the plus + symbol at the beginning, followed by the country code, followed by the remaining numerals. Spaces, hyphens and parentheses can be included or omitted, but the string must start with the plus symbol and country code; e.g. +61 400 580 860.
   * @type {string}
   * @memberof BookingCommunicationInfo
   */
  phone: string;
  /**
   * One user email address to be used for correspondence from the supplier regarding the booking.This needs to be <=165 characters so we can add our support email and remain under 200 characters.
   * @type {string}
   * @memberof BookingCommunicationInfo
   */
  email: string;
}

export interface AdditionalBookingDetails {
  voucherDetails?: VoucherDetails;
}

export interface VoucherDetails {
  format: VoucherFormat;
  email?: string;
  voucherText?: string;
  companyName?: string;
  phone?: string;
}

/**
 * @type VoucherFormat
 * @export
 */
export type VoucherFormat =
  | ({ VoucherFormat: "Html" } & VoucherFormatHtml)
  | ({ VoucherFormat: "Pdf" } & VoucherFormatPdf)
  | ({ VoucherFormat: "Unknown" } & VoucherFormatUnknown);

export const VoucherFormatEnum = {
  Html: "Html",
  Pdf: "Pdf",
  Unknown: "Unknown",
} as const;

export type VoucherFormatEnum =
  (typeof VoucherFormatEnum)[keyof typeof VoucherFormatEnum];

export interface VoucherFormatHtml {
  VoucherFormat: VoucherFormatEnum;
}

export interface VoucherFormatPdf {
  VoucherFormat: VoucherFormatEnum;
}

export interface VoucherFormatUnknown {
  VoucherFormat: VoucherFormatEnum;
}

export interface VoucherInfo {
  url: string;
  format: VoucherFormat;
  type: VoucherType;
  isVoucherRestrictionRequired: boolean;
}
/**
 * @type VoucherType
 * @export
 */
export type VoucherType =
  | ({ VoucherType: "Standard" } & VoucherTypeStandard)
  | ({ VoucherType: "Supplier" } & VoucherTypeSupplier)
  | ({ VoucherType: "Unknown" } & VoucherTypeUnknown);

export const VoucherTypeEnum = {
  Standard: "Standard",
  Supplier: "Supplier",
  Unknown: "Unknown",
} as const;

export type VoucherTypeEnum =
  (typeof VoucherTypeEnum)[keyof typeof VoucherTypeEnum];

export interface VoucherTypeStandard {
  VoucherType: VoucherTypeEnum;
}

export interface VoucherTypeSupplier {
  VoucherType: VoucherTypeEnum;
}

export interface VoucherTypeUnknown {
  VoucherType: VoucherTypeEnum;
}

export interface ExperienceDetails {
  duration?: number;
  name: string;
  additionalInformation: Array<string>;
  inclusionsAndExclusions?: ExperienceInclusions;
  supplierInfo?: ExperienceSupplierInfo;
  coverImage?: string;
  id: string;
  itinerary?: ExperienceItinerary;
  logistics?: ExperienceLogistics;
  rating?: ReviewSummary;
  overview?: string;
  destination?: string;
}

export interface ExperienceSelection {
  providerExperienceId: ExperienceId;
  providerExperienceStartTime?: string;
  ageBandSelection: Array<AgeBandSelection>;
  providerExperienceOptionId: ExperienceOptionId;
  travelDate: string;
  languageGuide?: ExperienceLanguageGuide;
}

export const ExperienceBookingStatusEnum = {
  Cancelled: "Cancelled",
  Confirmed: "Confirmed",
  Pending: "Pending",
  Unknown: "Unknown",
} as const;

export type ExperienceBookingStatusEnum =
  (typeof ExperienceBookingStatusEnum)[keyof typeof ExperienceBookingStatusEnum];

export interface BookingSession {
  totalSellAtPriceWithFees?: Prices;
  cartOrderId: string;
  bookingFee?: Prices;
  totalRemitPrice?: Prices;
  cartOrderedProductId: string;
  priceBreakdown: Array<PriceBreakdownLineItem>;
}
