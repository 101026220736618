import { FlightItinerarySlice } from "./itinerary";

export enum ScheduleChangeFlow {
  MoveToQueue = "MoveToQueue",
  ReferToAirline = "ReferToAirline",
}

export interface ScheduleChange {
  id: string;
  next: FlightItinerarySlice[];
  severity: ScheduleChangeSeverity;
  created: string;
  expiry: string;
  userDenyFlow: {
    UserDenyFlow: ScheduleChangeFlow;
  };
}

export enum ScheduleChangeUserActionEnum {
  Accept = "Accept",
  Deny = "Deny",
}

export enum ScheduleChangeSeverity {
  Major = "Major",
  Minor = "Minor",
  Unknown = "Unknown",
}

export interface AcceptScheduleChangeRequest {
  itineraryId: string;
}
export interface AcceptScheduleChangeResponse {
  success: boolean;
  itinerary: string;
  errorCodes?: string[];
}

export interface DenyScheduleChangeRequest {
  itineraryId: string;
  outboundPreferences: ScheduleChangeChoice[];
  returnPreferences: ScheduleChangeChoice[];
  additionalRemarks?: string;
}
export interface DenyScheduleChangeResponse {
  success: boolean;
  itinerary: string;
  errorCodes?: string[];
}

export interface ScheduleChangeChoice {
  departAfter: string;
  departBefore: string;
}

export enum TimeGroupingEnum {
  EARLY_MORNING,
  MORNING,
  AFTERNOON,
  EVENING,
}

export interface ITimeGrouping {
  grouping: TimeGroupingEnum;
  label: string;
  sublabel: string;
}
