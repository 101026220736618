import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { TruncatedText } from "../../shop/components/TruncatedText/component";
import {
  PaymentPolicy,
  Policy,
  PolicyEnum,
  TimeRange,
} from "redmond/apis/tysons/vacation-rentals";
import {
  GENERAL_CHECK_IN_INSTRUCTIONS_TEXT,
  PAYMENT_POLICY_CARD_ON_FILE,
  PAYMENT_POLICY_FULL_PAYMENT_DUE_NOW,
  PAYMENT_POLICY_TITLE,
} from "./constants";
import dayjs from "dayjs";
import { useDeviceTypes } from "halifax";

export interface VacationRentalTermsProps {
  checkinInformation?: string;
  checkInTimes?: TimeRange;
  checkOutTimes?: TimeRange;
  petFriendly?: boolean;
  petsPolicy?: string;
  cancellationPolicy?: Policy;
  paymentPolicy?: PaymentPolicy;
}

export const VacationRentalTerms = ({
  checkinInformation,
  checkInTimes,
  checkOutTimes,
  petFriendly = false,
  petsPolicy,
  cancellationPolicy,
  paymentPolicy,
}: VacationRentalTermsProps) => {
  const { matchesMobile } = useDeviceTypes();

  const cxlPolicyPrimaryText = cancellationPolicy?.primaryText;
  let cxlPolicySecondaryText: string | undefined;

  if (cancellationPolicy) {
    cxlPolicySecondaryText =
      cancellationPolicy.Policy !== PolicyEnum.Unknown
        ? cancellationPolicy?.secondaryText
        : undefined;
  }

  const formatTimeRange = (startTime?: string, endTime?: string) => {
    let formatted = "";
    if (startTime && endTime) {
      formatted = `From ${startTime} - ${endTime}`;
    } else if (startTime) {
      formatted = `From ${startTime}`;
    } else if (endTime) {
      formatted = `Until ${endTime}`;
    }
    return formatted;
  };

  const initialText = paymentPolicy?.schedule
    ? paymentPolicy?.schedule.PaymentSchedule === "FullPaymentDueNow"
      ? PAYMENT_POLICY_FULL_PAYMENT_DUE_NOW
      : paymentPolicy?.schedule.PaymentSchedule === "PaymentText"
      ? paymentPolicy.schedule.text
      : PAYMENT_POLICY_FULL_PAYMENT_DUE_NOW
    : "";

  const clausesText = (paymentPolicy?.clauses || [])
    .map((clause) => {
      switch (clause.PaymentClause) {
        case "Text":
          return clause.description || "";
        case "CardOnFile":
          return clause.description || PAYMENT_POLICY_CARD_ON_FILE;
        default:
          return "";
      }
    })
    .filter((text) => text.length > 0);

  const paymentPolicyText = [initialText, ...clausesText]
    .filter(Boolean)
    .join("\n");

  const getStandardTime = (time?: string) => {
    return time ? dayjs(time, "HH:mm").format("h:mm A") : undefined;
  };

  return (
    <Box
      className="section"
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: "10px", // Handle the optional sections
      }}
    >
      <Box paddingX={matchesMobile ? "20px" : "30px"}>
        <Typography className="title">House rules</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          gap: "12px",
        }}
        paddingX={matchesMobile ? "20px" : "30px"}
      >
        {!!checkinInformation && (
          <Box display="flex" flexDirection="column">
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Check-in Instructions
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#676868",
              }}
            >
              {GENERAL_CHECK_IN_INSTRUCTIONS_TEXT}
            </Typography>
          </Box>
        )}

        {!!checkInTimes?.startTime && (
          <Box display="flex" flexDirection="column">
            <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
              Check-in time
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#676868",
              }}
            >
              {formatTimeRange(
                getStandardTime(checkInTimes.startTime),
                !!checkInTimes?.endTime
                  ? getStandardTime(checkInTimes.endTime)
                  : undefined
              )}
            </Typography>
          </Box>
        )}
        {!!(checkOutTimes?.startTime || checkOutTimes?.endTime) && (
          <Box display="flex" flexDirection="column">
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Checkout time
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                paddingBottom: "20px",
                color: "#676868",
              }}
            >
              {formatTimeRange(
                getStandardTime(checkOutTimes?.startTime),
                !!checkOutTimes?.endTime
                  ? getStandardTime(checkOutTimes?.endTime)
                  : undefined
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        style={{
          gap: "12px",
        }}
        paddingY="20px"
        paddingX={matchesMobile ? "20px" : "30px"}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Pets policy
        </Typography>
        {petFriendly ? (
          petsPolicy ? (
            <TruncatedText text={petsPolicy} limit={200} setAsHTML={true} />
          ) : (
            <Typography>Pets are allowed</Typography>
          )
        ) : (
          <Typography>Pets are not allowed</Typography>
        )}
      </Box>

      {cxlPolicyPrimaryText && (
        <>
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            style={{
              gap: "12px",
            }}
            paddingY="20px"
            paddingX={matchesMobile ? "20px" : "30px"}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {cxlPolicyPrimaryText}
            </Typography>
            {cxlPolicySecondaryText && (
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#676868",
                }}
              >
                {cxlPolicySecondaryText}
              </Typography>
            )}
          </Box>
        </>
      )}

      {paymentPolicyText && (
        <>
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: "12px" }}
            paddingY="20px"
            paddingX={matchesMobile ? "20px" : "30px"}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {PAYMENT_POLICY_TITLE}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#676868",
              }}
            >
              {paymentPolicyText}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
