import { useMediaQuery } from "@material-ui/core";

export const useDeviceTypes = () => {
  const matchesMobile = useMediaQuery("(max-width: 960px)");
  const matchesDesktop = useMediaQuery("(min-width: 960px)");

  const matchesSmallScreen = useMediaQuery("(min-width: 576px) and (max-width: 768px)");
  const matchesMediumScreen = useMediaQuery("(min-width: 768px) and (max-width: 960px)");
  const matchesLargeScreen = useMediaQuery("(min-width: 960px) and (max-width: 1200px)");
  const matchesXLargeScreen = useMediaQuery("(min-width: 1200px) and (max-width: 1400px)");
  const matchesXXLargeScreen = useMediaQuery("(min-width: 1400px)");

  return { matchesMobile, matchesDesktop, matchesSmallScreen, matchesMediumScreen, matchesLargeScreen, matchesXLargeScreen, matchesXXLargeScreen  };
};
