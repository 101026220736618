import { DateTime } from "../..";
import { RewardsAccount } from "./rewards";

export type UserPermissions = {
  canBookTravel: boolean;
  canViewTravel: boolean;
  canApproveBooking: boolean;
  canViewUsers: boolean;
  canEditUsers: boolean;
  canViewAllTravel: boolean;
  canViewPolicy: boolean;
  canEditPolicy: boolean;
  canViewReport: boolean;
  canEditBusiness: boolean;
  canViewAdmin: boolean;
  canEditBusinessProfile: boolean;
};

export interface CorpBusinessAccountInfo {
  businessId: string;
  businessName: string;
  isLive: boolean;
  permissions: { [key in keyof UserPermissions]: boolean };
  createdAt: DateTime;
}

export interface CorpBusinessAccount {
  rewardsAccount: RewardsAccount;
  optedIn?: CorpBusinessAccountInfo;
  declineStatus: boolean;
  notNowStatus: boolean;
  productDisplayName: string;
}

export interface CorpBusinessWithoutRewardsAccount {
  accountReferenceId: string;
  productDisplayName: string;
  optedIn: CorpBusinessAccountInfo;
}

export type CorpBusinessAccountResponse = CorpBusinessAccount[];

export interface CorpOptInResponse {
  businessId: string;
}

export enum BusinessModalNames {
  WHOS_BOOKING = "whos_booking",
  ONBOARDING_CHECKLIST = "onboarding_checklist",
}

export enum BookingOptionsKeys {
  MYSELF_AND_OTHERS = "book_for_myself_and_others",
  EMPLOYEES_THEMSELVES = "employees_book_for_themselves",
  SOMEONE_ELSE = "someone_else_books_for_everyone",
  MYSELF = "book_for_myself",
}

export type WhosBookingModalData = {
  answer: BookingOptionsKeys;
};

type GenericModalData = Record<string, string> | Record<string, never>;

export type ModalDataMap = {
  [BusinessModalNames.WHOS_BOOKING]: WhosBookingModalData;
  [BusinessModalNames.ONBOARDING_CHECKLIST]: GenericModalData;
};

export type BusinessModalName = `${BusinessModalNames}`;
