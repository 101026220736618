import {
  VehicleKindEnum,
  TransmissionTypeEnum,
  FuelPolicy,
} from "../cars-module";
import {
  Person,
  State,
  LocationBookDateTime,
  Vendor,
  AdditionalInfo,
  Supplier,
  ExtraInfo,
  RestrictionCancel,
  ContactSupport,
  VendorMessages,
  CancellationInfo,
  TripSummary,
  InsuranceConfirmation,
  ConfirmationInfo,
  RentalRate,
  DistKind,
  NonRefundableInfo,
} from "../apis/tysons/payment-machine";
import { IPaymentAmountInfo, PaymentBreakdown } from "./itinerary";
import { PortalType, TrackingProperties } from "..";

export interface BookingResult {
  groundBookingId: string;
  status: { State: State };
  bookingId?: string;
  bookingDate: string;
  pickUp: LocationBookDateTime;
  dropOff: LocationBookDateTime;
  rentalRate: RentalRate;
  vendor: Vendor;
  vehicle: Vehicle;
  additionalInfo?: AdditionalInfo;
  supplier: Supplier;
  extraInfo: ExtraInfo[];
  driver: Person;
  showCSAT?: boolean;
  contactSupport?: string;
  cancellationRules?: RestrictionCancel;
  contactSupportInfo?: ContactSupport;
  vendorMessages?: VendorMessages;
  cancellationInfo?: CancellationInfo;
  tripSummary?: TripSummary;
  insuranceConfirmation?: InsuranceConfirmation;
  confirmationInfo?: ConfirmationInfo;
  trackingPropertiesV2: TrackingProperties;
}

export interface Extensions {
  distance: DistKind;
  cancellation: CancellationPolicy;
  collisionDamageWaiver?: boolean;
  supportExtraInfo: boolean;
  fuelPolicy?: FuelPolicy;
  specialOffers: string[];
  nonRefundable?: NonRefundableInfo;
  disclosureRequired?: boolean;
}

export interface Vehicle {
  id: string;
  name: string;
  styledName?: string;
  groupCode: string;
  kind: VehicleKindEnum;
  bagsInfo: { [key: string]: number };
  transmissionType: TransmissionTypeEnum;
  driveType: DriveTypeEnum;
  airConditioning: boolean;
  airbag: boolean;
  doorsQty: number;
  passengerQty: number;
  fuelType: string;
}

export enum DriveTypeEnum {
  FourWheelDrive = "FourWheelDrive",
  TwoWheelDrive = "TwoWheelDrive",
  Unavailable = "Unavailable",
}

export interface CancellationPolicy {
  free: boolean;
  desc: string;
}

export interface CarItineraryRequest {
  states: CarReservationState[];
  asOf: string;
}

export enum CarReservationState {
  Future = "Future",
  Present = "Present",
  Past = "Past",
  Canceled = "Canceled",
  Unused = "Unused",
}
export type CarItineraryResponse =
  | CarItineraryResponseFailure
  | CarItineraryResponseSuccess;

export enum CarItineraryResponseEnum {
  Failure = "Failure",
  Success = "Success",
}

// key is future, past, present, canceled
export interface CarItineraryResponseSuccess {
  reservations: { [key: string]: CarReservation[] };
  CarItineraryResponse: CarItineraryResponseEnum;
}

export interface CarReservation {
  bookResult: BookingResult;
  samePickUpDropOff: boolean;
  paymentAmountInfo: IPaymentAmountInfo;
  paymentBreakdown: PaymentBreakdown;
  portalType?: PortalType;
}

export interface CarItineraryResponseFailure {
  CarItineraryResponse: CarItineraryResponseEnum;
}

export type Reason =
  | BookExtBadRequest
  | BookExtPriceChanged
  | BookExtServiceUnavailable
  | BookExtTimeout
  | BookExtUnavailability
  | BookProviderUnavailable
  | CancelLongPollRequest
  | CancelRetrieveLongPoll
  | CancelUserRequest
  | CancellationAttemptFailed
  | CancellationAttemptOk
  | CancellationFeesApplicable;

export enum ReasonEnum {
  CancellationAttemptFailed = "CancellationAttemptFailed",
  CancelUserRequest = "CancelUserRequest",
  CancelRetrieveLongPoll = "CancelRetrieveLongPoll",
  CancelLongPollRequest = "CancelLongPollRequest",
  CancellationAttemptOk = "CancellationAttemptOk",
  BookExtServiceUnavailable = "BookExtServiceUnavailable",
  BookExtTimeout = "BookExtTimeout",
  BookExtBadRequest = "BookExtBadRequest",
  BookExtPriceChanged = "BookExtPriceChanged",
  BookExtUnavailability = "BookExtUnavailability",
  CancellationFeesApplicable = "CancellationFeesApplicable",
  BookProviderUnavailable = "BookProviderUnavailable",
}

export interface BookExtBadRequest {
  Reason: ReasonEnum;
}

export interface BookExtPriceChanged {
  Reason: ReasonEnum;
}

export interface BookExtServiceUnavailable {
  Reason: ReasonEnum;
}

export interface BookExtTimeout {
  Reason: ReasonEnum;
}

export interface BookExtUnavailability {
  Reason: ReasonEnum;
}

export interface BookProviderUnavailable {
  Reason: ReasonEnum;
}

export interface CancelLongPollRequest {
  Reason: ReasonEnum;
}

export interface CancelRetrieveLongPoll {
  Reason: ReasonEnum;
}

export interface CancelUserRequest {
  Reason: ReasonEnum;
}

export interface CancellationAttemptFailed {
  Reason: ReasonEnum;
}

export interface CancellationAttemptOk {
  Reason: ReasonEnum;
}

export interface CancellationFeesApplicable {
  Reason: ReasonEnum;
}
