import { Prices, Amount, Percentage } from "../../common";
import {
  BedTypeEnum,
  Lodging,
  RoomInfo,
  RoomProduct,
} from "../../hotels-module";

export interface GetHotelDetailResultErr {
  err: FetchDetailError;
  Result: ResultEnum.Err;
}

export type FetchDetailError =
  | GetExchangeRatesFailure
  | GetRewardsAccountsFailure
  | GetPriceFreezeVouchersFailure
  | GetPriceFreezeVoucherFailure
  | PriceFreezeVoucherNotFound;

export enum FetchDetailErrorEnum {
  GetExchangeRatesFailure = "GetExchangeRatesFailure",
  GetRewardsAccountsFailure = "GetRewardsAccountsFailure",
  GetPriceFreezeVouchersFailure = "GetPriceFreezeVouchersFailure",
  GetPriceFreezeVoucherFailure = "GetPriceFreezeVoucherFailure",
  PriceFreezeVoucherNotFound = "PriceFreezeVoucherNotFound",
  GotHoldTheRoomPriceFreezeVoucher = "GotHoldTheRoomPriceFreezeVoucher",
  GotBestAvailablePriceFreezeVoucher = "GotBestAvailablePriceFreezeVoucher",
}

export interface GetExchangeRatesFailure {
  message: string;
  FetchDetailError: FetchDetailErrorEnum.GetExchangeRatesFailure;
}

export interface GetRewardsAccountsFailure {
  message: string;
  FetchDetailError: FetchDetailErrorEnum.GetRewardsAccountsFailure;
}

export interface GetPriceFreezeVouchersFailure {
  message: string;
  FetchDetailError: FetchDetailErrorEnum.GetPriceFreezeVouchersFailure;
}

export interface GetPriceFreezeVoucherFailure {
  cause: ListSummariesError;
  FetchDetailError: FetchDetailErrorEnum.GetPriceFreezeVoucherFailure;
}

export interface PriceFreezeVoucherNotFound {
  request: GetHotelDetailRequest;
  FetchDetailError: FetchDetailErrorEnum.PriceFreezeVoucherNotFound;
}

export interface GotHoldTheRoomPriceFreezeVoucher {
  voucherId: string;
  FetchDetailError: FetchDetailErrorEnum.GotHoldTheRoomPriceFreezeVoucher;
}

export interface GotBestAvailablePriceFreezeVoucher {
  voucherId: string;
  FetchDetailError: FetchDetailErrorEnum.GotBestAvailablePriceFreezeVoucher;
}

export interface GetHotelDetailResultOk {
  value: GetHotelDetailResponse;
  Result: ResultEnum.Ok;
}

export type GetHotelDetailResult =
  | GetHotelDetailResultErr
  | GetHotelDetailResultOk;

export interface ByVoucherId {
  id: string;
  GetDetailRequest: GetHotelDetailRequestEnum.ByVoucherId;
}

export type GetHotelDetailRequest = ByVoucherId;

export enum GetHotelDetailRequestEnum {
  ByVoucherId = "ByVoucherId",
}

export interface GetHotelDetailResponse {
  detail: HotelPriceFreezeDetail;
}

export interface HotelPriceFreezeDetail {
  voucher: Voucher;
  lodging: HotelPriceFreezeDetailLodgingResult;
  roomWithPricing: RoomWithPricingResult;
}

export interface HotelPriceFreezeSummary {
  voucher: Voucher;
  lodging: HotelPriceFreezeDetailLodgingResult;
  confirmationNumber?: string;
}

export type HotelPriceFreezeDetailLodgingResult =
  | HotelPriceFreezeDetailLodgingResultErr
  | HotelPriceFreezeDetailLodgingResultOk;

export type RoomWithPricingResult =
  | RoomWithPricingResultErr
  | RoomWithPricingResultOk;

export interface RoomWithPricingResultErr {
  err: GetMatchingRoomError;
  Result: ResultEnum.Err;
}

export interface RoomWithPricingResultOk {
  value: RoomWithPricing;
  Result: ResultEnum.Ok;
}

export interface RoomProducts {
  standard: RoomProduct;
  exercised: RoomProduct;
}

export interface RoomWithPricing {
  roomInfo: RoomInfo;
  roomProducts: RoomProducts;
  pricings: CurrentHotelPricingList;
}

export interface CurrentHotelPricing {
  current: Prices;
  increase: Prices;
  versusCap: CurrentVersusCap;
  savings: Prices;
  selling: Prices;
  deposit: Prices;
  baseRateWithSavings: Prices;
}

export enum GetLodgingErrorEnum {
  GetLodgingDetailsFailure = "GetLodgingDetailsFailure",
  LodgingNotFound = "LodgingNotFound",
  ManyLodgingsFound = "ManyLodgingsFound",
}

export interface GetLodgingDetailsFailure {
  message: string;
  GetLodgingError: GetLodgingErrorEnum.GetLodgingDetailsFailure;
}

export interface LodgingNotFound {
  voucherId: string;
  lodgingId: string;
  GetLodgingError: GetLodgingErrorEnum.LodgingNotFound;
}

export interface ManyLodgingsFound {
  voucherId: string;
  lodgingId: string;
  GetLodgingError: GetLodgingErrorEnum.ManyLodgingsFound;
}

export type GetLodgingError =
  | GetLodgingDetailsFailure
  | LodgingNotFound
  | ManyLodgingsFound;

export interface HotelPriceFreezeDetailLodgingResultErr {
  err: GetLodgingError;
  Result: ResultEnum.Err;
}

export interface HotelPriceFreezeDetailLodgingResultOk {
  value: Lodging;
  Result: ResultEnum.Ok;
}

export enum GetMatchingRoomErrorEnum {
  NoMatchingRoomForVoucher = "NoMatchingRoomForVoucher",
  NoLodgingDetailAvailable = "NoLodgingDetailAvailable",
  HotelShopFailure = "HotelShopFailure",
  HotelShoppingError = "HotelShoppingError",
  NoBookingInformationAvailable = "NoBookingInformationAvailable",
  RoomTypeMatchFailure = "RoomTypeMatchFailure",
  ShoppedHotelUnavailable = "ShoppedHotelUnavailable",
  LodgingNotAvailable = "LodgingNotAvailable",
}

export interface HotelShopFailure {
  message: string;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.HotelShopFailure;
}

export interface HotelShoppingError {
  message: string;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.HotelShoppingError;
}

interface LodgingId {
  value: string;
  tags: string[];
}

export interface NoBookingInformationAvailable {
  voucherId: string;
  lodgingId: LodgingId;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.NoBookingInformationAvailable;
}

export interface NoLodgingDetailAvailable {
  voucherId: string;
  lodgingId: LodgingId;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.NoLodgingDetailAvailable;
}

export interface NoMatchingRoomForVoucher {
  voucherId: string;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.NoMatchingRoomForVoucher;
}

export interface LodgingNotAvailable {
  voucherId: string;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.LodgingNotAvailable;
}

export interface RoomTypeMatchFailure {
  message: string;
  GetMatchingRoomError: GetMatchingRoomErrorEnum.RoomTypeMatchFailure;
}

export interface ShoppedHotelUnavailable {
  GetMatchingRoomError: GetMatchingRoomErrorEnum.ShoppedHotelUnavailable;
}

export type GetMatchingRoomError =
  | HotelShopFailure
  | HotelShoppingError
  | NoBookingInformationAvailable
  | NoLodgingDetailAvailable
  | NoMatchingRoomForVoucher
  | RoomTypeMatchFailure
  | ShoppedHotelUnavailable
  | LodgingNotAvailable;

export interface Action {
  purchase: string;
  expiration: string;
}

export interface Pricing {
  deposit: Prices;
  frozen: Prices;
  current: Prices;
  cap: Prices;
  increase: Prices;
  versusCap: CurrentVersusCap;
  savings: Prices;
  selling: Prices;
}

export interface BestAvailable {
  start: string;
  end: string;
  duration: string;
  Reservation: ReservationEnum.BestAvailable;
}

export interface BedConfiguration {
  bedType: BedTypeEnum;
  quantity: number;
}

export interface HotelPriceFreezeOfferData {
  id: string;
  currency: string;
  frozenPrice: number;
  deposit: number;
  cap: number;
  durationDays: number;
}

export interface HotelPriceFreezeRoomData {
  providerName: string;
  roomId?: string;
  roomDescription?: string;
  roomName?: string;
  bedConfiguration: BedConfiguration[];
  isRefundable?: boolean;
}

export interface RoomName {
  toText: string;
}

export interface HoldTheRoom {
  start: string;
  end: string;
  duration: string;
  roomName?: RoomName;
  bedConfigurations: BedConfiguration[];
  Reservation: ReservationEnum.HoldTheRoom;
}

export interface RoomType {
  start: string;
  end: string;
  duration: string;
  roomName?: RoomName;
  bedConfigurations: BedConfiguration[];
  Reservation: ReservationEnum.RoomType;
}

export type CurrentVersusCap = OverCap | UnderCap;

export enum CurrentVersusCapEnum {
  UnderCap = "UnderCap",
  OverCap = "OverCap",
}

export interface ListSummariesErr {
  err: ListSummariesError;
  Result: ResultEnum.Err;
}

export interface GetExchangeRatesFailure {
  message: string;
  ListSummariesError: ListSummariesErrorEnum.GetExchangeRatesFailure;
}

export interface GetRewardsAccountsFailure {
  message: string;
  ListSummariesError: ListSummariesErrorEnum.GetRewardsAccountsFailure;
}

export interface GetPriceFreezeVouchersFailure {
  message: string;
  ListSummariesError: ListSummariesErrorEnum.GetPriceFreezeVouchersFailure;
}

export interface ForAuthenticatedUser {
  ListSummariesRequest: ListSummariesRequestEnum.ForAuthenticatedUser;
}

export type ListSummariesError =
  | GetExchangeRatesFailure
  | GetRewardsAccountsFailure
  | GetPriceFreezeVouchersFailure;

export enum ListSummariesErrorEnum {
  GetExchangeRatesFailure = "GetExchangeRatesFailure",
  GetRewardsAccountsFailure = "GetRewardsAccountsFailure",
  GetPriceFreezeVouchersFailure = "GetPriceFreezeVouchersFailure",
}

export type ListSummariesRequest = ForAuthenticatedUser;

export enum ListSummariesRequestEnum {
  ForAuthenticatedUser = "ForAuthenticatedUser",
}

export interface ListSummariesResponse {
  summaries: HotelPriceFreezeSummary[];
}

export interface ListSummariesOk {
  value: ListSummariesResponse;
  Result: ResultEnum.Ok;
}

export interface OverCap {
  CurrentVersusCap: CurrentVersusCapEnum.OverCap;
}

export type ListSummariesResult = ListSummariesErr | ListSummariesOk;

export enum ResultEnum {
  Err = "Err",
  Ok = "Ok",
}

export interface UnderCap {
  CurrentVersusCap: CurrentVersusCapEnum.UnderCap;
}

export enum ReservationEnum {
  BestAvailable = "BestAvailable",
  RoomType = "RoomType",
  HoldTheRoom = "HoldTheRoom",
}

export type HotelPFReservation = BestAvailable | HoldTheRoom | RoomType;

interface VoucherExchanged {
  voucherId: string;
  exchangeTime: string;
  State: HotelPriceFreezeStateEnum.Exchanged;
}

interface VoucherExercised {
  reservationId: string;
  exerciseTime: string;
  State: HotelPriceFreezeStateEnum.Exercised;
}

interface VoucherExpired {
  reservationId?: string;
  State: HotelPriceFreezeStateEnum.Expired;
}

interface VoucherRefunded {
  voucherId: string;
  refundTime: string;
  State: HotelPriceFreezeStateEnum.Refunded;
}

interface VoucherValid {
  State: HotelPriceFreezeStateEnum.Valid;
}

export type HotelPriceFreezeState =
  | VoucherExchanged
  | VoucherExercised
  | VoucherExpired
  | VoucherRefunded
  | VoucherValid;

export enum HotelPriceFreezeStateEnum {
  Expired = "Expired",
  Exchanged = "Exchanged",
  Refunded = "Refunded",
  Exercised = "Exercised",
  Valid = "Valid",
}

export interface Voucher {
  id: string;
  action: Action;
  reservation: HotelPFReservation;
  pricings: FrozenHotelPricingList;
  state: HotelPriceFreezeState;
  deposit: Prices;
}

export interface FrozenHotelPricingList {
  nightly: FrozenHotelPricing;
  total: FrozenHotelPricing;
}

export interface FrozenHotelPricing {
  frozen: Prices;
  cap: Prices;
}

export interface CurrentHotelPricingList {
  nightly: CurrentHotelPricing;
  total: CurrentHotelPricing;
  sellingOfCurrent: Percentage;
}

export interface HotelDetails {
  checkIn: string;
  checkOut: string;
  lodgingId: string;
}

export interface NoOffer {
  offer: LodgingPriceFreezeOffer;
  LodgingPriceFreezeOfferResult: LodgingPriceFreezeOfferResultEnum.NoOffer;
}

export interface HasOffer {
  offer: LodgingPriceFreezeOffer;
  LodgingPriceFreezeOfferResult: LodgingPriceFreezeOfferResultEnum.HasOffer;
}

export type LodgingPriceFreezeOfferResult = HasOffer | NoOffer;

export enum LodgingPriceFreezeOfferResultEnum {
  HasOffer = "HasOffer",
  NoOffer = "NoOffer",
}

export interface AccountPrice {
  currency: string;
  value: number;
  currencyDescription?: string;
}

export interface PriceFreezePricing {
  depositPerNight: Prices;
  frozenPricePerNight: Prices;
  capPerNight: Prices;
  deposit: Prices;
  cap: Prices;
  frozenPrice: Prices;
}

export interface LodgingPriceFreezeOffer {
  id: string;
  pricing: PriceFreezePricing;
  hotelDetails: HotelDetails;
  durationHours: number;
}

export interface LodgingPriceFreezeOfferRate {
  basePrice: Amount;
  checkIn: string;
  checkOut: string;
  lodgingId: string;
}

export type LodgingPriceFreezeBatchOfferResponseOffersInner =
  LodgingPriceFreezeOffer;

export interface LodgingPriceFreezeBatchOfferRequest {
  rates: Array<LodgingPriceFreezeOfferRate>;
}

export interface LodgingPriceFreezeBatchOfferResponse {
  offers: Array<LodgingPriceFreezeOfferResult>;
}

export interface LodgingPriceFreezeRefundRequest {
  id: string;
}

export type LodgingPriceFreezeRefundError =
  | MultipleTransactionsFound
  | ProductReversalFailure
  | SearchTransactionsFailure;

export enum LodgingPriceFreezeRefundErrorEnum {
  SearchTransactionsFailure = "SearchTransactionsFailure",
  MultipleTransactionsFound = "MultipleTransactionsFound",
  ProductReversalFailure = "ProductReversalFailure",
}

interface MultipleTransactionsFound {
  voucherId: string;
  RefundError: LodgingPriceFreezeRefundErrorEnum.MultipleTransactionsFound;
}

interface ProductReversalFailure {
  message: string;
  RefundError: LodgingPriceFreezeRefundErrorEnum.ProductReversalFailure;
}

interface SearchTransactionsFailure {
  message: string;
  RefundError: LodgingPriceFreezeRefundErrorEnum.SearchTransactionsFailure;
}

export type LodgingPriceFreezeRefundResponse =
  | RefundSuccess
  | TransactionNotFound;

export enum LodgingPriceFreezeRefundResponseEnum {
  TransactionNotFound = "TransactionNotFound",
  Success = "Success",
}

interface RefundSuccess {
  RefundResponse: LodgingPriceFreezeRefundResponseEnum.Success;
}

interface TransactionNotFound {
  msg: string;
  RefundResponse: LodgingPriceFreezeRefundResponseEnum.TransactionNotFound;
}

interface LodgingPriceFreezeRefundResultErr {
  err: LodgingPriceFreezeRefundError;
  Result: ResultEnum.Err;
}

interface LodgingPriceFreezeRefundResultOk {
  value: LodgingPriceFreezeRefundResponse;
  Result: ResultEnum.Ok;
}

export type LodgingPriceFreezeRefundResult =
  | LodgingPriceFreezeRefundResultErr
  | LodgingPriceFreezeRefundResultOk;

export interface LodgingPriceFreezeGetCreditsRequest {
  voucherId: string;
  token: {
    value: string;
  };
}

export enum GetCreditsErrorEnum {
  GetRewardsAccountsFailure = "GetRewardsAccountsFailure",
  PriceFreezeVoucherNotFound = "PriceFreezeVoucherNotFound",
  NoHotelProductQuoted = "NoHotelProductQuoted",
  QuotePending = "QuotePending",
  GetQuoteFailure = "GetQuoteFailure",
  GetExchangeRatesFailure = "GetExchangeRatesFailure",
  ManyHotelProductQuoted = "ManyHotelProductQuoted",
  CalculateDiscountFailure = "CalculateDiscountFailure",
  InvalidHotelQuote = "InvalidHotelQuote",
  QuoteUnavailable = "QuoteUnavailable",
  GetPriceFreezeVoucherFailure = "GetPriceFreezeVoucherFailure",
  MismatchedQuoteForVoucher = "MismatchedQuoteForVoucher",
}

interface GetCreditsCalculateDiscountFailure {
  message: string;
  GetCreditsError: GetCreditsErrorEnum.CalculateDiscountFailure;
}

interface GetCreditsGetExchangeRatesFailure {
  message: string;
  GetCreditsError: GetCreditsErrorEnum.GetExchangeRatesFailure;
}

interface GetCreditsGetPriceFreezeVoucherFailure {
  message: string;
  GetCreditsError: GetCreditsErrorEnum.GetPriceFreezeVoucherFailure;
}

interface GetCreditsGetQuoteFailure {
  message: string;
  GetCreditsError: GetCreditsErrorEnum.GetQuoteFailure;
}

interface GetCreditsGetRewardsAccountsFailure {
  message: string;
  GetCreditsError: GetCreditsErrorEnum.GetRewardsAccountsFailure;
}

interface GetCreditsInvalidHotelQuote {
  message: string;
  GetCreditsError: GetCreditsErrorEnum.InvalidHotelQuote;
}

interface GetCreditsManyHotelProductQuoted {
  GetCreditsError: GetCreditsErrorEnum.ManyHotelProductQuoted;
}

interface GetCreditsMismatchedQuoteForVoucher {
  request: LodgingPriceFreezeGetCreditsRequest;
  GetCreditsError: GetCreditsErrorEnum.MismatchedQuoteForVoucher;
}

interface GetCreditsNoHotelProductQuoted {
  GetCreditsError: GetCreditsErrorEnum.NoHotelProductQuoted;
}

interface GetCreditsPriceFreezeVoucherNotFound {
  request: LodgingPriceFreezeGetCreditsRequest;
  GetCreditsError: GetCreditsErrorEnum.PriceFreezeVoucherNotFound;
}

interface GetCreditsQuotePending {
  request: LodgingPriceFreezeGetCreditsRequest;
  GetCreditsError: GetCreditsErrorEnum.QuotePending;
}

interface GetCreditsQuoteUnavailable {
  request: LodgingPriceFreezeGetCreditsRequest;
  reasons: string[];
  GetCreditsError: GetCreditsErrorEnum.QuoteUnavailable;
}

export type LodgingPriceFreezeGetCreditsError =
  | GetCreditsCalculateDiscountFailure
  | GetCreditsGetExchangeRatesFailure
  | GetCreditsGetPriceFreezeVoucherFailure
  | GetCreditsGetQuoteFailure
  | GetCreditsGetRewardsAccountsFailure
  | GetCreditsInvalidHotelQuote
  | GetCreditsManyHotelProductQuoted
  | GetCreditsMismatchedQuoteForVoucher
  | GetCreditsNoHotelProductQuoted
  | GetCreditsPriceFreezeVoucherNotFound
  | GetCreditsQuotePending
  | GetCreditsQuoteUnavailable;

interface LodgingPriceFreezeGetCreditsResultErr {
  err: LodgingPriceFreezeGetCreditsError;
  Result: ResultEnum.Err;
}

interface AirChfarExerciseDiscount {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.AirChfarExerciseDiscount;
}

interface AirPriceFreezeExerciseDiscount {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.AirPriceFreezeExerciseDiscount;
}

interface Card {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.Card;
}

interface DisruptionProtectionCredit {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.DisruptionProtectionCredit;
}

interface FiatAccount {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.FiatAccount;
}

interface HotelPriceFreezeDeposit {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.HotelPriceFreezeDeposit;
}

interface HotelPriceFreezeExerciseDiscount {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.HotelPriceFreezeExerciseDiscount;
}

interface NequiPocketPayment {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.NequiPocketPayment;
}

interface Rewards {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.Rewards;
}

interface TravelWalletCredit {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.TravelWalletCredit;
}

interface TravelWalletOffer {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.TravelWalletOffer;
}

interface UberPayment {
  Payment: LodgingPriceFreezeGetCreditsPaymentEnum.UberPayment;
}

export type LodgingPriceFreezeGetCreditsPayment =
  | AirChfarExerciseDiscount
  | AirPriceFreezeExerciseDiscount
  | Card
  | DisruptionProtectionCredit
  | FiatAccount
  | HotelPriceFreezeDeposit
  | HotelPriceFreezeExerciseDiscount
  | NequiPocketPayment
  | Rewards
  | TravelWalletCredit
  | TravelWalletOffer
  | UberPayment;

export enum LodgingPriceFreezeGetCreditsPaymentEnum {
  Card = "Card",
  Rewards = "Rewards",
  DisruptionProtectionCredit = "DisruptionProtectionCredit",
  FiatAccount = "FiatAccount",
  AirChfarExerciseDiscount = "AirChfarExerciseDiscount",
  AirPriceFreezeExerciseDiscount = "AirPriceFreezeExerciseDiscount",
  UberPayment = "UberPayment",
  TravelWalletCredit = "TravelWalletCredit",
  HotelPriceFreezeDeposit = "HotelPriceFreezeDeposit",
  TravelWalletOffer = "TravelWalletOffer",
  NequiPocketPayment = "NequiPocketPayment",
  HotelPriceFreezeExerciseDiscount = "HotelPriceFreezeExerciseDiscount",
}

export interface LodgingPriceFreezeGetCreditsOpaqueValue {
  type: LodgingPriceFreezeGetCreditsPayment;
  value: any;
}

export interface LodgingPriceFreezeGetCreditsPayments {
  deposit: LodgingPriceFreezeGetCreditsOpaqueValue;
  discount: LodgingPriceFreezeGetCreditsOpaqueValue;
}

/*
 * @param subtotalCharge                   Quoted amount owed by the customer before [[taxesCharge]].
 * @param taxesCharge                      Quoted taxes.
 * @param payNowCharge                     Quoted amount owed by the customer, [[subtotalCharge]] plus [[taxesCharge]], before [[depositCredit]] and [[discountCredit]] are applied.
 * @param owedAtHotelCharge                What's owed at time of check-in.
 * @param tripTotalCharge                  [[payNowCharge]] plus [[owedAtHotelCharge]] without any credits.
 * @param depositCredit                    Purchase amount of the Hotel Price Freeze voucher to be credited towards the booking.
 * @param discountCredit                   A non-negative amount representing the capped increase from [[PriceFreezeVoucher.frozenPrice]] to [[payNowCharge]].
 * @param totalCredit                      [[depositCredit]] plus [[discountCredit]].
 * @param subtotalWithDepositCreditCharge  [[subtotalCharge]] less [[depositCredit]].
 * @param subtotalWithDiscountCreditCharge [[subtotalCharge]] less [[discountCredit]].
 * @param subtotalWithTotalCreditCharge    [[subtotalCharge]] less [[totalCredit]].
 * @param payNowWithDepositCreditCharge    [[payNowCharge]] less [[depositCredit]].
 * @param payNowWithDiscountCreditCharge   [[payNowCharge]] less [[discountCredit]].
 * @param payNowWithTotalCreditCharge      [[payNowCharge]] less [[totalCredit]].
 * @param tripTotalWithTotalCreditCharge   [[payNowWithTotalCreditCharge]] plus [[owedAtHotelCharge]].
 *
 * @see [[com.hopper.b2b.lodging.model.book.PriceQuote.pricing]]
 * @see [[com.hopper.b2b.lodging.model.book.Pricing.subtotal]]
 * @see [[com.hopper.b2b.lodging.model.book.Pricing.taxes]]
 * @see [[com.hopper.b2b.lodging.model.book.Pricing.payNowTotal]]
 * @see [[com.hopper.b2b.lodging.model.book.Pricing.tripTotal]]
 */
export interface LodgingPriceFreezeGetCreditsStatement {
  subtotalCharge: Prices;
  taxesCharge: Prices;
  payNowCharge: Prices;
  owedAtHotelCharge: Prices;
  tripTotalCharge: Prices;
  depositCredit: Prices;
  savingsCredit: Prices;
  totalCredit: Prices;
  subtotalWithDepositCreditCharge: Prices;
  subtotalWithSavingsCreditCharge: Prices;
  subtotalWithTotalCreditCharge: Prices;
  payNowWithDepositCreditCharge: Prices;
  payNowWithSavingsCreditCharge: Prices;
  payNowWithTotalCreditCharge: Prices;
  tripTotalWithTotalCreditCharge: Prices;
}

export interface LodgingPriceFreezeGetCreditsResponse {
  pricings: CurrentHotelPricingList;
  balance: Prices;
  statement: LodgingPriceFreezeGetCreditsStatement;
  payments: LodgingPriceFreezeGetCreditsPayments;
}

interface LodgingPriceFreezeGetCreditsResultOk {
  value: LodgingPriceFreezeGetCreditsResponse;
  Result: ResultEnum.Ok;
}

export type LodgingPriceFreezeGetCreditsResult =
  | LodgingPriceFreezeGetCreditsResultErr
  | LodgingPriceFreezeGetCreditsResultOk;
