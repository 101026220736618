import { createSelector } from "@reduxjs/toolkit";
import {
  getTotalPriceText,
  twoDecimalFormatter,
  emailRegex,
  phoneRegex,
  getRewardsString,
  roundToTwoDecimals,
  getAgentErrorTitle,
  getAgentErrorSubtitle,
  CurrencyFormatters,
  IHotelPriceLineItem,
  IconName,
  truncateToTwoDecimals,
  getCheckoutCreditBreakdown,
  TEST_CARD_LAST_FOURS,
} from "halifax";
import {
  FiatPrice,
  RewardsPrice,
  CallState,
  PaymentSplitRequestEnum,
  PaymentErrorEnum,
  PaymentVerifyResultEnum,
  RewardsAccountMinimumRequirementState,
  getRewardsAccountMinimumRequirementState,
  ErrorTitles,
  PaymentV2Enum,
  Prices,
  ITrackingProperties,
  CompleteBuyVacationRentalProperties,
  GroupedHomesLineItemEnum,
  CreateHomeProductRequest,
  TypeOfPaymentEnum,
  PaymentSplitRequest,
  UserCardPaymentType,
  SplitPaymentType,
  PaymentType,
  PaymentAmountEnum,
  RewardsPaymentType,
  RewardsAccount,
  CustomerAccountRole,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  getNotifyIfShopAndBookPriceDiffer,
  getVacationRentalShopSelectedListing,
  getViewedVacationRentalDetailsProperties,
} from "../../../shop/reducer/selectors";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
} from "../../../rewards/reducer";
import * as textConstants from "./textConstants";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getPetsCount,
  getUntilDate,
} from "../../../search/reducer";
import dayjs from "dayjs";
import {
  ErrorCode,
  Payment,
  PaymentError,
  PaymentOpaqueValue,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { isCaponeTenant, isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";
import { HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum } from "redmond/apis/tysons/vacation-rentals";
import { FEE_KIND_LABEL_MAPPING } from "./textConstants";

export const getUserPassengers = (state: IStoreState) =>
  state.vacationRentalBook.userPassengers;

export const getUserSelectedTravelerId = (state: IStoreState) => {
  return state.vacationRentalBook.userSelectedTravelerId;
};

export const getUserSelectedTraveler = createSelector(
  getUserSelectedTravelerId,
  getUserPassengers,
  (userSelectedTravelerId, travelers) => {
    return travelers.find((t) => t.id === userSelectedTravelerId);
  }
);

export const getUserPassengerCallState = (state: IStoreState) =>
  state.vacationRentalBook.userPassengerCallState;

export const getConfirmationEmail = (state: IStoreState) =>
  state.vacationRentalBook.confirmationEmailAddress;

export const getConfirmationPhoneNumber = (state: IStoreState) =>
  state.vacationRentalBook.confirmationPhoneNumber;

export const getPaymentMethods = (state: IStoreState) =>
  state.vacationRentalBook.paymentMethods;

export const getPaymentMethod = (state: IStoreState) =>
  state.vacationRentalBook.paymentMethod;

export const getSelectedPaymentMethodId = (state: IStoreState) =>
  state.vacationRentalBook.selectedPaymentMethodId;

export const getListPaymentMethodsCallState = (state: IStoreState) =>
  state.vacationRentalBook.listPaymentMethodCallState;

export const getDeletePaymentMethodCallState = (state: IStoreState) =>
  state.vacationRentalBook.deletePaymentMethodCallState;

export const getFetchPaymentMethodCallState = (state: IStoreState) =>
  state.vacationRentalBook.fetchPaymentMethodCallState;

export const getVerifyPaymentMethodCallState = (state: IStoreState) =>
  state.vacationRentalBook.verifyPaymentMethodCallState;

export const getSession = (state: IStoreState) =>
  state.vacationRentalBook.session;

export const getPriceQuote = (state: IStoreState) =>
  state.vacationRentalBook.priceQuote;

export const getPricingWithAncillaries = (state: IStoreState) =>
  state.vacationRentalBook.pricingWithAncillaries;

export const getPriceQuoteRequest = (state: IStoreState) =>
  state.vacationRentalBook.priceQuoteRequest;

// For Hotel Ancillaries, the priceQuote is only the hotelQuote and doesn't include
// the ancillary costs. The ancillary costs are included in the combinedPricing that
// comes from the BE and is stored in the priceQuoteWithAncillaries. If
// priceQuoteWithAncillaries is available, we want to use that for the total amount due.
export const getPriceQuotePricing = createSelector(
  getPriceQuote,
  getPricingWithAncillaries,
  (priceQuote, pricingWithAncillaries) => {
    if (pricingWithAncillaries) {
      return pricingWithAncillaries;
    } else {
      return priceQuote?.pricing;
    }
  }
);

export const getPriceQuoteErrors = (state: IStoreState) =>
  state.vacationRentalBook.priceQuoteErrors;

export const getSchedulePriceQuoteError = (state: IStoreState) =>
  state.vacationRentalBook.schedulePriceQuoteError;

export const getScheduleBookError = (state: IStoreState) =>
  state.vacationRentalBook.scheduleBookError;

export const getPollPriceQuoteCallState = (state: IStoreState) =>
  state.vacationRentalBook.pollPriceQuoteCallState;

export const getSchedulePriceQuoteCallState = (state: IStoreState) =>
  state.vacationRentalBook.schedulePriceQuoteCallState;

export const getConfirmationDetails = (state: IStoreState) =>
  state.vacationRentalBook.confirmationDetails;

export const getConfirmationDetailsError = (state: IStoreState) =>
  state.vacationRentalBook.confirmationDetailsErrors;

export const getConfirmationDetailsCallState = (state: IStoreState) =>
  state.vacationRentalBook.confirmationDetailsCallState;

export const getOffers = (state: IStoreState) =>
  state.vacationRentalBook.offers;

export const getOfferToApply = (state: IStoreState) =>
  state.vacationRentalBook.offerToApply;

export const getCredit = (state: IStoreState) => {
  const credit = state.vacationRentalBook.credit;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCreditToApply = (state: IStoreState) => {
  const credit = state.vacationRentalBook.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getTravelWalletItemsToApply = createSelector(
  getOfferToApply,
  getCreditToApply,
  (offerToApply, creditToApply) => ({
    walletItem: offerToApply || creditToApply,
    offerToApply,
    creditToApply,
  })
);

export const getFetchApplicableTravelWalletItemsCallState = (
  state: IStoreState
) => state.vacationRentalBook.fetchApplicableTravelWalletItemsCallState;

export const getBestOfferOverall = (state: IStoreState) =>
  state.vacationRentalBook.bestOfferOverall;

export const getPriceDifferenceAcknowledged = (state: IStoreState) =>
  state.vacationRentalBook.priceDifferenceAcknowledged;

export const getRewardsConversionFailed = (state: IStoreState) =>
  state.vacationRentalBook.rewardsConversionFailed;

export const getScheduleBookCallState = (state: IStoreState) =>
  state.vacationRentalBook.scheduleBookCallState;

export const hasNoUserPassengersSelector = createSelector(
  getUserPassengers,
  getUserPassengerCallState,
  (userPassengers, userPassengerCallState) => {
    return (
      userPassengers.length === 0 &&
      userPassengerCallState === CallState.Success
    );
  }
);

// PAYMENT

export const getRewardsPaymentAccountReferenceId = (state: IStoreState) =>
  state.vacationRentalBook.rewardsAccountReferenceId;

export const getRewardsPaymentAccount = createSelector(
  getRewardsPaymentAccountReferenceId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getRewardsPaymentInFiatCurrency = (state: IStoreState) =>
  state.vacationRentalBook.rewardsPaymentInFiatCurrency;

export const getRewardsPaymentInRewardsCurrency = (state: IStoreState) =>
  state.vacationRentalBook.rewardsPaymentTotal;

export const getRewardsRemainingAfterPurchase = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInFiatCurrency,
  (paymentAccount, rewardsPaymentAmount) => {
    if (!paymentAccount) return "";

    const {
      rewardsCashEquivalent: { value, currencyCode, currencySymbol },
    } = paymentAccount;

    const remainder = value - (rewardsPaymentAmount?.value || 0);

    return getTotalPriceText({
      price: {
        currencyCode,
        currencySymbol,
        value: remainder,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getPaymentMethodRewardsAccountId = (state: IStoreState) =>
  state.vacationRentalBook.paymentMethodRewardsAccountId;

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const getEarn = (state: IStoreState) =>
  state.vacationRentalBook.productEarnValue;

export const getCardPaymentRewardsAccount = createSelector(
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getEarnedString = createSelector(
  getEarn,
  getCardPaymentRewardsAccount,
  (earn, account) => {
    if (
      !earn ||
      (isCorpTenant(config.TENANT) &&
        account?.customerAccountRole !== "Primary")
    )
      return "";
    return textConstants.EARNED_STRING(
      earn,
      account?.rewardsBalance.currencyDescription
    );
  }
);

export const getRewardAccountsWithPaymentAccounts = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  (rewardsAccounts, paymentMethods) => {
    const accountsWithSavedPayment = paymentMethods.reduce(
      (savedPayments, currentAcc) => {
        const matchingRewardsAcct = rewardsAccounts.find(
          (rewards) =>
            rewards.lastFour === currentAcc.last4 ||
            rewards.lastFourVirtualCardNumbers?.includes(currentAcc.last4 || "")
        );

        if (matchingRewardsAcct) {
          savedPayments.push(matchingRewardsAcct);
        }
        const isTestCard =
          window.__mclean_env__.ENV !== "production" &&
          currentAcc.last4 &&
          TEST_CARD_LAST_FOURS.includes(currentAcc.last4);
        if (isTestCard) {
          savedPayments.push(rewardsAccounts[0]);
        }

        return savedPayments;
      },
      [] as RewardsAccount[]
    );
    return accountsWithSavedPayment;
  }
);

export enum CheckoutSteps {
  ADD_TRAVELERS = 0,
  CONTACT = 1,
  REWARDS_AND_PAYMENT = 2,
}

export const verifyPaymentMethodResultSelector = (state: IStoreState) =>
  state.vacationRentalBook.verifyPaymentMethodResult;

export const getSelectedPaymentCardType = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  getSelectedPaymentMethodId,
  (accounts, paymentMethods, id): string => {
    const paymentMethod = paymentMethods.find((p) => p.id === id);
    if (
      accounts[0]?.customerAccountRole === CustomerAccountRole.NonFinancialUser
    ) {
      return paymentMethod?.cardType ?? "";
    }
    return (
      accounts.find(
        (a) =>
          a.lastFour === paymentMethod?.last4 ||
          a.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4 || "")
      )?.productDisplayName || ""
    );
  }
);

export const getIsPaymentMethodVCN = createSelector(
  getPaymentMethodRewardsAccountId,
  getPaymentMethod,
  getRewardsAccounts,
  (paymentRewardsAccountId, paymentMethod, rewardsAccounts) => {
    if (!paymentRewardsAccountId || !paymentMethod || !rewardsAccounts)
      return false;

    const account = rewardsAccounts.find(
      (account) => account.accountReferenceId === paymentRewardsAccountId
    );

    return !!(
      account?.lastFourVirtualCardNumbers &&
      paymentMethod?.last4 &&
      account?.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4)
    );
  }
);

export const rewardsAccountMinimumRequirementStateSelector = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccountMinimumRequirementState => {
    return getRewardsAccountMinimumRequirementState(rewardsAccounts);
  }
);

export const getVacationRentalsSchedulePriceQuoteCallState = (
  state: IStoreState
) => state.vacationRentalBook.scheduleVacationRentalsPriceQuoteCallState;

export const getVacationRentalsPollPriceQuoteCallState = (state: IStoreState) =>
  state.vacationRentalBook.pollVacationRentalsPriceQuoteCallState;

export const getVacationRentalsPriceQuote = (state: IStoreState) =>
  state.vacationRentalBook.vacationRentalsPriceQuote;

export const getScheduleVRBookCallState = (state: IStoreState) =>
  state.vacationRentalBook.scheduleVacationRentalsBookCallState;

export const getVRConfirmationDetailsCallState = (state: IStoreState) =>
  state.vacationRentalBook.pollVacationRentalsConfirmationDetailsCallState;

export const getVRConfirmationDetailsError = (state: IStoreState) =>
  state.vacationRentalBook.vacationRentalsConfirmationDetailsErrors;

export const getEarnValuesByRewardAcctId = (state: IStoreState) =>
  state.vacationRentalBook.earnValuesByRewardAcctId;

export const getVacationRentalsPriceQuotePricing = createSelector(
  getVacationRentalsPriceQuote,
  (priceQuote) => {
    return priceQuote?.rate;
  }
);

export const getPollVacationRentalsPriceQuoteCallState = (state: IStoreState) =>
  state.vacationRentalBook.pollVacationRentalsPriceQuoteCallState;

export const getScheduleVacationRentalsPriceQuoteCallState = (
  state: IStoreState
) => state.vacationRentalBook.scheduleVacationRentalsPriceQuoteCallState;

export const getVacationRentalConfirmationDetails = (state: IStoreState) =>
  state.vacationRentalBook.vacationRentalsConfirmationDetails;

export const getAddMainGuestCallState = (state: IStoreState) =>
  state.vacationRentalBook.addMainGuestCallState;

export const getVacationRentalsPricingTotal = createSelector(
  getVacationRentalsPriceQuotePricing,
  (pricing) => {
    return pricing ? pricing.detailedPrice.payNowTotal : null;
  }
);

export const getTotalVacationRentalsToPay = createSelector(
  getTravelWalletItemsToApply,
  getVacationRentalsPricingTotal,
  (travelWalletItemsToApply, priceQuotePricingTotal): Prices | null => {
    let total = priceQuotePricingTotal ?? null;
    let rewardsTotal: Record<string, any> = {};
    if (
      total &&
      (travelWalletItemsToApply.offerToApply ||
        travelWalletItemsToApply.creditToApply)
    ) {
      let walletItemsTotal: number =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;

      if (travelWalletItemsToApply.creditToApply) {
        const maxApplicableCredit =
          travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
        if (
          maxApplicableCredit &&
          maxApplicableCredit >
            travelWalletItemsToApply.creditToApply.amount.amount
        ) {
          walletItemsTotal += maxApplicableCredit;
        } else {
          walletItemsTotal +=
            travelWalletItemsToApply.creditToApply.amount.amount;
        }
      }
      Object.keys(total.rewards).forEach((accountId) => {
        if (total) {
          let rewardTotalValue = total.rewards[accountId].value;
          rewardTotalValue =
            total.rewards[accountId].currency === "Cash"
              ? rewardTotalValue + walletItemsTotal
              : rewardTotalValue + walletItemsTotal * 100;
          rewardsTotal[accountId] = {
            currency: total.rewards[accountId].currency,
            currencyDescription: total.rewards[accountId].currencyDescription,
            value: rewardTotalValue > 0 ? rewardTotalValue : 0,
          };
        }
      });
      return {
        fiat: {
          currencyCode: total.fiat.currencyCode,
          currencySymbol: total.fiat.currencySymbol,
          value:
            total.fiat.value + walletItemsTotal > 0
              ? total.fiat.value + walletItemsTotal
              : 0,
        },
        rewards: rewardsTotal,
      };
    }
    return priceQuotePricingTotal ?? null;
  }
);

export const getTotalVacationRentalsCreditCardPaymentRequired = createSelector(
  getTotalVacationRentalsToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return "";

    if (!rewardsPaymentAmount) {
      return getTotalPriceText({
        price: estimate.fiat,
        priceFormatter: twoDecimalFormatter,
      });
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total = remainder <= 0 ? 0 : remainder;

    return getTotalPriceText({
      price: {
        currencyCode: estimate.fiat.currencyCode,
        currencySymbol: estimate.fiat.currencySymbol,
        value: total,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getVacationRentalsTravelWalletOfferToApplyAmount = createSelector(
  getTravelWalletItemsToApply,

  (travelWalletItemsToApply) => {
    if (travelWalletItemsToApply.offerToApply) {
      return travelWalletItemsToApply.offerToApply.amount.amount * -1;
    } else {
      return 0;
    }
  }
);

export const getVacationRentalsTravelWalletCreditToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  getVacationRentalsPricingTotal,
  getVacationRentalsTravelWalletOfferToApplyAmount,
  (travelWalletItemsToApply, priceQuotePricingTotal, offerAppliedAmount) => {
    const totalFiatValue = priceQuotePricingTotal?.fiat.value || 0;
    const totalFiatValueWithOfferApplied: number =
      totalFiatValue - offerAppliedAmount;
    if (
      travelWalletItemsToApply.creditToApply &&
      totalFiatValueWithOfferApplied > 0
    ) {
      const maxApplicableCredit =
        travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
      let amount =
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
          ? maxApplicableCredit
          : travelWalletItemsToApply.creditToApply.amount.amount;
      if (totalFiatValueWithOfferApplied < amount * -1) {
        if (
          maxApplicableCredit &&
          maxApplicableCredit <
            travelWalletItemsToApply.creditToApply.amount.amount &&
          travelWalletItemsToApply.creditToApply.amount.amount * -1 <
            totalFiatValueWithOfferApplied
        ) {
          return travelWalletItemsToApply.creditToApply.amount.amount * -1;
        }
        return totalFiatValueWithOfferApplied;
      }
      if (
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
      ) {
        return travelWalletItemsToApply.creditToApply.amount.amount * -1;
      }
      return amount * -1;
    } else {
      return 0;
    }
  }
);

export const getVacationRentalsMaxApplicableTravelWalletCreditAmount =
  createSelector(
    getCredit,
    getVacationRentalsPricingTotal,
    getVacationRentalsTravelWalletOfferToApplyAmount,
    (credit, priceQuotePricingTotal, offerAppliedAmount) => {
      if (priceQuotePricingTotal && credit) {
        if (
          priceQuotePricingTotal.fiat.value - offerAppliedAmount <
          credit.amount.amount * -1
        ) {
          return (priceQuotePricingTotal.fiat.value - offerAppliedAmount) * -1;
        }
        return credit.amount.amount;
      } else {
        return 0;
      }
    }
  );

export const getVacationRentalsTripTotalInPrices = createSelector(
  getVacationRentalsPriceQuote,
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  getVacationRentalsPriceQuotePricing,
  (
    priceQuote,
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    priceQuotePricing
  ): { fiat: FiatPrice; rewards?: RewardsPrice } | null => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    const includeRewards = activeRewardsAccount?.allowRewardsRedemption ?? true;

    if (priceQuote && priceQuotePricing && activeRewardsAccount) {
      return {
        fiat: priceQuotePricing.detailedPrice.payNowTotal.fiat,
        rewards: includeRewards
          ? priceQuotePricing.detailedPrice.payNowTotal.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    }
    return null;
  }
);

export const getVacationRentalsRewardsAndTotalLineItems = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getTotalVacationRentalsCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getPaymentMethods,
  getTravelWalletItemsToApply,
  getVacationRentalsTravelWalletOfferToApplyAmount,
  getVacationRentalsTravelWalletCreditToApplyAmount,
  (
    rewardsPaymentAccount,
    rewardsPayment,
    rewardsInFiat,
    totalAmountDueOnCredit,
    paymentMethodId,
    paymentMethods,
    travelWalletItemsToApply,
    offerAmountToApply,
    creditAmountToApply
  ) => {
    const rewardsLineItems = [];

    if (
      rewardsPaymentAccount &&
      (rewardsPaymentAccount.allowRewardsRedemption ?? true)
    ) {
      const fiatPrice = rewardsInFiat ?? {
        currencyCode: rewardsPaymentAccount?.rewardsCashEquivalent.currencyCode,
        currencySymbol:
          rewardsPaymentAccount?.rewardsCashEquivalent.currencySymbol,
        value: 0,
      };

      const priceText = getTotalPriceText({
        price: {
          ...fiatPrice,
          value: fiatPrice.value,
        },
        priceFormatter: twoDecimalFormatter,
      });

      const rewardsText = rewardsPayment
        ? `- ${getRewardsString(rewardsPayment)}`
        : "";
      rewardsLineItems.push({
        title: `${rewardsPaymentAccount?.productDisplayName} ${textConstants.REWARDS}`,
        value: `- ${priceText}`,
        rewardsValue: rewardsText,
      });
    }

    const paymentMethod = paymentMethods.find((p) => p.id === paymentMethodId);
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      const travelWalletItems = [];
      if (travelWalletItemsToApply.offerToApply && offerAmountToApply) {
        travelWalletItems.push({
          title: textConstants.TRAVEL_OFFER_APPLIED,
          value: getTotalPriceText({
            price: {
              value: offerAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.offerToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.offerToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: true,
          isTravelCredit: false,
          icon: "offer-tag",
        });
      }
      if (travelWalletItemsToApply.creditToApply && creditAmountToApply) {
        const breakdownHasStatementCredit =
          !!travelWalletItemsToApply.creditToApply.breakdown?.some(
            (detail) =>
              detail.CreditDetail === "Statement" &&
              Math.abs(detail.usableAmount.amount) > 0
          );
        travelWalletItems.push({
          title: breakdownHasStatementCredit
            ? textConstants.TOTAL_TRAVEL_CREDITS_APPLIED
            : textConstants.TRAVEL_CREDITS_APPLIED,
          value: getTotalPriceText({
            price: {
              value: creditAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.creditToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.creditToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: false,
          isTravelCredit: true,
          icon: "piggy-bank-icon",
          className: "wallet-item",
          travelCreditBreakdown: breakdownHasStatementCredit
            ? getCheckoutCreditBreakdown(
                travelWalletItemsToApply.creditToApply.breakdown || [],
                creditAmountToApply,
                travelWalletItemsToApply.creditToApply.amount.currency
              )
            : [],
        });
      }

      return [
        ...travelWalletItems,
        ...rewardsLineItems,
        {
          title: paymentMethod
            ? `Ending in ${paymentMethod.last4}:`
            : textConstants.AMOUNT_DUE,
          value: totalAmountDueOnCredit,
          icon: IconName.Payment,
        },
      ];
    }

    return [
      ...rewardsLineItems,
      {
        title: paymentMethod
          ? `Ending in ${paymentMethod.last4}:`
          : textConstants.AMOUNT_DUE,
        value: totalAmountDueOnCredit,
        icon: IconName.Payment,
      },
    ];
  }
);

export const getVRPriceDifferenceAcknowledged = (state: IStoreState) =>
  state.vacationRentalBook.priceDifferenceAcknowledged;

export const getVRPriceDifference = createSelector(
  getNotifyIfShopAndBookPriceDiffer,
  getVacationRentalShopSelectedListing,
  getVacationRentalsPriceQuote,
  getVRPriceDifferenceAcknowledged,
  (
    notify,
    selectedHome,
    priceQuote,
    acknowledged
  ): {
    hasDifference: boolean;
    isIncrease: boolean;
    seenPrice: number;
    priceQuoteTotal: number;
    amount?: string;
  } => {
    if (
      !notify ||
      !selectedHome ||
      !selectedHome.availability.rate ||
      !priceQuote ||
      acknowledged
    ) {
      return {
        hasDifference: false,
        isIncrease: false,
        seenPrice: 0,
        priceQuoteTotal: 0,
      };
    }

    const seenPrice = selectedHome.availability.rate.price.total.fiat.value;
    const priceQuoteTotal =
      priceQuote.rate.detailedPrice.payNowTotal.fiat.value;

    return {
      hasDifference: Math.abs(priceQuoteTotal - seenPrice) >= 1,
      isIncrease: priceQuoteTotal > seenPrice,
      amount: Math.abs(priceQuoteTotal - seenPrice)
        .toFixed(0)
        .toString(),
      seenPrice,
      priceQuoteTotal,
    };
  }
);

export const getGroupedPricingLineItems = createSelector(
  getVacationRentalsPriceQuotePricing,
  (priceQuotePricing): IHotelPriceLineItem[] => {
    if (priceQuotePricing) {
      const tooltipMapping: Partial<
        Record<HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum, string>
      > = {
        [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.CleaningFee]:
          "One-time fee charged to cover the cost of cleaning the space.",
      };

      const totalBasePriceGroup =
        priceQuotePricing.detailedPrice.groupedItems.find(
          (groupedItem) =>
            groupedItem.GroupedHomesLineItem ===
            GroupedHomesLineItemEnum.VacationRental
        );

      const totalBasePriceItem = totalBasePriceGroup
        ? [
            {
              title: totalBasePriceGroup.displayName,
              value: getTotalPriceText({
                // Use the sell price since the service fee is rolled into the base price group item
                // but we want to show them separately.
                price: priceQuotePricing.detailedPrice.sellPrice.fiat,
                priceFormatter: twoDecimalFormatter,
              }),
            },
          ]
        : undefined;

      // Render the line items individually rather than grouping them.
      const flatFeeLineItems = priceQuotePricing.detailedPrice.fees.map(
        (item) => {
          return {
            title: item.name || FEE_KIND_LABEL_MAPPING[item.kind.Kind],
            value: getTotalPriceText({
              price: item.amount.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
            tooltip: tooltipMapping[item.kind.Kind] || undefined,
          };
        }
      );

      const lineItems = (totalBasePriceItem || []).concat(flatFeeLineItems);
      return lineItems;
    } else {
      return [];
    }
  }
);

export const getVacationRentalsPricingLineItems = createSelector(
  getVacationRentalShopSelectedListing,
  getVacationRentalsPriceQuote,
  getRewardsPaymentAccount,
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  getVacationRentalsPriceQuotePricing,
  getGroupedPricingLineItems,
  (
    product,
    priceQuote,
    rewardsPaymentAccount,
    paymentMethodRewardsAccountId,
    rewardsAccounts,
    priceQuotePricing,
    taxesAndFeesLineItems
  ): IHotelPriceLineItem[][] => {
    if (priceQuote && priceQuotePricing && product) {
      const rewardsAccountId = rewardsPaymentAccount
        ? rewardsPaymentAccount.accountReferenceId
        : paymentMethodRewardsAccountId
        ? paymentMethodRewardsAccountId
        : Object.keys(priceQuotePricing.detailedPrice.payNowTotal.rewards)[0];

      const total = priceQuotePricing.detailedPrice.payNowTotal;

      const includeRewards =
        rewardsAccounts.find(
          (account) => account.accountReferenceId === rewardsAccountId
        )?.allowRewardsRedemption ?? true;

      return [
        taxesAndFeesLineItems,
        [
          {
            title: textConstants.TOTAL,
            value: getTotalPriceText({
              price: {
                currencyCode: total.fiat.currencyCode,
                currencySymbol: total.fiat.currencySymbol,
                value: total.fiat.value,
              },
              priceFormatter: twoDecimalFormatter,
            }),
            rewardsValue: includeRewards
              ? getRewardsString(total.rewards[rewardsAccountId])
              : undefined,
            boldLabel: true,
            type: "total",
          } as IHotelPriceLineItem,
        ],
      ];
    } else {
      return [];
    }
  }
);

export const getVacationRentalsIsTravelWalletCreditPaymentOnly = createSelector(
  getTotalVacationRentalsToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      !travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getVacationRentalsIsTravelWalletOfferPaymentOnly = createSelector(
  getTotalVacationRentalsToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      !travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getVacationRentalsIsStackedTravelWalletPaymentOnly =
  createSelector(
    getTotalVacationRentalsToPay,
    getTravelWalletItemsToApply,
    (total, travelWalletItemsToApply) => {
      if (
        travelWalletItemsToApply.offerToApply &&
        travelWalletItemsToApply.creditToApply &&
        total?.fiat.value === 0
      ) {
        return true;
      }
      return false;
    }
  );

export const getVacationRentalsIsTravelWalletPaymentOnly = createSelector(
  getVacationRentalsIsTravelWalletCreditPaymentOnly,
  getVacationRentalsIsTravelWalletOfferPaymentOnly,
  getVacationRentalsIsStackedTravelWalletPaymentOnly,
  (
    isTravelWalletCreditPaymentOnly,
    isTravelWalletOfferPaymentOnly,
    isStackedTravelWalletPaymentOnly
  ) => {
    if (
      isTravelWalletCreditPaymentOnly ||
      isTravelWalletOfferPaymentOnly ||
      isStackedTravelWalletPaymentOnly
    ) {
      return true;
    }
    return false;
  }
);

export const getVacationRentalsIsCreditCardPaymentRequired = createSelector(
  getTotalVacationRentalsToPay,
  getRewardsPaymentInFiatCurrency,
  getVacationRentalsIsTravelWalletPaymentOnly,
  (total, rewardsPaymentAmount, isTravelWalletPaymentOnly) => {
    if (isTravelWalletPaymentOnly) return false;

    if (!total) return true;
    if (!rewardsPaymentAmount) return true;

    return total.fiat.value > rewardsPaymentAmount.value;
  }
);

export const getTotalVacationRentalsCreditCardPaymentRequiredNumber =
  createSelector(
    getTotalVacationRentalsToPay,
    getRewardsPaymentInFiatCurrency,
    (estimate, rewardsPaymentAmount) => {
      if (!estimate) return null;

      if (!rewardsPaymentAmount) {
        return estimate.fiat.value;
      }

      const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
      const total =
        remainder <= 0
          ? 0
          : Math.round((remainder + Number.EPSILON) * 100) / 100;
      return total;
    }
  );

export const getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice =
  createSelector(
    getTotalVacationRentalsToPay,
    getRewardsPaymentInFiatCurrency,
    (totalToPay, rewardsPaymentAmount): FiatPrice | undefined => {
      if (!totalToPay) return undefined;

      if (!rewardsPaymentAmount) return totalToPay.fiat;

      const remainder = totalToPay.fiat.value - rewardsPaymentAmount.value;
      const total = remainder <= 0 ? 0 : remainder;

      return {
        currencyCode: totalToPay.fiat.currencyCode,
        currencySymbol: totalToPay.fiat.currencySymbol,
        value: total,
      };
    }
  );

// TODO: VR - Include fulfill states
export const getVacationRentalsBookingInProgress = createSelector(
  getVacationRentalsSchedulePriceQuoteCallState,
  getVacationRentalsPollPriceQuoteCallState,
  (scheduleQuote, pollPriceQuote) => {
    return (
      scheduleQuote === CallState.InProcess ||
      pollPriceQuote === CallState.InProcess
    );
  }
);

export const getVacationRentalsIsBookingValid = createSelector(
  getVacationRentalsIsCreditCardPaymentRequired,
  getVRPriceDifference,
  getVRPriceDifferenceAcknowledged,
  getSelectedPaymentMethodId,
  getUserSelectedTravelerId,
  getVacationRentalsBookingInProgress,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getVacationRentalsIsTravelWalletPaymentOnly,
  getAddMainGuestCallState,
  (
    creditCardRequired,
    priceDifference,
    priceDifferenceAcknowledged,
    selectedPaymentMethodId,
    userSelectedTravelerId,
    bookingInProgress,
    email,
    phone,
    isTravelWalletPaymentOnly,
    addMainGuestCallState
  ) => {
    const paymentUnsettled =
      creditCardRequired &&
      !selectedPaymentMethodId &&
      !isTravelWalletPaymentOnly;

    if (
      (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
      paymentUnsettled ||
      !userSelectedTravelerId ||
      bookingInProgress ||
      !email ||
      !phone ||
      addMainGuestCallState !== CallState.Success
    ) {
      return false;
    } else {
      return true;
    }
  }
);

export const getVacationRentalsBookingProgress = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getVacationRentalsIsCreditCardPaymentRequired,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.ADD_TRAVELERS,
        status: !!traveler ? Progress.COMPLETED : Progress.IN_PROGRESS,
      },
      {
        name: textConstants.CONTACT,
        status:
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.NOT_STARTED,
      },
      {
        name: textConstants.REWARDS_AND_PAYMENT,
        status: paymentCompleted(),
      },
    ];
  }
);

export const areAllVacationRentalsStepsCompletedInCheckout = createSelector(
  getVacationRentalsBookingProgress,
  getVacationRentalsIsTravelWalletPaymentOnly,
  (checkoutProgress, isTravelWalletPaymentOnly) => {
    return (
      checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
        Progress.COMPLETED &&
      checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED &&
      (!isTravelWalletPaymentOnly
        ? checkoutProgress[CheckoutSteps.REWARDS_AND_PAYMENT].status ===
          Progress.COMPLETED
        : true)
    );
  }
);

export const isTravelerStepComplete = createSelector(
  getVacationRentalsBookingProgress,
  (checkoutProgress) => {
    return (
      checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
      Progress.COMPLETED
    );
  }
);

export const isContactStepComplete = createSelector(
  getVacationRentalsBookingProgress,
  (checkoutProgress) => {
    return (
      checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED
    );
  }
);

export const vacationRentalPriceQuoteParamsSelector = createSelector(
  getVacationRentalShopSelectedListing,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  (
    selectedHome,
    checkIn,
    checkOut,
    adultsCount,
    childrenCount,
    petsCount
  ): CreateHomeProductRequest | null => {
    if (!selectedHome || !selectedHome.availability.rate) return null;
    return {
      listingId: selectedHome?.listingId,
      listingProviderId: selectedHome.listing.providerData.listingProviderId,
      stayDates: {
        from: dayjs(checkIn).format("YYYY-MM-DD"),
        until: dayjs(checkOut).format("YYYY-MM-DD"),
      },
      guests: {
        adults: adultsCount,
        children: childrenCount,
        infants: 0,
        petsIncluded: petsCount ? petsCount > 0 : false,
      },
      seenPrice: selectedHome.availability.rate.price.rentTotal,
      listingCollection: selectedHome.listing.listingCollection,
    };
  }
);

export const getVacationRentalsPaymentRequestType = createSelector(
  getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  (
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    selectedPaymentMethodId
  ) => {
    if (selectedPaymentMethodId && !rewardsPayment) {
      return PaymentSplitRequestEnum.PaymentCardRequest;
    } else if (
      rewardsAccountId &&
      rewardsPayment &&
      rewardsFiatPayment &&
      creditCardPayment?.value === 0
    ) {
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    } else if (rewardsPayment && rewardsAccountId && rewardsFiatPayment) {
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    } else {
      return null;
    }
  }
);

export const getVacationRentalsOpaquePayments = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getVacationRentalsPaymentRequestType,
  getTravelWalletItemsToApply,
  getVacationRentalsTravelWalletOfferToApplyAmount,
  getVacationRentalsTravelWalletCreditToApplyAmount,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount
  ): PaymentOpaqueValue[] | null => {
    let amount: PaymentOpaqueValue[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        const offer: PaymentOpaqueValue = {
          type: Payment.TravelWalletOffer,
          value: {
            offerId: travelWalletItemsToApply.offerToApply.id,
            description:
              travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
            paymentAmount: {
              fiatValue: {
                amount: offerToApplyAmount,
                currency: travelWalletItemsToApply.offerToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletOffer,
          },
        };
        amount.push(offer);
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        const credit: PaymentOpaqueValue = {
          type: Payment.TravelWalletCredit,
          value: {
            offerId: travelWalletItemsToApply.creditToApply.id,
            description: "TravelWalletCredit", // creditToApply doesn't have a description
            paymentAmount: {
              fiatValue: {
                amount: creditToApplyAmount,
                currency:
                  travelWalletItemsToApply.creditToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletCredit,
          },
        };
        amount.push(credit);
      }
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };
        amount.push(userCardPayment);
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitUserCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };

        const splitRewardsPayment: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };

        amount.push(splitUserCardPayment, splitRewardsPayment);
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };
        amount.push(rewardsPaymentType);
        break;
      default:
        break;
    }

    if (amount) {
      return amount;
    } else {
      return null;
    }
  }
);

export const bookingVRInProgressSelector = createSelector(
  getScheduleVRBookCallState,
  getVRConfirmationDetailsCallState,
  (scheduleBookCallState, confirmationCallState) => {
    return (
      scheduleBookCallState === CallState.InProcess ||
      confirmationCallState === CallState.InProcess
    );
  }
);

const getErrorMessage = (error: PurchaseError): ErrorTitles => {
  const { code = "" } = error as ErrorCode;
  let agentSubtitle = getAgentErrorSubtitle(error.Error);
  let agentTitle = getAgentErrorTitle(error.Error);
  let titles = textConstants.GENERIC_VR_ERROR_TITLES;

  switch (error.Error) {
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      if (paymentError.value.type === Payment.Rewards)
        titles = textConstants.REDEMPTION_FAILED_TITLES;
      break;
    case PaymentErrorEnum.ErrorCode:
      agentSubtitle = getAgentErrorSubtitle(code);
      agentTitle = getAgentErrorTitle(code);

      switch (code) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES;
          break;
        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
      }
      break;
    case PurchaseErrorEnum.ProductError:
      const { errorEnum } = (error as ProductError).value.value;
      agentSubtitle = getAgentErrorSubtitle(errorEnum);
      agentTitle = getAgentErrorTitle(errorEnum);
      switch (errorEnum) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES;
          break;

        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
      }
      break;
    default:
  }

  return { ...titles, agentSubtitle, agentTitle };
};

export const getVRPriceQuoteErrors = (state: IStoreState) =>
  state.vacationRentalBook.vacationRentalsPriceQuoteErrors;

export const getVRSchedulePriceQuoteError = (state: IStoreState) =>
  state.vacationRentalBook.scheduleVacationRentalsPriceQuoteError;

export const getVRScheduleBookError = (state: IStoreState) =>
  state.vacationRentalBook.scheduleVacationRentalsBookError;

export const getVRHasError = createSelector(
  getUserPassengerCallState,
  getVRPriceDifference,
  getVRPriceDifferenceAcknowledged,
  getVacationRentalsPollPriceQuoteCallState,
  getVRScheduleBookError,
  getVRSchedulePriceQuoteError,
  getVRConfirmationDetailsCallState,
  getRewardsConversionFailed,
  getVerifyPaymentMethodCallState,
  getAddMainGuestCallState,
  (
    passengerCallState,
    priceDifference,
    priceDifferenceAcknowledged,
    priceQuoteCallState,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsCallState,
    rewardsConversionFailed,
    verifyPaymentMethodCallState,
    addMainGuestCallState
  ) =>
    passengerCallState === CallState.Failed ||
    (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
    !!rewardsConversionFailed ||
    !!scheduleBookError ||
    !!schedulePriceQuoteError ||
    verifyPaymentMethodCallState === CallState.Failed ||
    confirmationDetailsCallState === CallState.Failed ||
    priceQuoteCallState === CallState.Failed ||
    addMainGuestCallState === CallState.Failed
);

export const getVRErrorTitles = createSelector(
  getVRHasError,
  getVRPriceQuoteErrors,
  getVRScheduleBookError,
  getVRSchedulePriceQuoteError,
  getVRConfirmationDetailsError,
  getVRPriceDifference,
  verifyPaymentMethodResultSelector,
  (
    hasError,
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult
  ): ErrorTitles => {
    if (!hasError) return { title: "", subtitle: "", primaryButtonText: "" };

    if (priceDifference.hasDifference && priceDifference.amount) {
      const primaryButtonText = textConstants.CONTINUE;

      return {
        title: textConstants.getPriceDifferenceTitle(
          priceDifference.isIncrease,
          priceDifference.amount
        ),
        subtitle: priceDifference.isIncrease
          ? textConstants.VR_PRICE_INCREASE_SUBTITLE
          : textConstants.VR_PRICE_DECREASE_SUBTITLE,
        primaryButtonText: primaryButtonText,
        icon: priceDifference.isIncrease
          ? textConstants.PRICE_INCREASE_ICON
          : textConstants.PRICE_DECREASE_ICON,
      };
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      return textConstants.PAYMENT_METHOD_ERROR_TITLES(
        verifyPaymentMethodResult
      );
    }

    if (confirmationDetailsErrors.length > 0) {
      return getErrorMessage(confirmationDetailsErrors[0]);
    }

    if (scheduleBookError) {
      return textConstants.GENERIC_VR_ERROR_TITLES;
    }

    if (schedulePriceQuoteError) {
      return textConstants.GENERIC_VR_ERROR_TITLES;
    }

    if (priceQuoteErrors.length > 0) {
      return getErrorMessage(priceQuoteErrors[0]);
    }

    return textConstants.GENERIC_VR_ERROR_TITLES;
  }
);

export const getCompleteBuyVacationRentalProperties = createSelector(
  getViewedVacationRentalDetailsProperties,
  getVacationRentalConfirmationDetails,
  getPaymentMethods,
  (
    trackingProperties,
    confirmationDetails,
    paymentMethods
  ): ITrackingProperties<CompleteBuyVacationRentalProperties> => {
    return {
      properties: {
        ...trackingProperties.properties,
        success: false,
        reservation_id: confirmationDetails?.id.value ?? "",
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [...trackingProperties.encryptedProperties],
    };
  }
);

export const getVRModalType = createSelector(
  getVRPriceQuoteErrors,
  getVRScheduleBookError,
  getVRSchedulePriceQuoteError,
  getVRConfirmationDetailsError,
  getVRPriceDifference,
  verifyPaymentMethodResultSelector,
  getAddMainGuestCallState,
  (
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult,
    addMainGuestCallState
  ): string => {
    if (priceDifference.hasDifference && priceDifference.amount) {
      return "pc_price_difference";
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      return "payment_verification_failed";
    }

    if (confirmationDetailsErrors.length > 0) {
      return "confirmation_details_failed";
    }

    if (scheduleBookError) {
      return "schedule_book_failed";
    }

    if (schedulePriceQuoteError) {
      return "schedule_price_quote_failed";
    }

    if (priceQuoteErrors.length > 0) {
      return "poll_price_quote_failed";
    }

    if (addMainGuestCallState === CallState.Failed) {
      return "add_main_guest_failed";
    }

    return "";
  }
);

export const getVacationRentalCombinedBookingSteps = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getVacationRentalsIsCreditCardPaymentRequired,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.TRAVELER_INFORMATION,
        status:
          !!traveler &&
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.IN_PROGRESS,
      },
      {
        name: isCaponeTenant(config.TENANT)
          ? textConstants.REWARDS_AND_PAYMENT
          : textConstants.CORP_REWARDS_AND_PAYMENT_SHORTENED,
        status: paymentCompleted(),
      },
    ];
  }
);

export const getIsCreditCardPaymentRequired = createSelector(
  getTotalVacationRentalsToPay,
  getRewardsPaymentInFiatCurrency,
  getVacationRentalsIsTravelWalletCreditPaymentOnly,
  (total, rewardsPaymentAmount, isTravelWalletPaymentOnly) => {
    if (isTravelWalletPaymentOnly) return false;

    if (!total) return true;
    if (!rewardsPaymentAmount) return true;

    return total.fiat.value > rewardsPaymentAmount.value;
  }
);

export const getCombinedBookingSteps = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.TRAVELER_INFORMATION,
        status:
          !!traveler &&
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.IN_PROGRESS,
      },
      {
        name: isCaponeTenant(config.TENANT)
          ? textConstants.REWARDS_AND_PAYMENT
          : textConstants.CORP_REWARDS_AND_PAYMENT_SHORTENED,
        status: paymentCompleted(),
      },
    ];
  }
);

export const getPaymentRequest = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getVacationRentalsPaymentRequestType,
  getSession,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session
  ): PaymentSplitRequest | null => {
    let amount: PaymentType | null = null;
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: UserCardPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
            PaymentAmount: PaymentAmountEnum.FiatAmount,
          },
          Payment: TypeOfPaymentEnum.UserCard,
        };
        amount = userCardPayment;
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitPayment: SplitPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            fiatAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
              PaymentAmount: PaymentAmountEnum.FiatAmount,
            },
            rewardsAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
              PaymentAmount: PaymentAmountEnum.RewardsAmount,
            },
            PaymentAmount: PaymentAmountEnum.SplitAmount,
          },
          Payment: TypeOfPaymentEnum.Split,
        };
        amount = splitPayment;
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: RewardsPaymentType = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
            PaymentAmount: PaymentAmountEnum.RewardsAmount,
          },
          Payment: TypeOfPaymentEnum.Rewards,
        };
        amount = rewardsPaymentType;
        break;
      default:
        return null;
    }

    if (amount && session) {
      const request = {
        token: session,
        payment: amount,
        ancillaries: [],
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getCardPaymentAccount = createSelector(
  getRewardsAccounts,
  getPaymentRequest,
  (rewardsAccounts, paymentRequest: PaymentSplitRequest | null) => {
    switch (paymentRequest?.payment.Payment) {
      case TypeOfPaymentEnum.UserCard:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as UserCardPaymentType).accountReferenceId
        );
      case TypeOfPaymentEnum.Split:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as SplitPaymentType).accountReferenceId
        );
      default:
        return null;
    }
  }
);
