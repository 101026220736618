import { Prices } from "../common";
import { HotelItinerary, HotelItineraryState } from "./hotelItinerary";
import {
  TripContext,
  BookedFlightItineraryWithDepartureTime,
  FlightItineraryState,
  PaymentBreakdown,
} from "./itinerary";

export interface PackageItineraryRequest {
  states: PackageItineraryState[];
  referenceDateTime: string;
}

export enum PackageItineraryState {
  Future = "Future",
  Present = "Present",
  Past = "Past",
  Canceled = "Canceled",
}

export interface PackageItineraryResponse {
  itineraries: {
    [key: string]: PackageItinerary[];
  };
  context: TripContext;
}

export interface PackageItinerary {
  hotel: HotelItineraryWithState;
  flight: FlightItineraryWithState;
  pricingBreakdown: PackagePricingBreakdown;
  paymentBreakdown?: PaymentBreakdown;
  customerConfirmationId: string;
}

export interface HotelItineraryWithState {
  state: HotelItineraryState;
  itinerary: HotelItinerary;
}

export interface FlightItineraryWithState {
  state: FlightItineraryState;
  itinerary: BookedFlightItineraryWithDepartureTime;
}

export interface PackagePricingBreakdown {
  subtotal: Subtotal;
  savingsAmount?: Prices;
  seatsAmount?: Prices;
  payNowAmount: Prices;
  payAtHotelAmount?: Prices;
}

export interface Subtotal {
  totalAmount: Prices;
  perTravelerAmount: Prices;
  numTravelers: number;
}
