import React, { useContext } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { ActionButton, Header, HotelName, HomeSummary } from "halifax";
import { ClientContext } from "../../../../App";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_STAYS } from "../../../../utils/paths";
import { goToAvailability } from "../../../shop/utils/queryStringHelpers";
import { RewardsAccountSelection } from "../../components/RewardsAccountSelection";
import { IDesktopRewardsHeaderProps } from "./container";
import { ListingAddressEnum } from "redmond/apis/tysons/vacation-rentals";
import * as textConstants from "./textConstants";
import "./styles.scss";

export const DesktopRewardsHeader = (props: IDesktopRewardsHeaderProps) => {
  const {
    history,
    listing,
    fromDate,
    untilDate,
    adultsCount,
    children,
    petsCount,
    vacationRentalShopCallInProgress,
    variant = "shop",
    title,
    subtitle,
  } = props;
  const clientContext = useContext(ClientContext);
  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  let city =
    listing?.listing?.content?.location?.address?.ListingAddress !==
    ListingAddressEnum.Empty
      ? listing?.listing?.content?.location?.address?.city
      : undefined;

  const starRating = listing?.listing?.content?.starRating;
  const userRating = listing?.listing?.content?.userRating?.score;

  const returnToAvailability = () => {
    goToAvailability({
      history,
      listing: listing?.listing,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount: children.length,
      petsCount,
    });
  };

  const LeftContent = () => {
    switch (variant) {
      case "customize":
        return (
          <Box className="desktop-vr-customize-basic-info-contents">
            <Box className={"logo"} onClick={() => history.push(PATH_STAYS)}>
              {clientContext.logo}
            </Box>
            <Box className={"desktop-vr-customize-header-details"}>
              <Typography variant={"body1"} tabIndex={0}>
                {title ?? textConstants.CUSTOMIZE}
              </Typography>
              <Typography variant={"body2"}>
                {subtitle ?? textConstants.CUSTOMIZE_SUBTITLE}
              </Typography>
            </Box>
          </Box>
        );
      case "shop":
      default:
        return (
          <Box className="desktop-vr-shop-basic-info-contents">
            <ActionButton
              className="back-to-results"
              message={textConstants.BACK_TO_RESULTS}
              onClick={() => {
                returnToAvailability();
              }}
              defaultStyle="h4r-secondary"
            />
            <Box className="desktop-vr-shop-header-details" tabIndex={0}>
              {listing?.listing && (
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: "8px" }}
                >
                  <HotelName
                    name={listing.listing.content.name}
                    isSkeleton={vacationRentalShopCallInProgress}
                  />
                  <HomeSummary
                    city={city}
                    starRating={starRating}
                    userRating={userRating}
                  />
                </Box>
              )}
            </Box>
          </Box>
        );
    }
  };

  return (
    <Header
      className="desktop-vr-rewards-header-root"
      left={<LeftContent />}
      right={
        <Box className="desktop-vr-shop-rewards-account-contents">
          <RewardsAccountSelection
            className={clsx("b2b", {
              "hide-balance-border": isTravelWalletCreditsExperiment,
            })}
            popoverClassName="b2b"
          />
        </Box>
      }
    />
  );
};
