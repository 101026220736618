import {
  AirportRegion,
  Currency,
  IdEnum,
  IIdBase,
  IResult,
} from "../common/interfaces";

export type OpaqueProperties = string;

export type OpaqueValue = any;

export enum BookingCategory {
  FLIGHT = "FLIGHT",
  HOTEL = "HOTEL",
}

export enum TripCategory {
  ONE_WAY = "one_way",
  ROUND_TRIP = "round_trip",
  MULTI_CITY = "multi_city",
}

export interface IIdLodgings {
  // Server returns this object as opaque base64 encoded string.
  // The real type of the decoded object is AppLodgingSelection.
  lodgingSelection: OpaqueValue;
  Id: IdEnum.Lodgings;
}

export interface IIdFlight extends IIdBase {
  code: AirportRegion;
  Id: IdEnum.Flight;
}

export type Severity = ISeverityFatal | ISeverityWarning;

export interface ISeverityBase {
  Severity: SeverityEnum;
}

export enum SeverityEnum {
  Fatal = "Fatal",
  Warning = "Warning",
}

export interface ISeverityFatal extends ISeverityBase {
  Severity: SeverityEnum.Fatal;
}

export interface ISeverityWarning extends ISeverityBase {
  Severity: SeverityEnum.Warning;
}

export enum ScalingFactor {
  X1 = "1x",
  X1Point5 = "1.5x",
  X2 = "2x",
  X3 = "3x",
  X4 = "4x",
}

export const EMAIL_FOR_SIGN_IN = "emailForSignIn";

export const EMAIL_LINK_URL = "emailLinkUrl";

export enum Locale {
  EN_US = "en_US",
}

export enum DateTimeZone {
  AMERICA_TORONTO = "America/Toronto",
}

export interface IUserPreferences {
  locale: Locale;
  currency: Currency;
  timeZone?: DateTimeZone;
}

export interface IPassengerCounts {
  seniorsCount?: number;
  adultsCount: number;
  youthsCount?: number;
  childrenCount: number;
  childrenAges?: number[];
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  infants?: { age: number; inSeat: boolean }[];
  infantsCount?: number;
}

export enum FlightRatingsEnum {
  basic,
  standard,
  enhanced,
  premium,
  luxury,
}

export type ITripTerminus = IResult & {
  id: IIdFlight;
};

export enum FlightSortOption {
  ArrivalTime = "arrivalTime",
  DepartureTime = "departureTime",
  Duration = "duration",
  FareScore = "fareScore",
  Price = "price",
  Stops = "stops",
}

export interface ISortOptions {
  label: string;
  value: FlightSortOption;
}

export enum FlightEntryTypeEnum {
  RECENTLY_SEARCHED_AIR = "recently_searched_air",
  RECENTLY_SEARCH_AUTOCOMPLETE = "recently_searched_autocomplete",
  TRAVEL_SALE_LANDING = "travel_sale_landing",
  TRAVEL_SALE_HOMEPAGE = "travel_sale_homepage",
}
