import { put, select } from "redux-saga/effects";
import { HomesAddMainGuestPostRequest } from "redmond";
import { actions } from "../../actions";
import { IStoreState } from "../../../../reducers/types";
import { getSession, getVacationRentalsPriceQuote } from "../../reducer";
import { addMainGuest } from "../../../../api/v1/book/addMainGuest";
import Logger from "../../../../utils/logger";

export function* addMainGuestSaga({
  emailAddress,
  mainGuest,
}: actions.IAddMainGuest) {
  try {
    const state: IStoreState = yield select();
    const homesPriceQuote = getVacationRentalsPriceQuote(state);
    const session = getSession(state);

    if (!session) {
      throw Error("Session is undefined");
    }

    if (!homesPriceQuote || !homesPriceQuote.orderedProductId) {
      throw Error("No price quote order product id avaialble");
    }

    if (!mainGuest) {
      throw Error("No guest specified");
    }

    const payload: HomesAddMainGuestPostRequest = {
      emailAddress,
      mainGuest,
      orderedProductId: homesPriceQuote.orderedProductId,
      paymentSessionToken: session,
    };
    const { orderedProductId } = yield addMainGuest(payload);

    if (orderedProductId) {
      yield put(actions.addMainGuestCallStateSuccess({}));
    } else {
      yield put(actions.addMainGuestCallStateFailed({}));
    }
  } catch (e) {
    Logger.debug(e);
    yield put(actions.addMainGuestCallStateFailed({}));
  }
}
