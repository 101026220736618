import { CreditDetail, TravelWalletCredit, TravelWalletOffer } from "./wallet";

export interface LandingPageTakeovers {
  takeovers: Takeover[];
  walletTakeover: Takeover;
  statusTakeover?: Takeover;
}

export interface MultipleExpiringHotelPriceFreezesTakeover {
  Takeover: TakeoverEnum.MultipleExpiringHotelPriceFreezesTakeover;
}

export interface MultipleExpiringPriceFreezesTakeover {
  Takeover: TakeoverEnum.MultipleExpiringPriceFreezesTakeover;
}

export interface SingleFlightExpiringPriceFreezeTakeover {
  priceFreezeId: string;
  destinationCity: string;
  expiresAt: string;
  Takeover: TakeoverEnum.SingleExpiringPriceFreezeTakeover;
}

export interface SingleExpiringHotelPriceFreezeTakeover {
  priceFreezeId: string;
  hotelName: string;
  expiresAt: string;
  Takeover: TakeoverEnum.SingleExpiringHotelPriceFreezeTakeover;
}

export interface TravelWalletTakeover {
  Takeover: TakeoverEnum.TravelWalletTakeover;
  offerCount: number;
  creditBreakdown: CreditDetail[];
  bestOffer?: TravelWalletOffer;
  credit?: TravelWalletCredit;
}

export interface StatusTakeover {
  Takeover: TakeoverEnum.StatusTakeover;
  message: string;
}

export type Takeover =
  | MultipleExpiringPriceFreezesTakeover
  | MultipleExpiringHotelPriceFreezesTakeover
  | SingleFlightExpiringPriceFreezeTakeover
  | SingleExpiringHotelPriceFreezeTakeover
  | TravelWalletTakeover
  | StatusTakeover;

export enum TakeoverEnum {
  MultipleExpiringPriceFreezesTakeover = "MultipleExpiringPriceFreezesTakeover",
  TravelWalletTakeover = "TravelWalletTakeover",
  StatusTakeover = "StatusTakeover",
  MultipleExpiringHotelPriceFreezesTakeover = "MultipleExpiringHotelPriceFreezesTakeover",
  SingleExpiringPriceFreezeTakeover = "SingleExpiringPriceFreezeTakeover",
  SingleExpiringHotelPriceFreezeTakeover = "SingleExpiringHotelPriceFreezeTakeover",
}
