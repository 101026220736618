import { UnavailableReasonEnum } from "redmond/apis/tysons/vacation-rentals";

export interface UnavailableReasonText {
  title: string;
  subtitle: string;
}

export const isDateSelectionErrorType = (
  reason: UnavailableReasonEnum | undefined
): boolean => {
  return !isOccupancyErrorType(reason);
};

export const isOccupancyErrorType = (
  reason: UnavailableReasonEnum | undefined
): boolean => {
  return (
    !!reason &&
    (reason === UnavailableReasonEnum.OccupancyViolation ||
      reason === UnavailableReasonEnum.PetPolicyViolation)
  );
};

export const getUnavailableReasonText = (
  reason: UnavailableReasonEnum | undefined
): UnavailableReasonText => {
  let unavailableReasonText = {
    title: "Listing is not available.",
    subtitle: "Please select a different set of dates",
  };

  switch (reason) {
    case UnavailableReasonEnum.MinStayViolation:
      unavailableReasonText = {
        title: "Minimum nights stay not met.",
        subtitle: "Try a longer stay to continue.",
      };
      break;
    case UnavailableReasonEnum.MaxStayViolation:
      unavailableReasonText = {
        title: "Max nights stay exceeded.",
        subtitle: "Try a shorter stay to continue.",
      };
      break;
    case UnavailableReasonEnum.OccupancyViolation:
      unavailableReasonText = {
        title: "Guest limit exceeded.",
        subtitle: "Reduce the number of guests to continue.",
      };
      break;
    case UnavailableReasonEnum.PetPolicyViolation:
      unavailableReasonText = {
        title: "Pets not allowed.",
        subtitle: "Please remove pets to continue.",
      };
      break;
    case UnavailableReasonEnum.CheckInNotAllowed:
      unavailableReasonText = {
        title: "Check-in date is not allowed.",
        subtitle: "Please select a different check-in date to continue.",
      };
      break;
    case UnavailableReasonEnum.CheckOutNotAllowed:
      unavailableReasonText = {
        title: "Check-out date is not allowed.",
        subtitle: "Please select a different check-out date to continue.",
      };
      break;
    case UnavailableReasonEnum.BlockedDates:
      unavailableReasonText = {
        title: "The dates you have selected are not available.",
        subtitle: "Please try a different set of dates.",
      };
      break;
    case UnavailableReasonEnum.MinBookingWindowViolation:
      unavailableReasonText = {
        title: "Listing not available on short notice.",
        subtitle: "Please select a later check-in date.",
      };
      break;
    default:
      break;
  }
  return unavailableReasonText;
};
