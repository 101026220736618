export interface EngagementRequest {
  event: any;
}

export enum EngagementResultEnum {
  Failure = "Failure",
  Success = "Success",
}

export interface EngagementResponse {
  result: EngagementResultEnum;
}
