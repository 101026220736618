import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { PolicyEnum, Prices } from "redmond";
import { VacationRentalShopConnectorProps } from "./container";
import {
  ActionButton,
  BannerSeverity,
  getPricesWithComma,
  getRewardsString,
  getTotalPriceText,
  Icon,
  IconName,
  NotificationBanner,
  pluralize,
} from "halifax";
import "./styles.scss";
import { ConnectedDateSelector } from "../DateSelector/container";
import { goToCheckout } from "../../utils/queryStringHelpers";
import { ConnectedOccupancySelector } from "../OccupancySelector/container";
import { IDateSelectorVariant } from "../DateSelector/component";
import { IMobileDateSelectorVariant } from "../MobileDateSelector/component";
import { ConnectedMobileDateSelector } from "../MobileDateSelector/container";
import {
  AvailabilityResultEnum,
  FundingEnum,
  Price,
} from "redmond/apis/tysons/vacation-rentals";
import { VacationRentalShopCallState } from "../../reducer";
import { Skeleton } from "@material-ui/lab";
import {
  getUnavailableReasonText,
  isDateSelectionErrorType,
  isOccupancyErrorType,
} from "./constants";
import { IOccupanySelectionVariant } from "../OccupancySelector/component";

// Display context of the ShopCta
export enum IShopCtaVariant {
  DESKTOP = "DESKTOP",
  DESKTOP_MAP = "DESKTOP_MAP",
  MOBILE_CTA_BUTTON = "MOBILE_CTA_BUTTON",
  PRICING_ONLY = "PRICING_ONLY",
}

export interface IShopCtaProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {
  variant: IShopCtaVariant;
}

export const ShopCta = (props: IShopCtaProps) => {
  const {
    variant,
    selectedListing,
    selectedRewardsAccount,
    lengthOfStay,
    history,
    shopCallState,
  } = props;

  if (!selectedListing) {
    return null;
  }

  const { availability, listing } = selectedListing;
  const maxOccupancy = listing.terms?.maxOccupancy;

  if (availability.AvailabilityResult === AvailabilityResultEnum.Unavailable) {
    const failureReason = availability?.reasons?.[0]?.reason?.Reason;

    const unavailableReasonText = getUnavailableReasonText(failureReason);

    const isDateSelectionError = isDateSelectionErrorType(failureReason);
    const isOccupanySelectionError = isOccupancyErrorType(failureReason);

    const renderUnavailableReason = () => {
      return (
        <Box display="flex" flexDirection="column" style={{ gap: "20px" }}>
          <Typography
            variant="h5"
            style={{ fontWeight: 600, color: "#141414" }}
          >
            {unavailableReasonText.title}
          </Typography>

          <NotificationBanner
            severity={BannerSeverity.ERROR}
            content={
              <Box
                display="flex"
                alignItems={"center"}
                marginY="5px"
                style={{ gap: "8px" }}
              >
                <Icon name={IconName.ErrorAlert} />
                <Typography style={{ fontSize: "12px" }}>
                  {unavailableReasonText.subtitle}
                </Typography>
              </Box>
            }
          />
        </Box>
      );
    };

    return (
      <Box className="vacation-rental-shop-cta">
        {variant === IShopCtaVariant.DESKTOP && (
          <Box display="flex" flexDirection="column" padding="20px">
            {renderUnavailableReason()}
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "12px" }}
              paddingTop={"20px"}
            >
              <ConnectedDateSelector
                variant={IDateSelectorVariant.RANGE}
                showErrorStyles={isDateSelectionError}
              />
              <ConnectedOccupancySelector
                variant={IOccupanySelectionVariant.RANGE}
                maxOccupancy={maxOccupancy}
                showErrorStyles={isOccupanySelectionError}
              />
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" style={{ gap: "12px" }}>
              {isOccupanySelectionError && (
                <ConnectedOccupancySelector
                  variant={IOccupanySelectionVariant.BUTTON}
                  maxOccupancy={maxOccupancy}
                  showErrorStyles={isOccupanySelectionError}
                />
              )}
              {isDateSelectionError && (
                <ConnectedDateSelector
                  variant={IDateSelectorVariant.BUTTON}
                  showErrorStyles={isDateSelectionError}
                />
              )}
            </Box>
          </Box>
        )}

        {variant === IShopCtaVariant.DESKTOP_MAP && (
          <>
            {isDateSelectionError && (
              <ConnectedDateSelector variant={IDateSelectorVariant.BUTTON} />
            )}
            {isOccupanySelectionError && (
              <ConnectedOccupancySelector
                variant={IOccupanySelectionVariant.BUTTON}
                maxOccupancy={maxOccupancy}
              />
            )}
          </>
        )}

        {variant === IShopCtaVariant.MOBILE_CTA_BUTTON && (
          <Box
            paddingLeft="15px"
            paddingRight="15px"
            paddingBottom="25px"
            style={{ backgroundColor: "white" }}
          >
            {isDateSelectionError && (
              <ConnectedMobileDateSelector
                variant={IMobileDateSelectorVariant.BUTTON}
              />
            )}

            {isOccupanySelectionError && (
              <ConnectedOccupancySelector
                variant={IOccupanySelectionVariant.BUTTON}
                maxOccupancy={maxOccupancy}
              />
            )}
          </Box>
        )}

        {variant === IShopCtaVariant.PRICING_ONLY && renderUnavailableReason()}
      </Box>
    );
  }

  const { rate } = availability;

  const { price, cancellationPolicy } = rate || {};

  const isSkeleton = shopCallState === VacationRentalShopCallState.InProcess;

  const { nightly, total, rentTotal, taxes, fees, discounts } = price as Price;

  const formatAmountWithCurrency = (
    amount: number,
    currencySymbol: string = ""
  ) => {
    return formatPrice({
      fiat: {
        value: amount,
        currencySymbol,
        currencyCode: "",
      },
      rewards: {},
    });
  };

  const vacationRentalPriceFormatter = (price: number): string =>
    Math.round(price).toString();

  const formatPrice = (price: Prices) => {
    return getPricesWithComma(
      getTotalPriceText({
        price: price?.fiat,
        priceFormatter: vacationRentalPriceFormatter,
      })
    );
  };

  const taxesTotal = taxes
    .map((x) => x.amount?.fiat?.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const feesTotal = fees
    .map((x) => x.amount?.fiat?.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const taxesAndFeesTotal = taxesTotal + feesTotal;

  const discountsTotal =
    discounts
      ?.map((discount) => {
        const hopperFunded =
          discount.funding.Funding === FundingEnum.HopperFunded ||
          discount.funding.Funding === FundingEnum.JointFunded
            ? +discount.funding.hopperFunded.amount.fiat.value || 0
            : 0;
        const hostFunded =
          discount.funding.Funding === FundingEnum.HostFunded ||
          discount.funding.Funding === FundingEnum.JointFunded
            ? +discount.funding.hostFunded.amount.fiat.value || 0
            : 0;
        return hopperFunded + hostFunded;
      })
      .reduce((partialSum, a) => partialSum + a, 0) || 0;

  const nightlyPriceDisplay = formatPrice(nightly);
  const nightlyPriceBeforeDiscountsDisplay =
    discountsTotal > 0 && !!lengthOfStay
      ? formatPrice({
          fiat: {
            value: nightly.fiat.value + discountsTotal / lengthOfStay,
            currencySymbol: nightly.fiat.currencySymbol,
            currencyCode: nightly.fiat.currencyCode,
          },
          rewards: {},
        })
      : null;
  const rentPriceDisplay = formatPrice(rentTotal);
  const totalPriceDisplay = formatPrice(total);

  const rewardsPriceDisplay = selectedRewardsAccount
    ? getRewardsString(total.rewards[selectedRewardsAccount], true)
    : null;

  const totalTaxesAndFeesDisplay = formatAmountWithCurrency(
    taxesAndFeesTotal,
    total.fiat?.currencySymbol
  );

  const renderPricingDetails = () => {
    if (isSkeleton) {
      return (
        <Box>
          <Skeleton width="150px" height="20px" />
          <Skeleton width="100px" height="20px" />
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="baseline"
          style={{ gap: "4px" }}
        >
          {!!nightlyPriceBeforeDiscountsDisplay && (
            <Typography
              style={{
                fontSize: "12px",
                color: "#CC2427",
                textDecoration: "line-through",
              }}
            >
              {nightlyPriceBeforeDiscountsDisplay}
            </Typography>
          )}
          <Typography
            style={{ fontWeight: 600, fontSize: "16px", color: "#141414" }}
          >
            {nightlyPriceDisplay}
            <span style={{ fontSize: "12px" }}> per night</span>
          </Typography>
        </Box>

        {!!lengthOfStay && (
          <Typography style={{ fontSize: "12px", color: "#141414" }}>
            {totalPriceDisplay} for {lengthOfStay}{" "}
            {pluralize(lengthOfStay, "night", "nights")}
            {rewardsPriceDisplay && ` / ${rewardsPriceDisplay}`}
          </Typography>
        )}

        <Typography
          style={{
            fontSize: "12px",
            color: "#676868",
            marginTop: "10px",
          }}
        >
          Total includes taxes and fees.
        </Typography>
        <Box display="flex" marginY="10px">
          {renderFreeCancellation()}
        </Box>
      </Box>
    );
  };

  const renderFreeCancellation = () => {
    return (
      cancellationPolicy?.policy.Policy === PolicyEnum.FullyRefundable && (
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          bgcolor="#F0F5F3"
          padding="10px"
          borderRadius="4px"
        >
          <Icon
            width="16px"
            height="16px"
            className="icon-fill-green"
            name={IconName.CheckCircle}
          />
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#0E6018",
              marginLeft: "5px",
            }}
          >
            {cancellationPolicy?.policy?.primaryText || "Free cancellation"}
          </Typography>
        </Box>
      )
    );
  };

  const renderPriceBreakdown = () => {
    return (
      <>
        <Typography variant="h6" style={{ color: "#141414" }}>
          Checkout breakdown
        </Typography>
        <Divider style={{ height: "0.5px" }} />
        <Box display="flex" justifyContent="space-between">
          {lengthOfStay && (
            <Typography style={{ fontSize: "14px", color: "#676868" }}>
              Vacation rental x {lengthOfStay}{" "}
              {pluralize(lengthOfStay, "night", "nights")}
            </Typography>
          )}
          <Typography style={{ fontSize: "14px", color: "#676868" }}>
            {rentPriceDisplay}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography style={{ fontSize: "14px", color: "#676868" }}>
            Taxes and fees
          </Typography>
          <Typography style={{ fontSize: "14px", color: "#676868" }}>
            {totalTaxesAndFeesDisplay}
          </Typography>
        </Box>
        <Divider style={{ height: "0.5px" }} />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" style={{ color: "#141414" }}>
            Total
          </Typography>

          <Typography variant="h6" style={{ color: "#141414" }}>
            {totalPriceDisplay}
            {rewardsPriceDisplay && (
              <span style={{ fontWeight: 400, color: "#676868" }}>
                {` / ${rewardsPriceDisplay}`}
              </span>
            )}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box className="vacation-rental-shop-cta">
      {variant === IShopCtaVariant.DESKTOP && (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            style={{ gap: "5px" }}
            alignItems="start"
          >
            {renderPricingDetails()}
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "12px", width: "100%" }}
            >
              <ConnectedDateSelector variant={IDateSelectorVariant.RANGE} />
              <ConnectedOccupancySelector
                variant={IOccupanySelectionVariant.RANGE}
                maxOccupancy={maxOccupancy}
              />
            </Box>
          </Box>
          {!isSkeleton && (
            <>
              <Divider />
              <Box
                display="flex"
                flexDirection="column"
                padding="20px"
                style={{ gap: "12px" }}
              >
                {renderPriceBreakdown()}
                <ActionButton
                  className="b2b cta-button-outer"
                  buttonClassName="cta-button-inner"
                  onClick={() => goToCheckout({ history })}
                  message={
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: "white",
                      }}
                    >
                      Continue for{" "}
                      <span style={{ fontWeight: "600" }}>
                        {nightlyPriceDisplay} per night
                      </span>
                    </Typography>
                  }
                />
              </Box>
            </>
          )}
        </Box>
      )}

      {variant === IShopCtaVariant.DESKTOP_MAP && (
        <Box display="flex" flexDirection="column" style={{ gap: "12px" }}>
          {renderPricingDetails()}
          <Divider style={{ height: "0.5px" }} />
          {renderPriceBreakdown()}
          <ActionButton
            className="b2b cta-button-outer"
            buttonClassName="cta-button-inner"
            onClick={() => goToCheckout({ history })}
            message={
              <Typography
                style={{
                  fontSize: "20px",
                  color: "white",
                }}
              >
                Continue for{" "}
                <span style={{ fontWeight: "600" }}>
                  {nightlyPriceDisplay} per night
                </span>
              </Typography>
            }
          />
        </Box>
      )}

      {variant === IShopCtaVariant.MOBILE_CTA_BUTTON && (
        <Box
          paddingLeft="15px"
          paddingRight="15px"
          paddingBottom="25px"
          style={{ backgroundColor: "white" }}
        >
          <ActionButton
            className="b2b cta-button-outer"
            buttonClassName="cta-button-inner"
            onClick={() => goToCheckout({ history })}
            message={
              <Typography
                style={{
                  fontSize: "20px",
                  color: "white",
                }}
              >
                Continue for{" "}
                <span style={{ fontWeight: "600" }}>
                  {nightlyPriceDisplay} per night
                </span>
              </Typography>
            }
          />
        </Box>
      )}

      {variant === IShopCtaVariant.PRICING_ONLY && renderPricingDetails()}
    </Box>
  );
};
