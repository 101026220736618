import { TripDetails } from "./trip-details";
import { Amount, Prices, PassengerTypes } from "../../common";
import { PassengerType, TripPricingSummary } from "./payment-machine";
import { TripSummaryContext } from "./trip-summary";
import {
  PriceDropProtection,
  TripSummary,
  PredictionResultResponse,
  OfferWithSuggested,
} from "./shop-summary";
import { FareDetails, TripSlice } from "./index";

export interface Cap {
  value: Amount;
}

export type ID = string;

export interface Offer {
  id: ID;
  perPaxAmount: Prices;
  cap: Cap;
  timeToLive: TimeToLive;
  totalAmount: Prices;
  passengers: { [key: string]: number };
  seatedPassengers: { [key: string]: number };
  voidWindowAvailabilityEnd: VoidWindowAvailabilityEnd;
}

export type VoidWindowAvailabilityEnd = Unavailable | Until;

export enum VoidWindowAvailabilityEndEnum {
  Unavailable = "Unavailable",
  Until = "Until",
}

export interface Unavailable {
  VoidWindowAvailabilityEnd: VoidWindowAvailabilityEndEnum.Unavailable;
}

export interface Until {
  time: string,
  VoidWindowAvailabilityEnd: VoidWindowAvailabilityEndEnum.Until;
}

export interface Passengers {
  [PassengerTypes.Adult]?: number;
  [PassengerTypes.Child]?: number;
  [PassengerTypes.InfantInLap]?: number;
  [PassengerTypes.InfantInSeat]?: number;
}

export interface TimeToLive {
  inDays?: number;
  inHours?: number;
  inSeconds: number;
}

export interface Actions {
  purchaseTime?: string;
  archiveTime?: string;
  transferTime?: string;
}

export interface PriceFreeze {
  actions: Actions;
  id: ID;
  externalId: string | { value: string };
  offer: Offer;
  expiresAt: string;
}
export interface PriceFreezeTripDetails {
  fareDetails: FareDetails;
  slices: TripSlice[];
}

export interface FetchOfferRequest {
  offerId: ID;
}

export interface FetchOfferResponse {
  offer: Offer;
}

export type PriceFreezeItinerary = MultiItinerary | SingleItinerary;

export interface MultiItinerary {
  tripIds: string[];
  Itinerary: PriceFreezeItineraryEnum.MultiItinerary;
}

export interface SingleItinerary {
  tripId: string;
  fareId: string;
  Itinerary: PriceFreezeItineraryEnum.SingleItinerary;
}

export enum PriceFreezeItineraryEnum {
  MultiItinerary = "MultiItinerary",
  SingleItinerary = "SingleItinerary",
}

export interface ListPriceFreezeRequest {
  ListPriceFreezeRequest: CurrentPriceFreezesEnum;
}

export enum CurrentPriceFreezesEnum {
  CurrentPriceFreezes = "CurrentPriceFreezes",
}

export interface ListPriceFreezeResponse {
  priceFreezes: PriceFreezeView[];
}

export interface FarePricing {
  baseAmount: Prices;
  taxAmount: Prices;
  additionalMargin?: Prices;
  totalAmount: Prices;
}

export interface PassengerPricing {
  paxType: PassengerType;
  pricing: FarePricing;
  quantity: number;
}

export interface PriceFreezeViewFlightFare {
  id: string;
  paxPricings: PassengerPricing[];
  totalPricing?: FarePricing;
}

export interface PriceFreezeViewFlight {
  slices: TripSlice[];
  fareDetails: PriceFreezeViewFlightFare[];
}

export type RefreezeOffer =
  | CurrentOfferActive
  | NoOfferAvailable
  | PreviousTripUnavailable
  | RefreezeTrip;

export interface CurrentOfferActive {
  RefreezeOffer: RefreezeOfferEnum.CurrentOfferActive;
}

export interface NoOfferAvailable {
  RefreezeOffer: RefreezeOfferEnum.NoOfferAvailable;
}

type PredictionResult = PredictionResultResponse;

export interface PreviousTripUnavailable {
  tripSummaries: TripSummary[];
  prediction: PredictionResult;
  priceFreezeOffer: OfferWithSuggested;
  RefreezeOffer: RefreezeOfferEnum.PreviousTripUnavailable;
}

export interface RefreezeTrip {
  tripSummaries: TripSummary[];
  prediction: PredictionResult;
  priceFreezeOffer: OfferWithSuggested;
  RefreezeOffer: RefreezeOfferEnum.RefreezeTrip;
}

export enum RefreezeOfferEnum {
  CurrentOfferActive = "CurrentOfferActive",
  NoOfferAvailable = "NoOfferAvailable",
  PreviousTripUnavailable = "PreviousTripUnavailable",
  RefreezeTrip = "RefreezeTrip",
}

export interface BookingDetails {
  tripId: string;
  fareId: string;
}

export interface PriceFreezeView {
  priceFreeze: PriceFreeze;
  status: {
    Status: PriceFreezeStatusEnum;
  };
  tripDetails: TripDetails;
  frozenFlight: PriceFreezeViewFlight;
  frozenFare: PriceFreezeFrozenFare;
  refreezeOffer?: RefreezeOffer;
  bookingDetails: BookingDetails;
}

export interface PriceFreezeFrozenFare {
  id: string;
  paxPricings: FrozenPricePaxPricing[];
  totalPricing?: FrozenFarePricing;
}

export interface FrozenPricePaxPricing {
  paxType: PassengerTypes;
  pricing: FrozenFarePricing;
  quantity: number;
}

export enum CurrentFareVersusCapEnum {
  FareUnderCap = "FareUnderCap",
  FareOverCap = "FareOverCap",
}

export interface FareOverCap {
  CurrentFareVersusCap: CurrentFareVersusCapEnum.FareOverCap;
}

export interface FareUnderCap {
  CurrentFareVersusCap: CurrentFareVersusCapEnum.FareUnderCap;
}

export type CurrentFareVersusCap = FareOverCap | FareUnderCap;

export interface PriceFreezeDefaultFareDetails {
  baseAmount: Prices;
  taxAmount: Prices;
  totalAmount: Prices;
}

export interface FrozenFarePricing {
  originalAmount: Prices;
  currentAmount: Prices;
  increaseAmount: Prices;
  versusCap: CurrentFareVersusCap;
  savingsAmount: Prices;
  chargeAmount: Prices;
}

export enum PriceFreezeStatusEnum {
  IsActive = "IsActive",
  IsExpired = "IsExpired",
}

export interface GetPriceFreezeResponse {
  priceFreeze: PriceFreezeView;
  tripContext: TripSummaryContext;
  priceDropProtection?: PriceDropProtection;
}

export type GetPriceFreezeRequest = ByPriceFreezeId;

export interface ByPriceFreezeId {
  id: ID;
  GetPriceFreezeRequest: GetPriceFreezeRequestEnum;
}

export enum GetPriceFreezeRequestEnum {
  ByPriceFreezeId = "ByPriceFreezeId",
}

export interface RefundPriceFreezeRequest {
  id: ID;
}

export type RefundPriceFreezeResponse =
  | RefundPriceFreezeSuccessEnum
  | RefundFailureEnum;

export enum RefundPriceFreezeSuccessEnum {
  Success = "Success",
}

export enum RefundFailureEnum {
  NotFound = "NotFound",
  AlreadyExchanged = "AlreadyExchanged",
  Unknown = "Unknown",
  AlreadyExercised = "AlreadyExercised",
  AlreadyRefunded = "AlreadyRefunded",
}

export interface FlightsListEntry {
  'PriceFreezeEntryPoint': PriceFreezeEntryPointEnum;
}

export interface PriceFreezeBannerEntry {
  'PriceFreezeEntryPoint': PriceFreezeEntryPointEnum;
}

export interface PredictionPriceFreezeEntry {
  'PriceFreezeEntryPoint': PriceFreezeEntryPointEnum;
}

/**
 * @type PriceFreezeEntryPoint
 * The entry point use to surface the PF offer
 * @export
 */
export type PriceFreezeEntryPoint = { PriceFreezeEntryPoint: 'FlightsListEntry' } & FlightsListEntry | { PriceFreezeEntryPoint: 'PredictionPriceFreezeEntry' } & PredictionPriceFreezeEntry | { PriceFreezeEntryPoint: 'PriceFreezeBannerEntry' } & PriceFreezeBannerEntry;

export const PriceFreezeEntryPointEnum = {
    FlightsListEntry: 'FlightsListEntry',
    PredictionPriceFreezeEntry: 'PredictionPriceFreezeEntry',
    PriceFreezeBannerEntry: 'PriceFreezeBannerEntry'
} as const;

export type PriceFreezeEntryPointEnum = typeof PriceFreezeEntryPointEnum[keyof typeof PriceFreezeEntryPointEnum];

export interface PriceFreezeOfferDataRequest {
  departureDate: string;
  tripId: string;
  fareId: string;
  entryPoint?: PriceFreezeEntryPoint;
}

export interface PriceFreezeOfferDataResponse {
  offerData: PriceFreezeOfferDataWithRewards[];
}

export interface PriceFreezeOfferDataWithRewards {
  secondsTtl: number;
  cap: Prices;
  price: Prices;
  modelName: string;
}

export interface GenerateCustomOfferRequest {
  offerData: PriceFreezeOfferDataWithRewards;
  tripId: string;
  fareId: string;
  passengers: { [key: string]: number };
}

export type GenerateCustomOfferResponse =
  | CustomOfferAvailable
  | CustomOfferInvalidOfferData
  | CustomOfferNotAvailable;

export interface CustomOfferAvailable {
  offer: Offer;
  GenerateCustomOfferResponse: GenerateCustomOfferResponseEnum.Available;
}

export interface CustomOfferInvalidOfferData {
  offerData: PriceFreezeOfferDataWithRewards;
  GenerateCustomOfferResponse: GenerateCustomOfferResponseEnum.InvalidOfferData;
}

export interface CustomOfferNotAvailable {
  GenerateCustomOfferResponse: GenerateCustomOfferResponseEnum.NotAvailable;
}

export enum GenerateCustomOfferResponseEnum {
  Available = "Available",
  InvalidOfferData = "InvalidOfferData",
  NotAvailable = "NotAvailable",
}

export interface QuoteTokenKey {
  userId: string;
  sessionId: string;
}

export interface BrooklynTokenKey {
  userId: string;
  client: string;
}

export interface StartFareQuoteResult {
  tokenKey: QuoteTokenKey;
}

export interface ScheduleFareQuoteRequest {
  tokenKey: QuoteTokenKey;
  passengerCountByType: { [key: string]: number };
  tripId: string;
  fareId: string;
}

export interface PollFareQuoteRequest {
  tokenKey: QuoteTokenKey;
}

export enum PollFareQuoteResultEnum {
  Pending = "Pending",
  Error = "Error",
  Success = "Success",
}

export interface PollFareQuoteResultError {
  PollFareQuoteResult: PollFareQuoteResultEnum.Error;
}

export interface PollFareQuoteResultPending {
  PollFareQuoteResult: PollFareQuoteResultEnum.Pending;
}

export type PollFareQuoteOutcome = NoQuote | Quoted;

export enum PollFareQuoteOutcomeEnum {
  NoQuote = "NoQuote",
  Quoted = "Quoted",
}

type BookingError = ErrorCode | NoAvailability | OfferInvalid;

export enum FareQuoteBookingErrorEnum {
  OfferInvalid = "OfferInvalid",
  NoAvailability = "NoAvailability",
  ErrorCode = "ErrorCode",
}

interface ErrorCode {
  code: string;
  cause?: string;
  message?: string;
  BookingError: FareQuoteBookingErrorEnum.ErrorCode;
}

interface NoAvailability {
  BookingError: FareQuoteBookingErrorEnum.NoAvailability;
}

interface OfferInvalid {
  message: string;
  BookingError: FareQuoteBookingErrorEnum.OfferInvalid;
}

export interface NoQuote {
  tokenKey: BrooklynTokenKey;
  errors: BookingError[];
  Outcome: PollFareQuoteOutcomeEnum.NoQuote;
}

export interface Quoted {
  tokenKey: BrooklynTokenKey;
  tripPricing: TripPricingSummary;
  Outcome: PollFareQuoteOutcomeEnum.Quoted;
}

export interface PollFareQuoteResultSuccess {
  tokenKey: QuoteTokenKey;
  outcome: PollFareQuoteOutcome;
  PollFareQuoteResult: PollFareQuoteResultEnum.Success;
}

export type PollFareQuoteResult =
  | PollFareQuoteResultError
  | PollFareQuoteResultPending
  | PollFareQuoteResultSuccess;
