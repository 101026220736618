import { ListingId, ListingSearchResult, VacationRentalCalendarResponse, HotelDetailsEntrySourceEnum } from "redmond";
import * as actionTypes from "./constants";
import * as H from "history";
import { VacationRentalShopCallError } from "../reducer";

export interface IFetchVacationRentalShop {
  type: actionTypes.FETCH_VACATION_RENTAL_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallVRAvailability?: boolean;
    fetchListingCalendar?: boolean;
  };
}

export const fetchVacationRentalShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallVRAvailability?: boolean;
    fetchListingCalendar?: boolean;
  }
): IFetchVacationRentalShop => ({
  type: actionTypes.FETCH_VACATION_RENTAL_SHOP,
  history,
  options,
});

export interface ISetVacationRentalShopCallStateFailed {
  type: actionTypes.SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED;
  vrShopCallError: VacationRentalShopCallError;
}

export const setVacationRentalShopCallStateFailed = (
  vrShopCallError: VacationRentalShopCallError
): ISetVacationRentalShopCallStateFailed => ({
  type: actionTypes.SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED,
  vrShopCallError,
});
export interface ISelectHome {
  type: actionTypes.SELECT_HOME;
  home: ListingSearchResult;
}

export const selectHome = (home: ListingSearchResult): ISelectHome => ({
  type: actionTypes.SELECT_HOME,
  home,
});

export interface ISetSelectedListingId {
  type: actionTypes.SET_SELECTED_LISTING_ID;
  id: ListingId;
}

export const setSelectedListingId = (id: ListingId): ISetSelectedListingId => ({
  type: actionTypes.SET_SELECTED_LISTING_ID,
  id,
});

export interface ISetListingCalendar {
  type: actionTypes.SET_LISTING_CALENDAR;
  calendar?: VacationRentalCalendarResponse;
}

export const setListingCalendar = (
  calendar?: VacationRentalCalendarResponse
): ISetListingCalendar => ({
  type: actionTypes.SET_LISTING_CALENDAR,
  calendar,
});

export interface ISetNotifyIfShopAndBookPriceDifferFlag {
  type: actionTypes.SET_NOTIFY_IF_SHOP_AND_BOOK_PRICE_DIFFER_FLAG;
  notify: boolean;
}

export interface ISetVacationRentalEntrySource{
  type: actionTypes.SET_VACATION_RENTAL_ENTRY_SOURCE;
  entrySource?: HotelDetailsEntrySourceEnum;
}

export const setVacationRentalEntrySource = (
  entrySource?: HotelDetailsEntrySourceEnum
): ISetVacationRentalEntrySource => ({
  type: actionTypes.SET_VACATION_RENTAL_ENTRY_SOURCE,
  entrySource,
});

export const setNotifyIfShopAndBookPriceDifferFlag = (
  notify: boolean
): ISetNotifyIfShopAndBookPriceDifferFlag => ({
  type: actionTypes.SET_NOTIFY_IF_SHOP_AND_BOOK_PRICE_DIFFER_FLAG,
  notify,
});

export interface ISetVacationRentalSortOrder{
  type: actionTypes.SET_VACATION_RENTAL_SORT_ORDER;
  sortOrder?: string | undefined;
}

export const setVacationRentalSortOrder = (
  sortOrder: string | undefined
): ISetVacationRentalSortOrder => ({
  type: actionTypes.SET_VACATION_RENTAL_SORT_ORDER,
  sortOrder,
});

export type VacationRentalShopActions =
  | IFetchVacationRentalShop
  | ISetListingCalendar
  | ISetVacationRentalShopCallStateFailed
  | ISelectHome
  | ISetSelectedListingId
  | ISetNotifyIfShopAndBookPriceDifferFlag
  | ISetVacationRentalEntrySource
  | ISetVacationRentalSortOrder;
