import { RewardsPrice, FiatPrice } from "../../common";
import { CorpBusinessWithoutRewardsAccount } from "./businesses";

interface RewardsAccountEarn {
  hotelsMultiplier: number;
  carsMultiplier: number;
  flightsMultiplier: number;
  premierCollectionMultiplier: number;
  homesMultiplier: number;
  experiencesMultiplier: number;
}

export enum CustomerAccountRole {
  Primary = "Primary",
  AuthorizedUser = "Authorized User",
  AccountManager = "Account Manager",
  NonFinancialUser = "Non Financial User",
}

export enum AccountUseType {
  Personal = "Personal",
  Business = "Business",
}

export interface RewardsAccount {
  accountDisplayName: string;
  accountReferenceId: string;
  cardArtUrl: string;
  earn: RewardsAccountEarn;
  isTiered: boolean;
  lastFour: string;
  lastFourVirtualCardNumbers?: string[];
  productDisplayName: string;
  redemptionMin: RewardsPrice;
  redemptionMinCashEquivalent: FiatPrice;
  rewardsBalance: RewardsPrice;
  rewardsCashEquivalent: FiatPrice;
  customerAccountRole?: CustomerAccountRole;
  accountUseType?: AccountUseType;
  allowRewardsRedemption?: boolean;
  businessName?: string;
}

export type PortalAccessResponse = {
  isAuthorized: boolean;
  rewardsAccounts: RewardsAccount[];
  businesses: CorpBusinessWithoutRewardsAccount[];
};

export type RewardsAccountsResponse = RewardsAccount[];

export interface ConvertRewardsToUsdRequest {
  rewardsAmount: number;
  accountReferenceId: string;
}

export type ConvertRewardsToUsdResponse =
  | RewardsResponseErrorCode
  | ConvertRewardsToUsdSuccess;

export interface ConvertRewardsToUsdSuccess {
  value: number;
}

export interface ConvertUsdToAllRewardsRequest {
  amount: number;
}

export interface ConvertUsdToAllRewardsResponse {
  value: { [key: string]: RewardsPrice };
}

export interface ConvertUsdToRewardsRequest {
  amount: number;
  accountReferenceId: string;
}

export type ConvertUsdToRewardsResponse =
  | RewardsResponseErrorCode
  | ConvertUsdToRewardsSuccess;

export interface ConvertUsdToRewardsSuccess {
  value: RewardsPrice;
}

export interface RewardsResponseErrorCode {
  code: string;
  details?: string;
}

export interface EarnRequest {
  account: string;
  travelProduct: TravelProduct;
  amountUsd: number;
  earnOfferDescription?: string;
}

export interface EarnResponse {
  multiplier: number;
  correlatedCurrency: string;
  amount: number;
  isMultiplierPercentage: boolean;
}

export interface EarnItem {
  amountUsd: number;
  earnOfferDescription?: string;
}

export interface EarnRequestV2 {
  account: string;
  earnItems: { [key in TravelProductEnum]?: EarnItem };
}

export interface EarnResponseV2 {
  earnResponses: { [key in TravelProductEnum]?: EarnResponse };
  totalAmount: number;
  correlatedCurrency: string;
}

export type TravelProduct =
  | TravelProductEnum.Hotels
  | TravelProductEnum.Cars
  | TravelProductEnum.Flights
  | TravelProductEnum.PremierCollection
  | TravelProductEnum.Homes
  | TravelProductEnum.LifestyleCollection
  | TravelProductEnum.Packages;

export enum TravelProductEnum {
  Hotels = "Hotels",
  Cars = "Cars",
  Flights = "Flights",
  PremierCollection = "PremierCollection",
  Homes = "Homes",
  LifestyleCollection = "LifestyleCollection",
  Packages = "Packages",
}

export const cardsToShowWithPriorityOnRewardsBanner = [
  "Venture X",
  "Venture",
  "VentureOne",
  "Spark Classic Miles",
  "Spark Miles",
  "Spark Miles Select",
  "Quicksilver",
  "QuicksilverOne",
  "Quicksilver Secured",
  "Savor",
  "SavorOne",
  "Platinum",
  "Secured",
  "Journey",
  "Spark Cash",
  "Spark Cash",
  "Spark Cash Plus",
  "Spark Cash Select",
  "Spark Classic",
  "Spark Pro",
  "Walmart Rewards Card",
  "Capital One Walmart Rewards Card",
  "Capital One Walmart Rewards Plus Card",
  "Spark Pro charge card",
  "Starter",
  "Spark Cash Preferred",
  "Spark Business",
  "Capital One Cash",
].map((card) => card.toLowerCase());

export const cardsToShowLastFourOnRewardsBanner = [
  "Capital One Miles",
  "Spark Business",
  "Capital One Small Business Card",
  "Card",
].map((card) => card.toLowerCase());
