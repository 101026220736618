export enum HotelBookType {
  PRICE_FREEZE_PURCHASE = "pf-purchase",
  PRICE_FREEZE_EXERCISE = "pf-exercise",
  DEFAULT = "default",
}

export const HOTEL_BOOK_TYPE = "hotelBookType";

export const PRICE_FREEZE_ENTRY = "priceFreezeEntry";

export const HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD = 10;
