import {
  AirlineCode,
  CountryCode,
  DateTime,
  PassengerType,
  Prices,
  Uuid,
  BaggageInfoEnum,
  PenaltiesInfoEnum,
} from "../../common";
import { AmenitiesAndUtas } from "../dulles/interfaces";
import { FareScore, FareShelf, TripSlice } from "./trip-summary";
import { Provider } from "../../common/provider";
import { MultiTicketTypeEnum } from "../../trips-module";
import { CorpFareDetails } from "../../capone-corporate";
import { PackageFareDetails } from "../../packages-module";
import type { PriceFreezeOfferDataWithRewards } from "./price-freeze";

export interface ConfigurationId {
  timestamp: DateTime;
}

export interface FareIntent {
  flightType: string;
  notificationType: string;
}

export type Penalty = NoPenalty | NotPossible | PenaltyUnknown | WithFee;

export interface NoPenalty {
  Penalty: PenaltyEnum.NoPenalty;
}

export interface NotPossible {
  Penalty: PenaltyEnum.NotPossible;
}

export interface PenaltyUnknown {
  Penalty: PenaltyEnum.Unknown;
}

export interface WithFee {
  amount: number;
  currency: string;
  Penalty: PenaltyEnum.WithFee;
}

export enum PenaltyEnum {
  NoPenalty = "NoPenalty",
  NotPossible = "NotPossible",
  Unknown = "Unknown",
  WithFee = "WithFee",
}

export type PenaltiesInfo = ExternallyManaged | Known | PenaltiesInfoUnknown;

export interface ExternallyManaged {
  code: string;
  PenaltiesInfo: PenaltiesInfoEnum.ExternallyManaged;
}

export interface Known {
  changePenalty: Penalty;
  cancelPenalty: Penalty;
  PenaltiesInfo: PenaltiesInfoEnum.Known;
}

export interface PenaltiesInfoUnknown {
  PenaltiesInfo: PenaltiesInfoEnum.Unknown;
}

export interface BookingFeeDefinitions {
  origin: BookingFeeDefinition;
  destination?: BookingFeeDefinition;
}

export interface BookingFeeDefinition {
  kind: string;
  code: string;
  location: string;
}
export interface FeeTypeDefinition {
  type: string;
}

export enum FeeType {}

export interface BookingFee {
  definedFor: BookingFeeDefinitions;
  bookingFee: FeeTypeDefinition;
  definedBy: string;
  definedOn: DateTime;
}

export interface FarePricing {
  baseAmount: Prices;
  taxAmount: Prices;
  discountAdded?: {
    discountedPrice: Prices;
    originalPrice: Prices;
  };
  lastTicketDate?: string;
  additionalMargin?: Prices;
  total?: Prices;
}

export interface PassengerPricing {
  paxType: PassengerType;
  pricing: FarePricing;
  quantity: number;
}

export type Source = string;

export interface MorMargin {
  percent: number;
}

export interface AccountCode {
  code: string;
}

export interface FareDetails {
  id: string;
  timestamp: number;
  conversationId: string;
  paxPricings?: PassengerPricing[];
  slices: FareSliceDetails[];
  penaltiesInfo?: PenaltiesInfo;
  refundable?: boolean;
  took?: number;
  restrictions?: Restrictions[];
  fareScore?: FareScore;
  multiTicket: boolean;
  multiTicketType: MultiTicketTypeEnum;
  // For experiment "c1-price-freeze-new-flights-list-entry"
  // Maybe we can move this to the BFF if the experiment is successful
  priceFreezeOffers?: PriceFreezeOfferDataWithRewards[];
  middleOffer?: PriceFreezeOfferDataWithRewards;
}

export interface Restrictions {
  amenitiesAndUtas: AmenitiesAndUtas[];
}

export type BaggageInfo = Allowed | NotAllowed | BaggageInfoUnknown;

export interface Allowed {
  allowance: BaggageAllowance;
  charges: BaggageCharge[];
  BaggageInfo: BaggageInfoEnum.Allowed;
}

export interface NotAllowed {
  BaggageInfo: BaggageInfoEnum.NotAllowed;
}

export interface BaggageInfoUnknown {
  BaggageInfo: BaggageInfoEnum.Unknown;
}

export interface BaggageAllowance {
  descriptions: string[];
  pieces: number;
  maximumMass?: Mass;
}

export interface Mass {
  value: number;
  unit: string;
}

export interface BaggageCharge {
  firstPiece: number;
  amount: number;
  lastPiece: number;
  currency: string;
  descriptions: string[];
  maximumMass?: Mass;
}

export interface FareSegment {
  availableSeats: number;
  baggageInfo: BaggageInfo;
  bookingCode?: string;
  cabinClassName?: string;
  carrierCabinClass?: string;
  code?: string;
  connection?: boolean;
  isSelfTransferLayover?: boolean;
  outgoing: boolean;
  penaltiesInfo: PenaltiesInfo;
  privateFare?: boolean;
  ticketIndex?: number;
  brand?: FareBrand;
  codes: {
    [key: string]: string;
  };
  validatingCarrierCode?: string;
}

export interface FareSlice {
  segments: FareSegment[];
}

export interface FareSliceDetails {
  outgoing: boolean;
  fareShelf?: FareShelf;
  fareDetails: FareSlice;
  amenitiesUtas?: AmenitiesAndUtas;
  source?: Source;
  provider?: Provider;
  penaltiesInfo?: PenaltiesInfo;
  refundable?: boolean;
  took?: number;
  fareScore?: FareScore;
  morMargin?: MorMargin;
  accountCode?: AccountCode;
  paxPricings?: PassengerPricing[];
}

export interface SabreBrand {
  brandId: string;
  brandName?: string;
}

export interface TravelportBrand {
  brandId: string;
  brandName?: string;
  brandTier?: number;
}

export interface GdxBrand {
  brandId: string;
  brandName?: string;
}

export interface AmadeusBrand {
  brandId: string;
  brandName?: string;
}

export interface TravelFusionBrand {
  brandId: string;
  brandName?: string;
}

export interface FareBrand {
  sabreFareBrand?: SabreBrand;
  travelportFareBrand?: TravelportBrand;
  gdxFareBrand?: GdxBrand;
  amadeusFareBrand?: AmadeusBrand;
  travelFusionFareBrand?: TravelFusionBrand;
}

export interface TripDetails {
  id: Uuid;
  slices: TripSlice[];
  validatingCarrierCode?: AirlineCode;
  fareDetails: (FareDetails | CorpFareDetails | PackageFareDetails)[];
  shopId?: Uuid;
  configurationId?: ConfigurationId;
  fareIntent?: FareIntent;
  pointOfSaleCountryCode: CountryCode;
  tripDescription: string;
}

export interface ISelectedTrip {
  tripId: string | null;
  outgoingSliceId: string | null;
  outgoingFareId: string | null;
  outgoingFareRating?: number;
  returnSliceId?: string | null;
  returnFareId?: string | null;
  returnFareRating?: number;
}

export interface LayoverDetails {
  airlineCode: string;
  originCode: string;
  destinationCode: string;
  originCity: string;
  destinationCity: string;
  airlineName: string;
}

export function getCancelPenalty(fare: FareDetails) {
  const penaltiesInfo = fare.penaltiesInfo;
  if (penaltiesInfo?.PenaltiesInfo === PenaltiesInfoEnum.Known) {
    return penaltiesInfo.cancelPenalty;
  }

  return undefined;
}

export function getChangePenalty(fare: FareDetails) {
  const penaltiesInfo = fare.penaltiesInfo;
  if (penaltiesInfo?.PenaltiesInfo === PenaltiesInfoEnum.Known) {
    return penaltiesInfo.changePenalty;
  }

  return undefined;
}
