import React, { CSSProperties } from "react";
import { RouteComponentProps } from "react-router";
import { PhotoGalleryConnectorProps } from "./container";
import { useEffect, useState } from "react";
import { MediaAsset, MediaAssetTagEnum } from "redmond/common";
import {
  ActionLink,
  Carousel,
  CloseButtonIcon,
  DesktopPopupModal,
  HomeSummary,
  HotelName,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { ConnectedShopCta } from "../ShopCta/container";
import { IShopCtaVariant } from "../ShopCta/component";
import { useDeviceTypes } from "../../../../hooks/useDeviceTypes";
import { CATEGORY_DISPLAY_NAME_MAP, CATEGORY_SORT_ORDER } from "./constants";
import lozad from "lozad";
import clsx from "clsx";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  VIEWED_VR_DETAILS_PHOTO_GALLERY,
  VR_DETAILS_PHOTO_GALLERY_IMAGE_LOAD_ERROR,
} from "redmond/apis/tysons";
import {
  AVAILABLE,
  getExperimentVariant,
  useExperiments,
  VR_CATEGORIZED_IMAGE_GALLERY,
} from "../../../../context/experiments";
import { ListingAddressEnum } from "redmond/apis/tysons/vacation-rentals";

export interface DesktopPhotoGalleryProps
  extends PhotoGalleryConnectorProps,
    RouteComponentProps {}

export const PhotoGallery = (props: DesktopPhotoGalleryProps) => {
  const { selectedListing } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [coverPageImages, setCoverPageImages] = useState<MediaAsset[]>([]);

  const expState = useExperiments();

  const showCategorizationUI =
    getExperimentVariant(expState.experiments, VR_CATEGORIZED_IMAGE_GALLERY) ===
    AVAILABLE;

  const allMedia = selectedListing?.listing?.content?.media || [];

  const { starRating, userRating } = selectedListing?.listing?.content || {};

  const city =
    selectedListing?.listing?.content?.location?.address?.ListingAddress !==
    ListingAddressEnum.Empty
      ? selectedListing?.listing?.content?.location?.address?.city
      : undefined;

  // Fetch the first three images to display on the cover page
  useEffect(() => {
    const fetchImage = async (media: MediaAsset) => {
      return new Promise<MediaAsset>((resolve, reject) => {
        const img = new Image();
        img.src = media.url;
        img.onload = () => resolve(media);
        img.onerror = () => reject("Image failed to load");
      });
    };

    const fetchFirstThreeImages = async () => {
      const results = await Promise.allSettled([
        fetchImage(allMedia[0]),
        fetchImage(allMedia[1]),
        fetchImage(allMedia[2]),
      ]);

      const validMedia = results
        .filter(
          (result): result is PromiseFulfilledResult<MediaAsset> =>
            result.status === "fulfilled"
        )
        .map((x) => x.value);

      setCoverPageImages(validMedia);
    };

    fetchFirstThreeImages();
  }, []);

  // When the modal is opened, lozad images must be loaded in the DOM before they can be observed
  useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        const observer = lozad(".lozad", {});
        observer.observe();
      }, 100);

      trackEvent({
        eventName: VIEWED_VR_DETAILS_PHOTO_GALLERY,
        properties: {
          listing_id: selectedListing?.listing?.id?.id,
        },
      });
    }
  }, [openModal]);

  const trackImageLoadError = (imageUrl: string) => {
    trackEvent({
      eventName: VR_DETAILS_PHOTO_GALLERY_IMAGE_LOAD_ERROR,
      properties: {
        listing_id: selectedListing?.listing?.id?.id,
        image_url: imageUrl,
      },
    });
  };

  const showCategoriesUI = () => {
    // Ensure at least 70% of the images are tagged with a category
    const totalNumImages = allMedia.length || 0;
    const numImagesWithTags =
      allMedia.filter((image) => image.tags?.kind !== MediaAssetTagEnum.Unknown)
        .length || 0;

    return showCategorizationUI && numImagesWithTags / totalNumImages >= 0.7;
  };

  const scrollToCategory = (category: string) => {
    const elem = window.document.getElementById(`${category}`);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Gallery that appears on the cover page
  const coverGallery = () => {
    const CoverGalleryImage: React.FC<{ coverPageImageIndex: number }> = ({
      coverPageImageIndex,
    }) => {
      return (
        <ImageWithLoading
          url={coverPageImages?.[coverPageImageIndex]?.url}
          alt={`pc-${coverPageImageIndex}`}
          width="100%"
          height="100%"
          style={{
            objectFit: "cover",
            borderRadius: "8px",
          }}
          onErrorCallback={() =>
            trackImageLoadError(coverPageImages?.[coverPageImageIndex]?.url)
          }
        />
      );
    };

    return matchesMobile ? (
      <Box display="flex" height="224px" onClick={() => setOpenModal(true)}>
        <Carousel
          className="image-slider-overrides"
          imageUrlsArray={allMedia.map((media) => media.url)}
          hideDots={false}
          hideArrows={false}
        />
      </Box>
    ) : (
      <Box
        position="relative"
        height="419px"
        display="flex"
        flexDirection="row"
        style={{ gap: "20px" }}
      >
        <Box width="60%">
          <CoverGalleryImage coverPageImageIndex={0} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="40%"
          height="calc(100% - 20px)"
          style={{ gap: "20px" }}
        >
          <Box height="50%">
            <CoverGalleryImage coverPageImageIndex={1} />
          </Box>
          <Box height="50%">
            <CoverGalleryImage coverPageImageIndex={2} />
          </Box>
        </Box>

        <Button
          className="show-all-photos-button"
          style={{
            position: "absolute",
            bottom: "50px",
            right: "25px",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Icon name={IconName.ShowAllImagesIcon} />
          <span style={{ marginLeft: "8px" }}>
            See all photos ({allMedia.length})
          </span>
        </Button>
      </Box>
    );
  };

  // Modal gallery variant 1: All images stacked vertically (desktop/mobile)
  const defaultGallery = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        overflow="scroll"
        margin={matchesMobile ? "15px 15px 125px 15px" : "54px 0px 54px 20px"}
        style={{ gap: "10px" }}
      >
        {allMedia.map((media) => {
          return (
            <ImageWithLoading
              key={media.url}
              alt={"listing image"}
              width="100%"
              height="auto"
              url={media.url}
              style={{
                borderRadius: "4px",
              }}
              loadWithLozad
              onErrorCallback={() => trackImageLoadError(media.url)}
            />
          );
        })}
      </Box>
    );
  };

  // Modal gallery variant 2: Categorized gallery (desktop/mobile)
  const categorizedGallery = () => {
    const categorizedImages: { [id in string]: string[] } = {};

    allMedia.forEach((media) => {
      let tag = media.tags?.kind ?? MediaAssetTagEnum.Unknown;
      categorizedImages[tag] ||= [];
      categorizedImages[tag].push(media.url);
    });

    const sortedCategorizedImages = Object.keys(categorizedImages).sort(
      (a, b) =>
        CATEGORY_SORT_ORDER.indexOf(a as MediaAssetTagEnum) -
        CATEGORY_SORT_ORDER.indexOf(b as MediaAssetTagEnum)
    );

    return (
      <>
        {matchesMobile && (
          <Box marginBottom={"125px"}>
            <Box
              display="flex"
              paddingY="15px"
              style={{
                overflow: "auto",
                scrollbarWidth: "none", // Firefox
                msOverflowStyle: "none", // IE/Edge
              }}
            >
              {sortedCategorizedImages.map((categoryName) => {
                let imageUrls = categorizedImages[categoryName];
                return (
                  <Box
                    key={imageUrls[0]}
                    display="flex"
                    flexDirection="column"
                    paddingLeft="12px"
                    style={{ gap: "12px" }}
                    onClick={() => scrollToCategory(categoryName)}
                  >
                    <ImageWithLoading
                      key={imageUrls[0]}
                      url={imageUrls[0]}
                      alt="category-image"
                      width={"150px"}
                      height={"115px"}
                      style={{
                        borderRadius: "4px",
                      }}
                      onErrorCallback={() => trackImageLoadError(imageUrls[0])}
                    />
                    <Typography>
                      {
                        CATEGORY_DISPLAY_NAME_MAP[
                          categoryName as MediaAssetTagEnum
                        ]
                      }
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" overflow="auto">
              {sortedCategorizedImages.map((categoryName) => {
                let imageUrls = categorizedImages[categoryName];

                return (
                  <Box
                    id={categoryName}
                    display="flex"
                    flexDirection="column"
                    paddingX="15px"
                    key={imageUrls[0]}
                  >
                    <Box paddingY={"20px"}>
                      <Typography variant="h4">
                        {
                          CATEGORY_DISPLAY_NAME_MAP[
                            categoryName as MediaAssetTagEnum
                          ]
                        }
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ gap: "5px" }}
                    >
                      {imageUrls.map((imageUrl) => {
                        return (
                          <ImageWithLoading
                            key={imageUrl}
                            alt={"listing image"}
                            width="100%"
                            height="auto"
                            url={imageUrl}
                            style={{
                              borderRadius: "4px",
                            }}
                            onErrorCallback={() =>
                              trackImageLoadError(imageUrl)
                            }
                          />
                        );
                      })}
                    </Box>
                    <Divider style={{ marginTop: "20px" }} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        {matchesDesktop && (
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: "20px", backgroundColor: "#F4F4F4" }}
            padding="54px 20px 54px 54px"
            maxWidth="60%"
            minWidth="60%"
            overflow="scroll"
          >
            <Typography variant="h2">All photos ({allMedia.length})</Typography>
            <Box display="flex" flexWrap="wrap" style={{ gap: "20px" }}>
              {sortedCategorizedImages.map((categoryName) => {
                let imageUrls = categorizedImages[categoryName];
                return (
                  <Box
                    key={imageUrls[0]}
                    display="flex"
                    flexDirection="column"
                    style={{ gap: "12px" }}
                    onClick={() => scrollToCategory(categoryName)}
                  >
                    <ImageWithLoading
                      key={imageUrls[0]}
                      url={imageUrls[0]}
                      alt="category-image"
                      width={"150px"}
                      height={"115px"}
                      style={{
                        borderRadius: "4px",
                      }}
                      onErrorCallback={() => trackImageLoadError(imageUrls[0])}
                    />
                    <Typography>
                      {
                        CATEGORY_DISPLAY_NAME_MAP[
                          categoryName as MediaAssetTagEnum
                        ]
                      }
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box paddingTop="20px">
              <Divider />
            </Box>
            <Box display="flex" flexDirection="column" style={{ gap: "20px" }}>
              {sortedCategorizedImages.map((categoryName) => {
                let imageUrls = categorizedImages[categoryName];

                return (
                  <Box
                    paddingTop="20px"
                    id={categoryName}
                    display="flex"
                    key={imageUrls[0]}
                  >
                    <Box width="100px" minWidth="100px">
                      {
                        CATEGORY_DISPLAY_NAME_MAP[
                          categoryName as MediaAssetTagEnum
                        ]
                      }
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ gap: "15px" }}
                    >
                      {imageUrls.map((imageUrl) => {
                        return (
                          <ImageWithLoading
                            key={imageUrl}
                            alt={"listing image"}
                            width="100%"
                            height="auto"
                            url={imageUrl}
                            style={{
                              borderRadius: "4px",
                            }}
                            onErrorCallback={() =>
                              trackImageLoadError(imageUrl)
                            }
                          />
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box className="vacation-rentals-photo-gallery">
      {coverGallery()}

      {matchesMobile && (
        <MobilePopoverCard
          open={openModal}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setOpenModal(false);
          }}
          bottomButton={
            <ConnectedShopCta variant={IShopCtaVariant.MOBILE_CTA_BUTTON} />
          }
          className="vacation-rentals-mobile-popover-photo-gallery"
          fullScreen
          headerElement={
            <Box
              padding="20px"
              borderBottom="1px solid var(--grey-11)"
              display="flex"
              justifyContent="center"
            >
              <Typography>All photos ({allMedia.length})</Typography>
              <ActionLink
                style={{ position: "absolute", right: "20px", top: "22px" }}
                className="close"
                content={<CloseButtonIcon />}
                label="Close"
                onClick={() => setOpenModal(false)}
              />
            </Box>
          }
        >
          {showCategoriesUI() ? categorizedGallery() : defaultGallery()}
        </MobilePopoverCard>
      )}

      {matchesDesktop && (
        <DesktopPopupModal
          className={clsx("vacation-rental-photo-modal-popup", "b2b")}
          open={openModal}
          invisibleBackdrop={false}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setOpenModal(false);
          }}
        >
          <Box
            id="vr-photo-modal-container"
            display="flex"
            flexDirection="row"
            overflow="none"
            height="90vh"
          >
            {showCategoriesUI() ? categorizedGallery() : defaultGallery()}

            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "15px" }}
              padding="54px 54px 54px 20px"
              minWidth="30%"
            >
              {selectedListing?.listing && (
                <HomeSummary
                  city={city}
                  starRating={starRating}
                  userRating={userRating?.score}
                />
              )}

              <HotelName name={selectedListing?.listing?.content?.name} />
              <ConnectedShopCta variant={IShopCtaVariant.DESKTOP_MAP} />
            </Box>
          </Box>
        </DesktopPopupModal>
      )}
    </Box>
  );
};

interface IImageWithLoading {
  url: string;
  style?: CSSProperties;
  className?: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  onErrorCallback?: () => void;
  loadWithLozad?: boolean;
}

const ImageWithLoading = ({
  url,
  style,
  className,
  alt,
  width,
  height,
  onErrorCallback,
  loadWithLozad,
}: IImageWithLoading) => {
  const [validImage, setValidImage] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const onError = () => {
    setValidImage(false);
    if (onErrorCallback) {
      onErrorCallback();
    }
  };

  return (
    <Box height={height} width={width} display={validImage ? "block" : "none"}>
      <img
        className={clsx([className, loadWithLozad && "lozad"])}
        {...(loadWithLozad ? { "data-src": url } : { src: url })}
        alt={alt}
        onLoad={() => setLoaded(true)}
        onError={onError}
        width={width}
        height={height}
        style={{
          ...style,
          opacity: loaded ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
      />
    </Box>
  );
};
