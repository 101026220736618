import { FareclassShelfBrandName, ITripTerminus } from "../../flights-module";
import { HotelStarRatingEnum } from "../../hotels-module";

export enum TopAmenityEnum {
  Restaurant = "Restaurant",
  FreeWifi = "FreeWifi",
  ParkingIncluded = "ParkingIncluded",
  TwentyFourSevenFrontDesk = "TwentyFourSevenFrontDesk",
  Pool = "Pool",
  Bar = "Bar",
  PetFriendly = "PetFriendly",
  BusinessServices = "BusinessServices",
  AirportShuttle = "AirportShuttle",
  Laundry = "Laundry",
  Spa = "Spa",
  Gym = "Gym",
  Accessibility = "Accessibility",
  AdultsOnly = "AdultsOnly",
  BreakfastAvailable = "BreakfastAvailable",
  ParkingAvailable = "ParkingAvailable",
}

export interface UserHotelPreferencesPayload {
  starRatings: HotelStarRatingEnum[];
  amenities: TopAmenityEnum[];
  freeCancellationOnly: boolean;
}

export enum AirlineOpenEnum {
  AA = "AA",
  B6 = "B6",
  DL = "DL",
  ExcludeLcc = "ExcludeLcc",
  F9 = "F9",
  NK = "NK",
  UA = "UA",
}

export type FareclassOptionFilter = {
  [key in FareclassShelfBrandName]: boolean;
};

export interface UserFlightPreferencesPayload {
  homeAirport?: ITripTerminus;
  fareClasses: FareclassOptionFilter;
  airlines: AirlineOpenEnum[];
  nonStopOnly: boolean;
}
