import React from "react";
import { Box, Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useDeviceTypes } from "halifax";

export const ShopSkeleton = () => {
  const { matchesMobile } = useDeviceTypes();

  const mobileSkeleton = (
    <Box pt="55px" bgcolor="rgb(251, 251, 251)">
      {/* Header skeleton */}
      <Box
        bgcolor="white"
        display="flex"
        width="100%"
        borderBottom=".5px solid var(--grey-11)"
        zIndex={2}
        style={{
          position: "fixed",
          top: "50px",
        }}
      >
        <Box
          height="55px"
          display="flex"
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* Listing title skeleton */}
          <Skeleton variant="text" width="130px" height="25px" />
          <Skeleton variant="text" width="150px" height="15px" />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="55px"
        width="100%"
      >
        {/* Rewards Balance skeleton */}
        <Skeleton variant="text" width="250px" height="20px" />
      </Box>
      <Skeleton
        variant="rect"
        width="100%"
        height="224px"
        style={{ borderRadius: "4px" }}
      />

      {/* Footer CTA skeleton */}
      <Box
        width="100%"
        bottom="0"
        position="fixed"
        boxShadow="rgba(0, 0, 0, 0.2) 0px -2px 10px"
        zIndex={2}
        bgcolor="white"
      >
        <Box px="15px" py="25px">
          <Skeleton
            variant="rect"
            width="100%"
            height="60px"
            style={{ borderRadius: "4px" }}
          />
        </Box>
      </Box>

      {/* Main section skeleton */}
      <Box display="flex" flexDirection="column" width="100%">
        {/* Overview skeleton */}
        <Box
          m="20px"
          p="30px"
          position="relative"
          borderRadius="8px"
          mb="25px"
          bgcolor="white"
          border="0.5px solid rgba(206, 207, 205, 0.5)"
        >
          {/* Title skeleton */}
          <Skeleton variant="text" width="70%" height="40px" />
          {/* Room counts skeleton */}
          <Box
            display="flex"
            style={{ flexFlow: "wrap", gap: "20px" }}
            alignItems="center"
            py="20px"
          >
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                width="100px"
                height="25px"
              />
            ))}
          </Box>
          <Divider style={{ marginBottom: "20px" }} />
          <Box display="flex" flexDirection="column">
            <Skeleton variant="text" width="120px" height="25px" />
            <Skeleton variant="text" width="160px" height="20px" />
            <Skeleton
              variant="text"
              width="150px"
              height="20px"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            />
          </Box>
          <Divider style={{ marginBottom: "20px" }} />
          {/* Popular amenities skeleton */}
          <Skeleton variant="text" width="150px" height="25px" />
          <Box
            display="flex"
            style={{ flexFlow: "wrap", gap: "20px" }}
            alignItems="center"
            pt="20px"
          >
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} variant="text" width="75px" height="25px" />
            ))}
          </Box>
        </Box>

        {/* About skeleton */}
        <Box
          m="0 20px 20px 20px"
          p="30px"
          position="relative"
          borderRadius="8px"
          mb="25px"
          bgcolor="white"
          border="0.5px solid rgba(206, 207, 205, 0.5)"
        >
          <Skeleton variant="text" width="80px" height="40px" />
          <Box
            display="flex"
            style={{ gap: "20px" }}
            alignItems="center"
            py="15px"
          >
            <Skeleton variant="circle" width="40px" height="40px" />
            <Skeleton variant="text" width="150px" height="25px" />
          </Box>
          <Skeleton variant="text" width="100%" height="25px" />
          <Skeleton variant="text" width="100%" height="25px" />
          <Skeleton variant="text" width="100%" height="25px" />
        </Box>
      </Box>
    </Box>
  );

  const desktopSkeleton = (
    <Box pt="130px" bgcolor="rgb(251, 251, 251)">
      {/* Header skeleton */}
      <Box
        bgcolor="white"
        display="flex"
        px="120px"
        borderBottom=".5px solid var(--grey-11)"
        zIndex={2}
        style={{
          position: "fixed",
          top: "50px",
        }}
      >
        <Box
          height="80px"
          display="flex"
          width="calc(var(--vw) * 100 - 2 * 120px)"
          flexDirection="row"
          alignItems="center"
        >
          {/* Back to results skeleton */}
          <Skeleton
            variant="rect"
            width="132px"
            height="34px"
            style={{ borderRadius: "4px" }}
          />
        </Box>
      </Box>
      {/* Gallery Skeleton */}
      <Box bgcolor="white" pt="30px" px="120px">
        <Box
          position="relative"
          height="419px"
          display="flex"
          flexDirection="row"
          style={{}}
        >
          <Box width="60%">
            <Skeleton
              variant="rect"
              height="100%"
              style={{ borderRadius: "8px" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="40%"
            style={{ gap: "20px" }}
            pl="35px"
          >
            <Box height="50%">
              <Skeleton
                variant="rect"
                height="100%"
                style={{ borderRadius: "8px" }}
              />
            </Box>
            <Box height="50%">
              <Skeleton
                variant="rect"
                height="100%"
                style={{ borderRadius: "8px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Nav Skeleton */}
      <Box
        bgcolor="white"
        px="120px"
        minHeight="82px"
        boxShadow="0px 4px 4px rgba(0,0,0,.05)"
        alignItems="center"
        display="flex"
        overflow="hidden"
      >
        {Array.from({ length: 7 }).map((_, index) => (
          <Box marginRight="48px" key={index} style={{ opacity: 0.3 }}>
            <Skeleton
              variant="text"
              width="80px"
              height="40px"
              animation="wave"
            />
          </Box>
        ))}
      </Box>
      {/* Main section skeleton */}
      <Box
        display="flex"
        p="30px 120px"
        flexDirection="row"
        style={{ gap: "20px" }}
      >
        {/* Left side skeleton */}
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            p="30px"
            position="relative"
            borderRadius="8px"
            mb="25px"
            bgcolor="white"
            border="0.5px solid rgba(206, 207, 205, 0.5)"
          >
            {/* Title skeleton */}
            <Skeleton variant="text" width="70%" height="40px" />
            {/* Room counts skeleton */}
            <Box
              display="flex"
              style={{ flexFlow: "wrap", gap: "20px" }}
              alignItems="center"
              py="20px"
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  width="100px"
                  height="25px"
                />
              ))}
            </Box>
            <Divider style={{ marginBottom: "20px" }} />
            {/* Popular amenities skeleton */}
            <Skeleton variant="text" width="150px" height="25px" />
            <Box
              display="flex"
              style={{ flexFlow: "wrap", gap: "20px" }}
              alignItems="center"
              pt="20px"
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  width="75px"
                  height="25px"
                />
              ))}
            </Box>
          </Box>

          {/* About skeleton */}
          <Box
            p="30px"
            position="relative"
            borderRadius="8px"
            mb="25px"
            bgcolor="white"
            border="0.5px solid rgba(206, 207, 205, 0.5)"
          >
            <Skeleton variant="text" width="80px" height="40px" />
            <Box
              display="flex"
              style={{ gap: "20px" }}
              alignItems="center"
              py="15px"
            >
              <Skeleton variant="circle" width="40px" height="40px" />
              <Skeleton variant="text" width="150px" height="25px" />
            </Box>
            <Skeleton variant="text" width="100%" height="25px" />
            <Skeleton variant="text" width="100%" height="25px" />
            <Skeleton variant="text" width="100%" height="25px" />
          </Box>
        </Box>
        {/* CTA skeleton */}
        <Box
          display="flex"
          maxWidth="500px"
          minWidth="450px"
          height="100%"
          flexDirection="column"
          bgcolor="white"
          border="0.5px solid rgba(206, 207, 205, 0.5)"
          borderRadius="8px"
        >
          <Box display="flex" flexDirection="column" p="20px">
            <Skeleton variant="text" width="120px" height="25px" />
            <Skeleton variant="text" width="160px" height="20px" />
            <Skeleton
              variant="text"
              width="150px"
              height="20px"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "12px", opacity: 0.5 }}
            >
              {Array.from({ length: 2 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rect"
                  width="100%"
                  height="50px"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(206, 207, 205)",
                    borderRadius: "4px",
                  }}
                />
              ))}
            </Box>
          </Box>

          <Divider />

          <Box
            display="flex"
            flexDirection="column"
            p="20px"
            style={{ gap: "12px" }}
          >
            <Skeleton variant="text" width="120px" height="25px" />
            <Divider />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ opacity: 0.5 }}
            >
              <Skeleton variant="text" width="180px" height="25px" />
              <Skeleton variant="text" width="40px" height="25px" />
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ opacity: 0.5 }}
            >
              <Skeleton variant="text" width="80px" height="25px" />
              <Skeleton variant="text" width="40px" height="25px" />
            </Box>

            <Divider />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Skeleton variant="text" width="32px" height="25px" />
              <Skeleton variant="text" width="120px" height="25px" />
            </Box>

            <Skeleton
              style={{ marginTop: "25px" }}
              variant="rect"
              width="410px"
              height="60px"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return matchesMobile ? mobileSkeleton : desktopSkeleton;
};
