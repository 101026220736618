import { HomesReservation } from "../apis";
import { IPaymentAmountInfo, PaymentBreakdown } from "./itinerary";

export interface HomesItineraryRequest {
  states: HomesReservationState[];
  referenceDateTime: string;
}

export enum HomesReservationState {
  Future = "Future",
  Present = "Present",
  Past = "Past",
  Canceled = "Canceled",
}

export interface HomesItineraryResponse {
  itineraries: {
    [key: string]: HomesItinerary[];
  };
}
export interface HomesItinerary {
  reservation: HomesReservation;
  paymentBreakdown: PaymentBreakdown;
  paymentsAndCredits: IPaymentAmountInfo;
}

interface InformativeSection {
  title: string;
  body: string[];
}
export interface BaseHomeInfoResponseCopy {
  title: string;
  disclaimer?: string;
  body: string[];
  informativeSection?: InformativeSection;
  importantInfo: string[];
  callToAction?: string;
}
export enum HomeChangeQuoteResponseEnum {
  HomesCancelQuoteSuccess = "HomesCancelQuoteSuccess",
  HomesChangeQuoteFailure = "HomesChangeQuoteFailure",
}

export interface HomeCancelInfoResponse {
  cancelScenario: HomesCancelScenario;
  HomeChangeQuoteResponse: HomeChangeQuoteResponseEnum;
}

export type HomesCancelScenario =
  | AgentReviewAndFulfillment
  | Canceled
  | ContactCustomerService
  | FullyRefundable
  | NonRefundable
  | PartiallyRefundable
  | PastCheckInDate;

export enum HomesCancelScenarioEnum {
  AgentReviewAndFulfillment = "AgentReviewAndFulfillment",
  Canceled = "Canceled",
  ContactCustomerService = "ContactCustomerService",
  FullyRefundable = "FullyRefundable",
  NonRefundablePartially = "NonRefundablePartially",
  RefundablePastCheckInDate = "RefundablePastCheckInDate",
  PartiallyRefundable = "PartiallyRefundable",
  PastCheckInDate = "PastCheckInDate",
}

interface HomesCancelScenarioBase {
  cancelCopy: BaseHomeInfoResponseCopy;
  HomesCancelScenario: HomesCancelScenarioEnum;
}
interface AgentReviewAndFulfillment extends HomesCancelScenarioBase {
  cancelConfirmationCopy: BaseHomeInfoResponseCopy;
  cancellationPolicyTitle: string;
  cancellationPolicyBody: string;
  HomesCancelScenario: HomesCancelScenarioEnum.AgentReviewAndFulfillment;
}

interface Canceled extends HomesCancelScenarioBase {
  HomesCancelScenario: HomesCancelScenarioEnum.Canceled;
}

interface ContactCustomerService extends HomesCancelScenarioBase {
  HomesCancelScenario: HomesCancelScenarioEnum.ContactCustomerService;
}

interface FullyRefundable extends HomesCancelScenarioBase {
  cancelConfirmationCopy: BaseHomeInfoResponseCopy;
  HomesCancelScenario: HomesCancelScenarioEnum.FullyRefundable;
}

interface NonRefundable extends HomesCancelScenarioBase {
  HomesCancelScenario: HomesCancelScenarioEnum.NonRefundablePartially;
}

interface PartiallyRefundable extends HomesCancelScenarioBase {
  cancelConfirmationCopy?: BaseHomeInfoResponseCopy;
  refundAmount: string;
  HomesCancelScenario: HomesCancelScenarioEnum.PartiallyRefundable;
}

interface PastCheckInDate extends HomesCancelScenarioBase {
  HomesCancelScenario: HomesCancelScenarioEnum.PastCheckInDate;
}

export enum HomesCancelAgentFullfillResponseEnum {
  HomesCancelAgentFulfillSuccess = "HomesCancelAgentFulfillSuccess",
  HomesCancelAgentFulfillFailure = "HomesCancelAgentFulfillFailure",
}

export interface HomesCancelAgentFullfillResponse {
  HomesCancelAgentFullfillResponse: HomesCancelAgentFullfillResponseEnum;
}
