import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { PhotoGallery } from "./component";
import { getVacationRentalShopSelectedListing } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedListing: getVacationRentalShopSelectedListing(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PhotoGalleryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPhotoGallery = withRouter(connector(PhotoGallery));
